import React, { useEffect, useState, useContext, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import { IoArrowBackSharp } from "react-icons/io5";
import { LoadingButton } from "@mui/lab";
import { useTranslate } from "react-polyglot";
import { useQuery } from "../../../hooks/useQuery";
import {
  Typography,
  InputBase,
  Button,
  Paper,
  CircularProgress,
  TextField,
  Box,
} from "@mui/material";
import { MerchantContext } from "../../../context/MerchantContext";
import { CustomizedSnackbars } from "../../../components/shareable/Shareable";
// AWS apis
import { API, graphqlOperation } from "aws-amplify";
import { updateMerchantKYC } from "../../../graphql/mutations";
import {
  adminGetMerchantGeneralConfig,
  getMerchantDetails,
} from "../../../graphql/queries";

function UpdateBusinessInfo(props) {
  const translate = useTranslate();
  const history = useHistory();
  let query = useQuery();
  const merchantInfoContext = useContext(MerchantContext);
  const [storeIsLoading, setStoreIsLoading] = useState(false);
  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [storeData, setStoreData] = useState("");
  const [businessData, setBusinessData] = useState({
    kycNRICName: "",
    kycNRIC: "",
    kycBusinessAddress: "",
    kycEmail: "",
    aboutUsDescription: "",
  });
  const [errorField, setErrorField] = useState({
    kycNRICName: "",
    kycNRIC: "",
    kycBusinessAddress: "",
    kycEmail: "",
    aboutUsDescription: "",
  });
  const [isSetting, setIsSetting] = useState(false);

  const [snackbar, setSnackbar] = useState({
    snackbarMessage: "",
    snackbarOpen: false,
    snackbarSeverity: "info",
  });

  const MALAYSIANICREGEX = /^(([[0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01]))-([0-9]{2})-([0-9]{4})/
  const NAMEREGEX = /^(?=.*[a-zA-Z]).+$/;
  const EMAILREGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

  function isEmail(input) {
    return EMAILREGEX.test(input);
  }

  function isICValueValid(input) {
    return MALAYSIANICREGEX.test(input);
  }

  function noBlankSpace(input) {
    return NAMEREGEX.test(input);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate and update the error state for each form field
    if (name === "kycNRICName" && !noBlankSpace(value)) {
      setErrorField({ ...errorField, kycNRICName: translate("onboarding-screen.empty-name") });
    } else if (name === "kycBusinessAddress" && value.trim() === "") {
      setErrorField({ ...errorField, kycBusinessAddress: translate("onboarding-screen.empty-address") });
    } else if (name === "aboutUsDescription" && value.trim() === "") {
      setErrorField({ ...errorField, aboutUsDescription: translate("onboarding-screen.empty-aboutus") });
    } else if (name === "kycNRIC" && value.trim() === "") {
      setErrorField({ ...errorField, kycNRIC: translate("onboarding-screen.empty-ic") });
    } else if (name === "kycNRIC" && !isICValueValid(value)) {
      setErrorField({ ...errorField, kycNRIC: translate("onboarding-screen.invalid-ic") });
    } else if (name === "kycEmail" && ((value || value !== "") && !isEmail(value))) {
      setErrorField({ ...errorField, kycEmail: translate("onboarding-screen.invalid-email") });
    } else if (name === "kycEmail" && !value || value === "") {
      setErrorField({ ...errorField, kycEmail: "" });
    }
    else {
      // Clear the error for the current field if it's valid
      setErrorField({ ...errorField, [name]: "" });
    }

    setBusinessData({
      ...businessData,
      [name]: value,
    });
  };

  const fetchStoreData = async () => {
    setStoreIsLoading(true);
    try {
      let res = await API.graphql(
        graphqlOperation(adminGetMerchantGeneralConfig)
      );
      let params = {
        merchantId: merchantInfoContext.merchantId,
      };
      let resAboutUs = await API.graphql(
        graphqlOperation(getMerchantDetails, params)
      );
      setBusinessData({
        ...businessData,
        kycNRICName: res.data.adminGetMerchantGeneralConfig.kycNRICName,
        kycNRIC: res.data.adminGetMerchantGeneralConfig.kycNRIC,
        kycBusinessAddress:
          res.data.adminGetMerchantGeneralConfig.kycBusinessAddress,
        kycEmail: res.data.adminGetMerchantGeneralConfig.notificationEmail,
        aboutUsDescription:
          resAboutUs.data.getMerchantDetails.aboutUsDescription,
      });
      setStoreIsLoading(false);
    } catch (error) {
      console.log("error", error);
      setStoreIsLoading(false);
    }
  };

  const saveBusinessData = async () => {
    setSaveIsLoading(true);

    if (
      businessData.kycNRICName &&
      businessData.kycNRIC &&
      businessData.kycBusinessAddress &&
      businessData.aboutUsDescription
    ) {
      try {
        let params = {
          businessAddress: businessData.kycBusinessAddress,
          ic: businessData.kycNRIC,
          name: businessData.kycNRICName,
          email: businessData.kycEmail,
          aboutUsDescription: businessData.aboutUsDescription,
        };

        const { kycEmail, ...otherFields } = errorField;
        const checkForErrorsButEmailIgnored = Object.values(otherFields).some(fieldValue => fieldValue !== "");
        const checkForErrors = Object.values(errorField).some(fieldValue => fieldValue !== "");

        if (params.email) {
          if (checkForErrors) {
            setSnackbar({
              snackbarMessage:
                translate("onboarding-screen.remove-all-error"),
              snackbarOpen: true,
              snackbarSeverity: "error",
            });
            setSaveIsLoading(false);
          } else {
            const respOrderFulfillment = await API.graphql(
              graphqlOperation(updateMerchantKYC, params)
            );
            if (respOrderFulfillment.data.updateMerchantKYC.status === "true") {
              if (isSetting) {
                setSaveIsLoading(false);
                setSnackbar({
                  snackbarMessage: "General Merchant Information Updated",
                  snackbarOpen: true,
                  snackbarSeverity: "success",
                });
              } else {
                setSaveIsLoading(false);
                if (merchantInfoContext.role === "AxrailAdmin") {
                  history.push("/kds/link-qr");
                } else {
                  history.push("/kds/settings/store-information");
                }
              }
            } else {
              setSnackbar({
                snackbarMessage:
                  respOrderFulfillment.data.updateMerchantKYC.message,
                snackbarOpen: true,
                snackbarSeverity: "error",
              });
            }
            setSaveIsLoading(false);
          }
        } else {
          if (checkForErrorsButEmailIgnored) {
            setSnackbar({
              snackbarMessage:
                translate("onboarding-screen.remove-all-error"),
              snackbarOpen: true,
              snackbarSeverity: "error",
            });
            setSaveIsLoading(false);
          } else {
            const respOrderFulfillment = await API.graphql(
              graphqlOperation(updateMerchantKYC, params)
            );
            if (respOrderFulfillment.data.updateMerchantKYC.status === "true") {
              if (isSetting) {
                setSaveIsLoading(false);
                setSnackbar({
                  snackbarMessage: translate("onboarding-screen.info-updated"),
                  snackbarOpen: true,
                  snackbarSeverity: "success",
                });
              } else {
                setSaveIsLoading(false);
                if (merchantInfoContext.role === "AxrailAdmin") {
                  history.push("/kds/link-qr");
                } else {
                  history.push("/kds/settings/store-information");
                }
              }
            } else {
              setSnackbar({
                snackbarMessage:
                  respOrderFulfillment.data.updateMerchantKYC.message,
                snackbarOpen: true,
                snackbarSeverity: "error",
              });
            }
            setSaveIsLoading(false);
          }
        }
      } catch (error) {
        console.log(error);
        setSaveIsLoading(false);
      }
    } else {
      setSaveIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStoreData();
  }, []);

  const renderPage = () => {
    if (storeIsLoading) {
      return (
        <Box className="center-loader" sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      );
    } else {
      return (
        <div>
          <TextField
            label={translate("onboarding-screen.register-name")}
            type="text"
            value={businessData.kycNRICName}
            onChange={(e) => handleChange(e)}
            variant="outlined"
            className="update-input-box"
            name="kycNRICName"
            helperText={
              errorField.kycNRICName ? (
                <p style={{ color: "#ff3333" }}>{errorField.kycNRICName}</p>
              ) : null
            }
          />
          <TextField
            label={translate("onboarding-screen.register-ic")}
            type="kycNRIC"
            value={businessData.kycNRIC}
            placeHolder="XXXXXX-XX-XXXX"
            onChange={(e) => handleChange(e)}
            variant="outlined"
            className="update-input-box"
            name="kycNRIC"
            helperText={
              errorField.kycNRIC ? (
                <p style={{ color: "#ff3333" }}>{errorField.kycNRIC}</p>
              ) : null
            }
          />
          <TextField
            label={translate("onboarding-screen.register-business-address")}
            type="text"
            value={businessData.kycBusinessAddress}
            onChange={(e) => handleChange(e)}
            multiline
            variant="outlined"
            className="update-input-box"
            name="kycBusinessAddress"
            helperText={
              errorField.kycBusinessAddress ? (
                <p style={{ color: "#ff3333" }}>{errorField.kycBusinessAddress}</p>
              ) : null
            }
          />
          <TextField
            label={translate("onboarding-screen.register-email")}
            type="text"
            value={businessData.kycEmail}
            onChange={(e) => handleChange(e)}
            variant="outlined"
            className="update-input-box"
            name="kycEmail"
            helperText={
              errorField.kycEmail ? (
                <p style={{ color: "#ff3333" }}>{errorField.kycEmail}</p>
              ) : null
            }
          />
          <TextField
            label={translate("onboarding-screen.register-about-us")}
            type="text"
            multiline
            value={businessData.aboutUsDescription}
            onChange={(e) => handleChange(e)}
            variant="outlined"
            className="update-input-box"
            name="aboutUsDescription"
            helperText={
              errorField.aboutUsDescription ? (
                <p style={{ color: "#ff3333" }}>{errorField.aboutUsDescription}</p>
              ) : null
            }
          />
          <div
            className="orderview-single-button-box"
            style={{ marginTop: "20px" }}
          >
            <LoadingButton
              className="orderview-confirm-button"
              onClick={() => saveBusinessData()}
              loading={saveIsLoading}
              loadingPosition="start"
              disabled={saveIsLoading}
              variant={saveIsLoading ? "outlined" : "contained"}
            >
              {isSetting
                ? translate("settings-home-page.save")
                : translate("settings-home-page.next")}
            </LoadingButton>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    if (history.location.state && history.location.state.previous) {
      setIsSetting(true);
    }
  }, [history.location.state]);

  return (
    <div>
      <Header page={"setting"} />
      <CustomizedSnackbars
        message={snackbar.snackbarMessage}
        snackbarOpen={snackbar.snackbarOpen}
        snackbarClose={() =>
          setSnackbar({
            ...snackbar,
            snackbarOpen: false,
            snackbarSeverity: snackbar.snackbarSeverity,
          })
        }
        severity={snackbar.snackbarSeverity}
      />
      <div className="body body-bg body-padding">
        {
          <Link
            to={
              !isSetting && merchantInfoContext.role === "AxrailAdmin"
                ? "/kds/select-merchant"
                : isSetting
                  ? history.location.state.previous
                  : "/kds/package-offer"
            }
          >
            <IoArrowBackSharp className="backbutton-styling"></IoArrowBackSharp>
          </Link>
        }
        <h1 className="orderhome-orderinfo-extralarge">
          {translate("settings-home-page.businessupdate")}
        </h1>

        {renderPage()}
      </div>
      {isSetting && <Footer page={"setting"} />}
    </div>
  );
}

export default UpdateBusinessInfo;
