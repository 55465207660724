import { TextField } from "@mui/material";
import { useState } from "react";
import { MapPin } from "../../../../assets/icons/Icons";
import PlacesAutocompleteInput from "./PlacesAutocompleteInput";

import { GoogleMap } from "@react-google-maps/api";
import { useTranslate } from "react-polyglot";
import usePlacesAutocomplete from "use-places-autocomplete";

import style from "./CustomMapWithInput.module.scss";

const CustomMapWithInput = ({
  formikProps,
  inputLabel,
  getErrorTranslation,
  hideStoreAddressDetail =  false,
  disabled = false,
}) => {
  const translate = useTranslate();
  const [refMap, setRefMap] = useState(null);
  const [mapStatusMessage, setMapStatusMessage] = useState(
    translate("store-information-page.no-address-selected")
  );
  const placesAutocompleteProps = usePlacesAutocomplete({
    // Provide the cache time in seconds, default is 24 hours
    cache: 24 * 60 * 60,
    requestOptions: {
      componentRestrictions: { country: ["my"] },
    },
  });

  const funcSplitAddress = (
    addressComponent,
    formattedAddress,
    setFieldValue
  ) => {
    addressComponent.forEach((addressData) => {
      if (addressData.types && addressData.types.length > 0) {
        addressData.types.forEach((type) => {
          switch (type) {
            case "country":
              setFieldValue("country", addressData.long_name);
              break;
            case "postal_code":
              setFieldValue("postalCode", addressData.long_name);
              break;
            case "administrative_area_level_1":
              setFieldValue("state", addressData.long_name);
              break;
            case "administrative_area_level_2":
            case "locality":
              setFieldValue("city", addressData.long_name);
              break;
            default:
          }
        });
      }
    });
    if (formattedAddress) {
      setFieldValue("address", formattedAddress);
    }
  };

  const getMapCenterLatLngAndAddress = async (
    handleChangeInput,
    handleChangeLatLng,
    formikProps
  ) => {
    if (refMap) {
      const mapCenter = refMap.getCenter();
      const latLng = {
        lat: mapCenter.lat(),
        lng: mapCenter.lng(),
      };
      const place = await reverseGeocode(latLng);
      handleChangeInput(place.formatted_address);
      handleChangeLatLng(latLng);
      funcSplitAddress(
        place.address_components,
        place.formatted_address,
        formikProps.setFieldValue
      );
    }
  };

  const reverseGeocode = async ({ lat, lng }) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&latlng=${lat},${lng}`;
    const res = await fetch(url);
    const data = await res.json();

    return data.results[0];
  };

  const getUserLocation = async (formikProps) => {
    setMapStatusMessage("Retrieving your location...");
    if (!navigator.geolocation) {
      setMapStatusMessage(
        translate("store-information-page.no-address-selected")
      );
      return false;
    } else {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const latLng = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          const place = await reverseGeocode(latLng);
          formikProps.setFieldValue("latitude", latLng.lat);
          formikProps.setFieldValue("longitude", latLng.lng);
          formikProps.setFieldValue("address", place.formatted_address);
          placesAutocompleteProps.setValue(place.formatted_address);
          setMapStatusMessage(
            translate("store-information-page.no-address-selected")
          );
        },
        () => {
          setMapStatusMessage(
            translate("store-information-page.no-address-selected")
          );
        }
      );
      return true;
    }
  };

  const handleOnDragAndZoom = async (formikProps) => {
    if (disabled) return;
    //isLoadFromInput = whenever user trigger drag event from inputting address in textfield
    if (formikProps.values.isLoadFromInput) {
      formikProps.setFieldValue("isLoadFromInput", false);
    } else {
      const handleChangeLatLng = ({ lat, lng }) => {
        // formikProps.setFieldValue("selectedLatLng", { lat, lng });
        formikProps.setFieldValue("latitude", lat);
        formikProps.setFieldValue("longitude", lng);
      };

      const handleChangeAddress = (address) => {
        formikProps.setFieldValue("address", address);
        placesAutocompleteProps.setValue(address);
      };

      getMapCenterLatLngAndAddress(
        handleChangeAddress,
        handleChangeLatLng,
        formikProps
      );
    }
  };
  return (
    <>
      <PlacesAutocompleteInput
        inputLabel={inputLabel}
        funcSplitAddress={funcSplitAddress}
        formikProps={formikProps}
        getUserLocation={getUserLocation}
        disabled={disabled}
        {...placesAutocompleteProps}
      />
      {!hideStoreAddressDetail && (
        <>
          <h1 className={style["store-address-detail"]}>
            {translate("settings-home-page.store-address-detail")}
          </h1>
          <TextField
            error={true && formikProps.errors.addressDetail}
            type="text"
            value={formikProps.values.addressDetail}
            onChange={(e) =>
              formikProps.setFieldValue("addressDetail", e.target.value)
            }
            variant="outlined"
            fullWidth
            className="input-box"
            name="addressDetail"
            placeholder="Eg : Floor, unit no., building name"
            helperText={getErrorTranslation(formikProps.errors.addressDetail)}
          />
        </>
      )}

      <div className={style["google-map-wrapper"]}>
        {formikProps.values.latitude && formikProps.values.longitude ? (
          <>
            <GoogleMap
              zoom={17}
              onLoad={(map) => setRefMap(map)}
              mapContainerClassName={style["google-map"]}
              center={{
                lat: Number(formikProps.values.latitude),
                lng: Number(formikProps.values.longitude),
              }}
              options={{
                disableDefaultUI: true,
                gestureHandling: disabled ? "none" : "greedy",
                clickableIcons: disabled ? false : true,
                draggable: disabled ? false : true,
                draggableCursor: disabled ? false : true,
              }}
              onDragEnd={() => handleOnDragAndZoom(formikProps)}
              onZoomChanged={() => handleOnDragAndZoom(formikProps)}
            />
            <div className={style["map-pin-wrapper"]}>
              <MapPin className={style["pin"]} />
            </div>
          </>
        ) : (
          <div className={style["no-address-wrapper"]}>{mapStatusMessage}</div>
        )}
      </div>
    </>
  );
};

export default CustomMapWithInput;
