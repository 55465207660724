import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import queryString from "query-string";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { IoArrowBackSharp, IoClose } from "react-icons/io5";
import { useTranslate } from "react-polyglot";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../../components/header/Header";
import { CustomizedSnackbars } from "../../components/shareable/Shareable";
import { MerchantContext } from "../../context/MerchantContext";
import { formatPrice } from "../../utils/shareable-utils";
import OrderDetail from "./components/OrderDetail";
// AWS apis
import { LinearProgress, Stack, Typography } from "@mui/material";
import { API, graphqlOperation } from "aws-amplify";
import Lottie from "react-lottie";
import {
  adminCancelManualPaymentOrder,
  adminCompletePickUpOrder,
  adminRefundOrder,
  adminRequestForDelivery,
  adminUpdateManualPaymentOrderStatus,
  adminUpdateOrderFulfillment,
} from "../../graphql/mutations";
import {
  adminGetCustomerOrderDetailList,
  adminGetDeliveryTransitionLog,
} from "../../graphql/queries";
import { usePageVisibility, useQuery } from "../../hooks";
import DeliveryAnimation from "../../lotties/delivery-animation.json";

function ViewOrder(props) {
  const translate = useTranslate();
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();
  const merchantInfoContext = useContext(MerchantContext);

  const MAX_REQUEST_TIME = 180000;

  const orderID = queryString.parse(location.search).orderID;
  const [orderItems, setOrderItems] = useState([]);
  const [orderInfo, setOrderInfo] = useState({});
  // const [listTotal, setListTotal] = useState(null);
  const [orderItemIsLoading, setOrderItemIsLoading] = useState();

  const [showRequestAnimation, setShowRequestAnimation] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const [open, setOpen] = useState(false);
  const handleOpenRejectReasonModal = () => {
    setOpenRejectConfirmModal(false);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [openRejectConfirmModal, setOpenRejectConfirmModal] = useState(false);

  const handleOpenRejectConfirmModal = () => {
    setOpenRejectConfirmModal(true);
  };

  const handleCloseRejectConfirmModal = () => {
    setOpenRejectConfirmModal(false);
  };

  const runRejectFunctionCloseModal = () => {
    setOpenRejectConfirmModal(false);
    updateOrderRejectedStatus();
  };

  const renderRejectConfirmModal = () => {
    return (
      <div>
        <Dialog
          open={openRejectConfirmModal}
          onClose={handleCloseRejectConfirmModal}
        >
          <DialogTitle
            className="orderhome-orderinfo-large"
            id="alert-dialog-title"
          >
            {translate("order-listing-screen.areyousuretoreject")}
          </DialogTitle>
          <DialogActions>
            <Button
              className="orderhome-orderinfo-no"
              onClick={handleCloseRejectConfirmModal}
            >
              {translate("settings-home-page.disagree")}
            </Button>
            <Button
              className="orderhome-orderinfo-yes"
              onClick={
                (orderInfo.status === "Pending Fulfilled" ||
                  orderInfo.status === "Pending Payment") &&
                (orderInfo.paymentType === "ManualPayment" ||
                  orderInfo.paymentType === "PayAtCounter")
                  ? () => handleOpenRejectReasonModal()
                  : () => runRejectFunctionCloseModal()
              }
              autoFocus
            >
              {translate("settings-home-page.agree")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 280,
    bgcolor: "background.paper",
    border: "1px solid #0A2540",
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
    orderaccept: {
      background: "#008CDC",
      border: "1px solid #008CDC",
    },
  };

  const [snackbar, setSnackbar] = useState({
    snackbarMessage: "",
    snackbarOpen: false,
    snackbarSeverity: "info",
  });

  const fetchOrderDetailData = useCallback(async (id) => {
    try {
      setOrderItemIsLoading(true);
      setFulfillmentIsLoading(true);
      let res = null;
      let params = {
        orderId: id,
      };
      res = await API.graphql(
        graphqlOperation(adminGetCustomerOrderDetailList, params)
      );
      if (res.data.adminGetCustomerOrderDetailList.status === "true") {
        let orderDetailInfo = res.data.adminGetCustomerOrderDetailList.order;
        let tmpOrderInfo = {
          ...orderDetailInfo,
          deliveryAddress: orderDetailInfo.deliveryAddress,
          billingAddress: orderDetailInfo.billingAddress,
        };
        if (orderDetailInfo.type === "Delivery") {
          let deliveryStatusRes = await API.graphql(
            graphqlOperation(adminGetDeliveryTransitionLog, {
              orderNumber: orderDetailInfo.orderNumber,
            })
          );
          tmpOrderInfo["deliveryStatusTimeline"] = !!deliveryStatusRes.data
            .adminGetDeliveryTransitionLog.items
            ? deliveryStatusRes.data.adminGetDeliveryTransitionLog.items
            : [];
          //tmpOrderInfo["deliveryStatusTimeline"] = dummyTimeline;
        }
        setOrderInfo(tmpOrderInfo);
        let orderDetailsList =
          res.data.adminGetCustomerOrderDetailList.orderDetails;
        var tmpList = [];
        if (orderDetailsList !== null) {
          for (const [key, value] of Object.entries(orderDetailsList)) {
            tmpList.push(
              Object.assign(value, {
                fulfillmentQuantity: value.orderedQuantity,
                availableRefundQuantity:
                  value.orderedQuantity - value.refundQuantity,
                refundQuantity: value.orderedQuantity - value.refundQuantity,
                refundAmount: formatPrice(
                  (value.orderedQuantity - value.refundQuantity) *
                    (value.deliveryPriceWithTax !== 0
                      ? value.deliveryPriceWithTax
                      : value.deliveryPrice)
                ),
              })
            );
          }
          setOrderItems(tmpList);
        }
      }
      setFulfillmentIsLoading(false);
      setOrderItemIsLoading(false);
    } catch (error) {
      console.log(error);
      setFulfillmentIsLoading(false);
      setOrderItemIsLoading(false);
    }
  }, []);

  const [fulfillmentIsLoading, setFulfillmentIsLoading] = useState(false);
  const updateOrderFulfillment = async () => {
    setOpenMerchantAdviseModal(false);
    setFulfillmentIsLoading(true);
    let orderDetailsToBeFulfilledList = [];
    let totalQuantity = 0;
    for (let key in orderItems) {
      let tmpObj = {};
      tmpObj["quantity"] = parseInt(orderItems[key]["fulfillmentQuantity"]);
      tmpObj["orderDetailId"] = orderItems[key]["orderDetailId"];
      tmpObj["productUOMId"] = orderItems[key]["itemId"];
      orderDetailsToBeFulfilledList.push(tmpObj);
      totalQuantity =
        totalQuantity + parseInt(orderItems[key]["fulfillmentQuantity"]);
    }

    if (totalQuantity === 0) {
      setFulfillmentIsLoading(false);
      return;
    }
    try {
      let params = {
        orderId: orderID,
        salesChannelName: orderInfo.salesChannelName,
        orderDetailsToBeFulfilled: orderDetailsToBeFulfilledList,
      };

      const respOrderFulfillment = await API.graphql(
        graphqlOperation(adminUpdateOrderFulfillment, params)
      );
      if (
        respOrderFulfillment.data.adminUpdateOrderFulfillment.status === "true"
      ) {
        if (orderInfo.type === "Delivery") {
          sendRequestDelivery();
        } else {
          fetchOrderDetailData(orderID);
        }
      }
    } catch (error) {
      console.log(error);
      setFulfillmentIsLoading(false);
    }
  };

  const sendRequestDelivery = async () => {
    setShowRequestAnimation(true);
    startDeliveryTimeout();

    let orderList = [];

    let tmpObj = {};
    tmpObj["orderNumber"] = orderInfo.orderNumber;
    tmpObj["orderId"] = orderInfo.orderId;
    tmpObj["status"] = orderInfo.status;
    orderList.push(tmpObj);

    try {
      let params = {
        orderList: orderList,
      };
      // setFulfillmentIsLoading(false);
      // return
      const respOrderFulfillment = await API.graphql(
        graphqlOperation(adminRequestForDelivery, params)
      );
      if (respOrderFulfillment.data.adminRequestForDelivery.status === "true") {
        fetchOrderDetailData(orderID);
      } else {
        setSnackbar({
          snackbarMessage:
            respOrderFulfillment.data.adminRequestForDelivery.message ===
            "Insufficient Shipping Credit Balance"
              ? translate("order-listing-screen.insufficient-credit")
              : respOrderFulfillment.data.adminRequestForDelivery.message,
          snackbarOpen: true,
          snackbarSeverity: "error",
        });
        if (
          respOrderFulfillment.data.adminRequestForDelivery.message ===
          "Insufficient Shipping Credit Balance"
        ) {
          let params = {
            orderList: orderList,
          };
          // setFulfillmentIsLoading(false);
          // return
          const respOrderFulfillment = await API.graphql(
            graphqlOperation(adminRequestForDelivery, params)
          );
          if (
            respOrderFulfillment.data.adminRequestForDelivery.status === "true"
          ) {
            fetchOrderDetailData(orderID);
          } else {
            setSnackbar({
              snackbarMessage:
                respOrderFulfillment.data.adminRequestForDelivery.message,
              snackbarOpen: true,
              snackbarSeverity: "error",
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
      setFulfillmentIsLoading(false);
    }
  };

  const updatePickupOrderShippingStatus = async () => {
    setFulfillmentIsLoading(true);

    try {
      let params = {
        orderId: orderID,
      };

      const respOrderFulfillment = await API.graphql(
        graphqlOperation(adminCompletePickUpOrder, params)
      );
      if (
        respOrderFulfillment.data.adminCompletePickUpOrder.status === "true"
      ) {
        setFulfillmentIsLoading(false);
        if (orderInfo.status === "Order Fulfilled") {
          fetchOrderDetailData(orderID);
        } else if (
          orderInfo.status === "Ready for Customer Pickup" ||
          orderInfo.status === "Served to Customer"
        ) {
          history.push("/kds/ordercompleted?orderID=" + orderInfo.orderId);
        } else {
          return null;
        }
      } else {
        setSnackbar({
          snackbarMessage:
            respOrderFulfillment.data.adminCompletePickUpOrder.message,
          snackbarOpen: true,
          snackbarSeverity: "error",
        });
      }
    } catch (error) {
      console.log(error);
      setFulfillmentIsLoading(false);
    }
  };

  const updateOrderRefundedStatus = async () => {
    setFulfillmentIsLoading(true);

    try {
      let params = {
        orderId: orderID,
        newOrderStatus: "Refunded",
        manualPaymentMethodName: "tng",
      };

      const respOrderFulfillment = await API.graphql(
        graphqlOperation(adminUpdateManualPaymentOrderStatus, params)
      );
      if (
        respOrderFulfillment.data.adminUpdateManualPaymentOrderStatus.status ===
        "true"
      ) {
        fetchOrderDetailData(orderID);
      } else {
        setSnackbar({
          snackbarMessage:
            respOrderFulfillment.data.adminUpdateManualPaymentOrderStatus
              .message,
          snackbarOpen: true,
          snackbarSeverity: "error",
        });
      }
      setFulfillmentIsLoading(false);
    } catch (error) {
      console.log(error);
      setFulfillmentIsLoading(false);
    }
  };

  const cancelOrderStatus = async () => {
    setFulfillmentIsLoading(true);

    try {
      let params = {
        orderId: orderID,
      };

      const respOrderFulfillment = await API.graphql(
        graphqlOperation(adminCancelManualPaymentOrder, params)
      );
      if (
        respOrderFulfillment.data.adminCancelManualPaymentOrder.status ===
        "true"
      ) {
        setOpen(false);
        fetchOrderDetailData(orderID);
      } else {
        setOpen(false);
        setSnackbar({
          snackbarMessage:
            respOrderFulfillment.data.adminCancelManualPaymentOrder.message,
          snackbarOpen: true,
          snackbarSeverity: "error",
        });
      }
      setFulfillmentIsLoading(false);
    } catch (error) {
      console.log(error);
      setFulfillmentIsLoading(false);
      setFulfillmentIsLoading(false);
    }
  };

  const updateOrderRejectedStatus = async () => {
    setFulfillmentIsLoading(true);
    let orderDetailsToBeFulfilledList = [];
    let totalQuantity = 0;
    for (let key in orderItems) {
      let tmpObj = {};
      tmpObj["quantity"] = parseInt(orderItems[key]["orderedQuantity"]);
      tmpObj["orderDetailId"] = orderItems[key]["orderDetailId"];
      tmpObj["amount"] =
        orderItems[key][
          !!orderItems[key].subtotalWithTax ? "subtotalWithTax" : "subtotal"
        ];
      orderDetailsToBeFulfilledList.push(tmpObj);
      totalQuantity =
        totalQuantity + parseInt(orderItems[key]["orderedQuantity"]);
    }

    if (totalQuantity === 0) {
      setFulfillmentIsLoading(false);
      return;
    }
    try {
      let params = {
        orderDetailsToBeRefunded: orderDetailsToBeFulfilledList,
        orderId: orderID,
        reason: "Out of Stock",
        refundType: "cancel",
      };

      const respOrderFulfillment = await API.graphql(
        graphqlOperation(adminRefundOrder, params)
      );
      if (respOrderFulfillment.data.adminRefundOrder.status === "true") {
        setOpen(false);
        fetchOrderDetailData(orderID);
      } else {
        setOpen(false);
        setSnackbar({
          snackbarMessage: respOrderFulfillment.data.adminRefundOrder.message,
          snackbarOpen: true,
          snackbarSeverity: "error",
        });
      }
      setFulfillmentIsLoading(false);
    } catch (error) {
      console.log(error);
      setFulfillmentIsLoading(false);
    }
  };

  const renderRejectModal = () => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <div className="reject-modal">
          <div className="orderhome-flex-box">
            <DialogTitle
              className="cancel-modal-header"
              id="alert-dialog-title"
            >
              {translate("order-listing-screen.reasontoreject")}
            </DialogTitle>
            <IoClose
              className="icon-cancel push-right"
              onClick={handleClose}
            ></IoClose>
          </div>

          <Button
            className="addproduct-rejectreason-button"
            onClick={() => cancelOrderStatus()}
          >
            {translate("order-listing-screen.nopay")}
          </Button>
          <Button
            className="addproduct-rejectreason-button"
            onClick={() => updateOrderRejectedStatus()}
            autoFocus
          >
            {translate("order-listing-screen.nostock")}
          </Button>
        </div>
      </Dialog>
    );
  };

  const [openMerchantAdviseModal, setOpenMerchantAdviseModal] = useState(false);

  const handleOpenMerchantAdviseModal = () => {
    setOpenMerchantAdviseModal(true);
  };

  const handleCloseMerchantAdviseModal = () => {
    setOpenMerchantAdviseModal(false);
  };

  const renderMerchantAdviseModal = (grandTotal) => {
    return (
      <div>
        <Dialog
          open={openMerchantAdviseModal}
          onClose={handleCloseMerchantAdviseModal}
        >
          <DialogTitle
            className="orderhome-orderinfo-large"
            id="alert-dialog-title"
          >
            {translate("order-listing-screen.pleasecheckbefore") +
              merchantInfoContext.currency +
              " " +
              grandTotal +
              translate("order-listing-screen.havereceived")}
          </DialogTitle>
          <DialogActions>
            <Button
              className="orderhome-orderinfo-no"
              onClick={() => handleCloseMerchantAdviseModal()}
            >
              {translate("order-listing-screen.goback")}
            </Button>
            <Button
              className="orderhome-orderinfo-yes"
              onClick={() => updateOrderFulfillment()}
              autoFocus
            >
              {translate("order-listing-screen.proceed")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
  let [refreshIntervalId, setRefreshIntervalId] = useState();
  //useeffect dependency to call fetch detail api after delete
  const [refreshDetail, setRefreshDetail] = useState(0);

  const refreshOrderDetail = () => {
    setOrderInfo({});
    setRefreshDetail((prev) => prev + 1);
    clearInterval(refreshIntervalId);
    let newIntervalId = setTimeout(refreshOrderDetail, 60000);
    setRefreshIntervalId(newIntervalId);
  };

  useEffect(() => {
    if (orderID) {
      fetchOrderDetailData(orderID);
    }
  }, [orderID, fetchOrderDetailData, refreshDetail]);

  const isPageVisible = usePageVisibility();

  useMemo(() => {
    if (isPageVisible) {
      refreshOrderDetail();
    }
  }, [isPageVisible]);

  const renderOrderInfo = () => {
    if (orderInfo.status === "Order Fulfilled") {
      return (
        <div>
          <OrderDetail
            orderInfo={orderInfo}
            orderItems={orderItems}
            download="false"
            rider="true"
          ></OrderDetail>
        </div>
      );
    } else if (orderInfo.status === "Shipping") {
      if (orderInfo.type === "Delivery") {
        return (
          <div>
            <OrderDetail
              orderInfo={orderInfo}
              orderItems={orderItems}
              download="false"
              rider="true"
            ></OrderDetail>
            {orderInfo?.deliveryStatus === "Requested for Delivery" &&
              remainingTime > 0 && (
                <>
                  <LinearProgress
                    variant="determinate"
                    value={(remainingTime / MAX_REQUEST_TIME) * 100}
                    sx={{ borderRadius: "20px", marginTop: "40px" }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "DM Sans",
                      fontSize: "12px",
                      marginTop: "4px",
                    }}
                  >
                    Requesting for rider...
                  </Typography>
                </>
              )}
          </div>
        );
      } else {
        return (
          <div>
            <OrderDetail
              orderInfo={orderInfo}
              orderItems={orderItems}
              download="false"
              rider="false"
            ></OrderDetail>
          </div>
        );
      }
    } else if (orderInfo.status === "Order Completed") {
      return (
        <div>
          <OrderDetail
            orderInfo={orderInfo}
            orderItems={orderItems}
            download="true"
            rider="false"
          ></OrderDetail>
        </div>
      );
    } else {
      return (
        <div>
          <OrderDetail
            orderInfo={orderInfo}
            orderItems={orderItems}
            download="false"
            rider="false"
          ></OrderDetail>
        </div>
      );
    }
  };

  const renderBottomButton = () => {
    if (
      orderInfo.status === "Pending Fulfilled" ||
      orderInfo.status === "Pending Payment"
    ) {
      return (
        <div className="orderview-button-bottom">
          <div className="orderview-button-holder">
            <div className="orderview-button-grid">
              <div style={{ textDecoration: "none" }}>
                <div className="orderview-button-box">
                  <LoadingButton
                    className="orderview-reject-button"
                    onClick={() => handleOpenRejectConfirmModal()}
                    loading={fulfillmentIsLoading}
                    disabled={fulfillmentIsLoading}
                    variant={fulfillmentIsLoading ? "outlined" : "contained"}
                  >
                    {!fulfillmentIsLoading ? (
                      translate("order-listing-screen.orderreject")
                    ) : (
                      <CircularProgress size={20} color={"inherit"} />
                    )}
                  </LoadingButton>
                </div>
              </div>
              <div style={{ textDecoration: "none" }}>
                <div className="orderview-button-box">
                  <LoadingButton
                    className="orderview-confirm-button"
                    onClick={() => handleOpenMerchantAdviseModal()}
                    loading={fulfillmentIsLoading}
                    disabled={fulfillmentIsLoading}
                    variant={fulfillmentIsLoading ? "outlined" : "contained"}
                  >
                    {!fulfillmentIsLoading ? (
                      translate("order-listing-screen.orderaccept")
                    ) : (
                      <CircularProgress size={20} color={"inherit"} />
                    )}
                  </LoadingButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (orderInfo.status === "Order Fulfilled") {
      if (orderInfo.type === "Delivery") {
        if (orderInfo.deliveryStatus === "No Rider Found") {
          return (
            <div className="orderview-button-bottom">
              <div className="ordercomplete-button-holder">
                <div className="orderview-button-grid">
                  <div style={{ textDecoration: "none" }}>
                    <div className="orderview-button-box">
                      <LoadingButton
                        className="orderview-reject-button"
                        onClick={() => handleOpenRejectConfirmModal()}
                        loading={fulfillmentIsLoading}
                        disabled={fulfillmentIsLoading}
                        variant={
                          fulfillmentIsLoading ? "outlined" : "contained"
                        }
                      >
                        {!fulfillmentIsLoading ? (
                          translate("order-listing-screen.orderreject")
                        ) : (
                          <CircularProgress size={20} color={"inherit"} />
                        )}
                      </LoadingButton>
                    </div>
                  </div>
                  <div style={{ textDecoration: "none" }}>
                    <div className="orderview-button-box">
                      <LoadingButton
                        className="orderview-confirm-button"
                        onClick={() => sendRequestDelivery()}
                        loading={fulfillmentIsLoading}
                        disabled={fulfillmentIsLoading}
                        variant={
                          fulfillmentIsLoading ? "outlined" : "contained"
                        }
                      >
                        {!fulfillmentIsLoading ? (
                          translate("order-listing-screen.request-for-rider")
                        ) : (
                          <CircularProgress size={20} color={"inherit"} />
                        )}
                      </LoadingButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="orderview-button-bottom">
              <div className="ordercomplete-button-holder">
                <div className="orderview-single-button-box">
                  <LoadingButton
                    className="orderview-reject-button"
                    onClick={() => handleOpenRejectConfirmModal()}
                    loading={fulfillmentIsLoading}
                    disabled={fulfillmentIsLoading}
                    sx={style.orderaccept}
                    variant={fulfillmentIsLoading ? "outlined" : "contained"}
                  >
                    {!fulfillmentIsLoading ? (
                      translate("order-listing-screen.orderreject")
                    ) : (
                      <CircularProgress size={20} color={"inherit"} />
                    )}
                  </LoadingButton>
                </div>
              </div>
            </div>
          );
        }
      } else {
        return (
          <div className="orderview-button-bottom">
            <div className="ordercomplete-button-holder">
              <div className="orderview-single-button-box">
                <LoadingButton
                  className="orderview-confirm-button"
                  onClick={() => updatePickupOrderShippingStatus()}
                  loading={fulfillmentIsLoading}
                  disabled={fulfillmentIsLoading}
                  variant={fulfillmentIsLoading ? "outlined" : "contained"}
                >
                  {!fulfillmentIsLoading ? (
                    orderInfo.type === "DineIn" ? (
                      translate("order-listing-screen.served-to-customer")
                    ) : (
                      translate("order-listing-screen.ready-to-pick-up")
                    )
                  ) : (
                    <CircularProgress size={20} color={"inherit"} />
                  )}
                </LoadingButton>
              </div>
            </div>
          </div>
        );
      }
    } else if (orderInfo.status === "Shipping") {
      if (orderInfo.type === "Delivery") {
        if (orderInfo.deliveryStatus === "No Rider Found") {
          return (
            <div className="orderview-button-bottom">
              <div className="ordercomplete-button-holder">
                <div className="orderview-button-grid">
                  <div style={{ textDecoration: "none" }}>
                    <div className="orderview-button-box">
                      <LoadingButton
                        className="orderview-reject-button"
                        onClick={() => handleOpenRejectConfirmModal()}
                        loading={fulfillmentIsLoading}
                        disabled={fulfillmentIsLoading}
                        variant={
                          fulfillmentIsLoading ? "outlined" : "contained"
                        }
                      >
                        {!fulfillmentIsLoading ? (
                          translate("order-listing-screen.orderreject")
                        ) : (
                          <CircularProgress size={20} color={"inherit"} />
                        )}
                      </LoadingButton>
                    </div>
                  </div>
                  <div style={{ textDecoration: "none" }}>
                    <div className="orderview-button-box">
                      <LoadingButton
                        className="orderview-confirm-button"
                        onClick={() => sendRequestDelivery()}
                        loading={fulfillmentIsLoading}
                        disabled={fulfillmentIsLoading}
                        variant={
                          fulfillmentIsLoading ? "outlined" : "contained"
                        }
                      >
                        {!fulfillmentIsLoading ? (
                          translate("order-listing-screen.request-for-rider")
                        ) : (
                          <CircularProgress size={20} color={"inherit"} />
                        )}
                      </LoadingButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      }
    } else if (
      orderInfo.status === "Ready for Customer Pickup" ||
      orderInfo.status === "Served to Customer"
    ) {
      return (
        <div className="orderview-button-bottom">
          <div className="ordercomplete-button-holder">
            <div className="orderview-single-button-box">
              <LoadingButton
                className="orderview-confirm-button"
                onClick={() => updatePickupOrderShippingStatus()}
                loading={fulfillmentIsLoading}
                disabled={fulfillmentIsLoading}
                variant={fulfillmentIsLoading ? "outlined" : "contained"}
              >
                {!fulfillmentIsLoading ? (
                  orderInfo.status === "Ready for Customer Pickup" ? (
                    translate("order-listing-screen.picked-up")
                  ) : orderInfo.status === "Served to Customer" ? (
                    translate("order-listing-screen.complete-order")
                  ) : (
                    ""
                  )
                ) : (
                  <CircularProgress size={20} color={"inherit"} />
                )}
              </LoadingButton>
            </div>
          </div>
        </div>
      );
    } else if (
      orderInfo.status === "Waiting Refund" &&
      (orderInfo.paymentType === "ManualPayment" ||
        orderInfo.paymentType === "PayAtCounter")
    ) {
      return (
        <div className="orderview-button-bottom">
          <div className="ordercomplete-button-holder">
            <div className="orderview-single-button-box">
              <LoadingButton
                className="orderview-reject-button"
                onClick={() => updateOrderRefundedStatus()}
                loading={fulfillmentIsLoading}
                disabled={fulfillmentIsLoading}
                variant={fulfillmentIsLoading ? "outlined" : "contained"}
              >
                {!fulfillmentIsLoading ? (
                  translate("order-listing-screen.refunded-to-customer")
                ) : (
                  <CircularProgress size={20} color={"inherit"} />
                )}
              </LoadingButton>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  let startTime;
  let elapsedTime = 0;

  function startDeliveryTimeout() {
    startTime = Date.now();

    // start interval to update elapsed time every 0.5 second
    const intervalId = setInterval(function () {
      elapsedTime = Date.now() - startTime;
      setRemainingTime(Math.max(0, MAX_REQUEST_TIME - elapsedTime));
    }, 500);

    // clear interval when timer is done
    setTimeout(function () {
      refreshOrderDetail();
      setShowRequestAnimation(false);
      clearInterval(intervalId);
    }, MAX_REQUEST_TIME);
  }

  const renderPage = () => {
    if (orderItemIsLoading) {
      return (
        <Box className="center-loader" sx={{ display: "flex" }}>
          <CircularProgress centered />
        </Box>
      );
    } else {
      return (
        <div>
          {orderInfo?.deliveryStatus === "Requested for Delivery"
            ? showRequestAnimation
              ? requestingRiderPage()
              : renderOrderInfo()
            : renderOrderInfo()}
        </div>
      );
    }
  };

  const requestingRiderPage = () => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: DeliveryAnimation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        height="80vh"
        margin="auto 0"
      >
        <Stack justifySelf="center" mt="auto">
          <Lottie options={defaultOptions} height={280} width={280} />
          <Typography
            sx={{
              fontFamily: "DM Sans",
            }}
          >
            Estimated duration of 3 minutes looking for rider..
          </Typography>
        </Stack>
        <Button
          variant="contained"
          disableElevation
          sx={{
            justifySelf: "flex-end",
            fontFamily: "DM Sans",
            fontSize: "14px",
            textTransform: "capitalize",
            color: "#FFF",
            borderRadius: "20px",
            marginTop: "auto",
            padding: "8px 28px",
          }}
          onClick={() => setShowRequestAnimation(false)}
        >
          Back
        </Button>
      </Stack>
    );
  };

  const renderHereButton = () => {
    return (
      <span
        style={{ textDecoration: "underline" }}
        onClick={() => sendRequestDelivery()}
      >
        {translate("order-listing-screen.here")}
      </span>
    );
  };

  return (
    <>
      <Header page={"order"}></Header>
      <CustomizedSnackbars
        message={snackbar.snackbarMessage}
        snackbarOpen={snackbar.snackbarOpen}
        snackbarClose={() =>
          setSnackbar({
            ...snackbar,
            snackbarOpen: false,
            snackbarSeverity: snackbar.snackbarSeverity,
          })
        }
        severity={snackbar.snackbarSeverity}
      />
      <div className="body body-bg new-body-padding-with-footer">
        {orderInfo.status === "Order Completed" ||
        orderInfo.status === "Waiting Refund" ||
        orderInfo.status === "Refunded" ? (
          <IoArrowBackSharp
            className="backbutton-styling"
            onClick={() => {
              if (query.get("from") === "history") {
                history.push("/kds/history");
              } else {
                history.goBack();
              }
            }}
          ></IoArrowBackSharp>
        ) : (
          <IoArrowBackSharp
            className="backbutton-styling"
            onClick={() => history.push("/kds/orders")}
          ></IoArrowBackSharp>
        )}
        {orderInfo.deliveryStatus === "No Rider Found" &&
        (orderInfo.status === "Shipping" ||
          orderInfo.status === "Order Fulfilled") ? (
          <div className="vieworder-warning-box">
            <p className="vieworder-warning-text">
              {translate("order-listing-screen.no-rider-available")}
              {translate("order-listing-screen.press")}
              {renderHereButton()}
              {translate("order-listing-screen.to-request")}
            </p>
          </div>
        ) : null}
        {renderPage()}
        {renderRejectModal()}
        {renderRejectConfirmModal()}
        {renderMerchantAdviseModal(orderInfo.grandTotal)}
      </div>
      <div className="body">{renderBottomButton()}</div>
    </>
  );
}

export default ViewOrder;
