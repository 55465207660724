import Moment from "moment";
import numeral from "numeral";
import produce from "immer";
import _ from "lodash";
import { API, graphqlOperation } from "aws-amplify";
import {
  adminAddTagsToModule,
  adminRemoveTagsFromModule,
  adminDeleteTags,
} from "../graphql/mutations";

export const getCurrentWindowScreenSize = () => {
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const height =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;
  return { width, height };
};

export const randomNumberGenerator = (from, to) => {
  return Math.floor(Math.random() * to) + from;
};

export const formatTime = (datetime) => {
  let hour = datetime.getHours();
  let minute = datetime.getMinutes();
  let hourStr = hour < 10 ? "0" + hour : hour;
  let minuteStr = minute < 10 ? "0" + minute : minute;
  return hourStr + ":" + minuteStr;
};

export const formatMomentDate = (date) => {
  return date ? Moment(date).format("DD/MM/YYYY") : "";
};

export const formatTimeToMoment = (time) => {
  try {
    let hour = parseInt(time.split(":")[0]);
    let minute = parseInt(time.split(":")[1]);
    let datetime = Moment().hours(hour).minute(minute).second(0);
    return datetime;
  } catch {
    return time;
  }
};

export const convertSecondsToMinutesAndSeconds = (duration) => {
  duration = parseInt(duration);
  let minutes = parseInt(duration / 60);
  let seconds = parseInt(duration % 60);
  if (minutes > 0) {
    return minutes + "m " + seconds + "s";
  } else {
    return seconds + "s";
  }
};

export const formatPrice = (price) => {
  if (price && isDecimal(price)) {
    let temp = numeral(roundTo(price, 2));
    return temp.format("0,0.00");
  } else {
    return price;
  }
};

//currency formatting 999.99 (accepts number)
export const inputCurrency = (input, hasMax = false) => {
  let newInput = parseFloat(input) < 0 ? 0 : input;
  let t = String(newInput);
  var newValue =
    t.toString().indexOf(".") >= 0 ? t.substr(0, t.toString().indexOf(".")) + t.substr(t.toString().indexOf("."), 3) : t;
  if (hasMax) {
    if (newValue > 99_999) {
      newValue = 1_00_000;
    }
  }
  return newValue;
};

//format input to percentage 0-100 (accepts number)
export const inputPercentage = (input) => {
  let newInput = input < 0 ? 0 : input;
  let newValue;
  if (input > 100) {
    newInput = 100;
  } else if (newInput < 0) {
    newInput = 0;
  }
  newValue = parseInt(newInput);

  return newValue;
};

export const inputQuantity = (input) => {
  let newInput = (input < 0 ? 0 : input).toString();
  let newValue;

  if (newInput.match(".")) {
    newValue = parseInt(newInput);
  }

  return newValue;
};

export const isDecimal = (str) => {
  return /^(?!-0(\.0+)?$)-?(0|[1-9]\d*)(\.\d+)?$/.test(str);
};

export const roundTo = (n, digits) => {
  if (digits === undefined) {
    digits = 0;
  }

  var multiplicator = Math.pow(10, digits);
  n = parseFloat((n * multiplicator).toFixed(11));
  var test = Math.round(n) / multiplicator;
  return +test.toFixed(digits);
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const toS3Link = (str, publicPath = true) => {
  if (str) {
    return encodeURI(
      `https://d2txwcz8p1q7po.cloudfront.net/${publicPath ? "public/" : ""
      }${str}`
    );
  } else {
    return null;
  }
};

export const decodeS3Link = (str) => {
  if (str) {
    return decodeURI(`https://d2txwcz8p1q7po.cloudfront.net/public/${str}`);
  } else {
    return null;
  }
};

export const timeConverter = (lastPurchasedDateTime) => {
  function msToTime(ms) {
    let seconds = (ms / 1000).toFixed(0);
    let minutes = (ms / (1000 * 60)).toFixed(0);
    let hours = (ms / (1000 * 60 * 60)).toFixed(0);
    let days = (ms / (1000 * 60 * 60 * 24)).toFixed(0);
    if (seconds < 60) return seconds + " Seconds";
    else if (minutes < 60) return minutes + " Minutes";
    else if (hours < 24) return hours + " Hours";
    else return days + " Days";
  }
  const dateToReformat = new Date(lastPurchasedDateTime);
  const today = new Date();
  const milliseconds = Math.abs(today - dateToReformat);

  return msToTime(milliseconds);
};

export const combination = ([head, ...[headTail, ...tailTail]]) => {
  if (!headTail) {
    return !!head
      ? head.map((o) => (!!Array.isArray(o) ? o.flat(Infinity) : [o]))
      : [];
  }

  const combined = headTail.reduce((acc, x) => {
    return acc.concat(head.map((h) => [h, x]));
  }, []);

  return combination([combined, ...tailTail]);
};

export const profitMarginCalculator = (sellingPrice, cost) => {
  let sp = !!sellingPrice
    ? typeof sellingPrice === "number"
      ? sellingPrice
      : Number(sellingPrice)
    : 0;
  let c = !!cost ? (typeof cost === "number" ? cost : Number(cost)) : 0;
  return sp === 0
    ? {
      profit: sp - c,
      margin: 0,
    }
    : {
      profit: sp - c,
      margin: ((sp - c) / sp) * 100,
    };
};

export const taxCalculator = (price, taxPercentage = 6, type = "multiply") => {
  let inputPrice = !!price
    ? typeof price === "number"
      ? price
      : Number(price)
    : 0;
  let result = inputPrice * ((100 + taxPercentage) / 100);
  if (type === "divide") {
    result = inputPrice / ((100 + taxPercentage) / 100);
  }

  return roundTo(result, 2);
};

export const downloadFile = (blob, filename) => {
  let url = URL.createObjectURL(
    new Blob([blob])
  );
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
  return a;
};

export const uploadFileToS3 = (signedUrl, file) => {
  let form = new FormData();
  const newData = JSON.parse(signedUrl);
  Object.keys(newData.fields).forEach((key) =>
    form.append(key, newData.fields[key])
  );
  form.append("file", file);
  return fetch(newData.url, { method: "POST", body: form });
};

export const convertISOStringDateFormat = (dateTimeArray) => {
  var formattedDateTime;
  if (dateTimeArray.length !== 0 && !!dateTimeArray[0] && !!dateTimeArray[1]) {
    let modDate = Moment(dateTimeArray[0]).format("YYYY-MM-DD");
    let combinedDateTime = Moment(
      `${modDate} ${dateTimeArray[1]}`,
      "YYYY-MM-DD HH:mm:ss"
    ).format();
    formattedDateTime = new Date(combinedDateTime).toISOString();
    var len = formattedDateTime.length;
    formattedDateTime =
      formattedDateTime.slice(0, -1) +
      "000" +
      formattedDateTime.substring(len - 1);
  } else {
    formattedDateTime = null;
  }
  return formattedDateTime;
};

export const isInclusivelyAfterDay = (a, b) => {
  function isBeforeDay(a, b) {
    if (!Moment.isMoment(a) || !Moment.isMoment(b)) return false;

    const aYear = a.year();
    const aMonth = a.month();

    const bYear = b.year();
    const bMonth = b.month();

    const isSameYear = aYear === bYear;
    const isSameMonth = aMonth === bMonth;

    if (isSameYear && isSameMonth) return a.date() < b.date();
    if (isSameYear) return aMonth < bMonth;
    return aYear < bYear;
  }
  if (!Moment.isMoment(a) || !Moment.isMoment(b)) return false;
  return !isBeforeDay(a, b);
};

export const getMaxValue = (prev, curr, prevStatus = false) => {
  let max = 0;
  if (prevStatus && prev > curr) {
    max = prev;
  } else {
    max = curr;
  }
  let numLength = Math.ceil(Math.log(max + 1) / Math.LN10);
  let newMax = max;
  if (numLength <= 2) {
    newMax = Math.round(max / 10) * 10 + 10;
  } else if (numLength === 3) {
    newMax = Math.round(max / 100) * 100 + 100;
  } else if (numLength === 4) {
    newMax = Math.round(max / 1000) * 1000 + 1000;
  } else if (numLength === 5) {
    newMax = Math.round(max / 10000) * 10000 + 10000;
  }
  return newMax;
};

export const calculatePrevPeriod = (oldValue) => {
  let monthRange = false;
  let compareTo = oldValue.compareTo;
  let currentStartDate = oldValue.startDate._d;
  let currentEndDate = oldValue.endDate._d;
  const startOfMonth = Moment(oldValue.startDate)
    .startOf("month")
    .format("YYYY-MM-DD");
  const endOfMonth = Moment(oldValue.endDate)
    .endOf("month")
    .format("YYYY-MM-DD");
  if (
    Moment(oldValue.startDate).format("YYYY-MM-DD") === startOfMonth &&
    Moment(oldValue.endDate).format("YYYY-MM-DD") === endOfMonth
  ) {
    monthRange = true;
  }

  let prevStartDate = null;
  let prevEndDate = null;
  if (compareTo === "previous-period") {
    if (!monthRange) {
      const diffInMs = Math.abs(currentEndDate - currentStartDate);
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24) + 1;
      prevStartDate = Moment(oldValue.startDate)
        .subtract(diffInDays, "days")
        .format("YYYY-MM-DD");
      prevEndDate = Moment(oldValue.endDate)
        .subtract(diffInDays, "days")
        .format("YYYY-MM-DD");
    } else {
      prevStartDate = Moment(oldValue.startDate)
        .subtract(1, "months")
        .format("YYYY-MM-DD");
      prevEndDate = Moment(oldValue.endDate)
        .subtract(1, "months")
        .format("YYYY-MM-DD");
    }
  } else {
    prevStartDate = Moment(oldValue.startDate)
      .subtract(1, "years")
      .format("YYYY-MM-DD");
    prevEndDate = Moment(oldValue.endDate)
      .subtract(1, "years")
      .format("YYYY-MM-DD");
  }

  return { prevStartDate, prevEndDate };
};

export const isValidStringWithNoSpace = (obj) => {
  let pattern = /^[^\s].*$/

  if (pattern.test(obj)) {
    return true;
  } else {
    return false;
  }
};

export const isValidPhoneNumber = (obj) => {
  //validate malaysia phone no
  if (obj.substr(0, 3) === "+60" && obj.length >= 12) {
    return true;
  }
  //valdiate singapore phone no
  else if (obj.substr(0, 3) === "+65" && obj.length >= 11) {
    return true;
  } else {
    return false;
  }
};

export const isValidEmailAddress = (item) => {
  var validEmailPattern =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  return item.match(validEmailPattern);
};

export const getPublicWebUrl = () => {
  return `https://m1.fam-dev.click/`;
};

export const copyToClipboard = (value, tooltipId) => {
  const tempInput = document.createElement("input");
  tempInput.value = value;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand("copy");
  document.body.removeChild(tempInput);
  document.getElementById(tooltipId).style.visibility = "visible";
  setTimeout(function () {
    document.getElementById(tooltipId).style.visibility = "hidden";
  }, 3000);
};

/**
 * @returns {string} Return empty for no issues & error message for validation issues
 */
export const onSaveValidation = async (params, schema) => {
  let validationReturn = "";
  let formData = params;
  await schema.validate(formData).catch((err) => {
    validationReturn = err.message;
  });
  return validationReturn;
};

export const redirectToPath = (history, path, state = null) => {
  if (state !== null) {
    history.push({
      pathname: path,
      state: state,
    });
  } else {
    history.push({
      pathname: path,
    });
  }
};

export const getSelectedDataTagList = async (
  merchantId,
  dataList,
  adminGetData,
  moduleType,
  getDataApi,
  dataIdKey,
  dataTagKey,
  setSelectedData = () => { }
) => {
  try {
    // fetch selected data current tag list
    let selectedDataIdList = [];
    selectedDataIdList = dataList
      .filter((obj) => obj.isChecked)
      .map((obj) => obj[dataIdKey]);

    let selectedData = [];

    selectedDataIdList.forEach(async (dataId) => {
      let productTagParams = {
        merchantId,
      };

      productTagParams[dataIdKey] = dataId;

      let dataTags = null;
      let dataTagRes = null;
      dataTagRes = await API.graphql(
        graphqlOperation(adminGetData, productTagParams)
      );
      let index = selectedData.length;
      if (moduleType === "product" || moduleType === "order") {
        dataTags = dataTagRes.data[getDataApi][moduleType].taggingNames
          ? dataTagRes.data[getDataApi][moduleType].taggingNames
          : [];
      } else {
        dataTags = dataTagRes.data[getDataApi].taggingNames
          ? dataTagRes.data[getDataApi].taggingNames
          : [];
      }
      selectedData.push({});
      selectedData[index][dataIdKey] = dataId;
      selectedData[index][dataTagKey] = dataTags;
    });
    setSelectedData(selectedData);
  } catch (error) {
    console.log(error);
  }
};

export const addTaggingToSelectedData = async (
  merchantId,
  dataTagList,
  setSnackbar = () => { },
  setOpenAddTagModal = () => { },
  setTaggingIsLoading = () => { },
  type,
  dataIdKey,
  tagsToAdd
) => {
  setTaggingIsLoading(true);
  try {
    let selectedDataIdList = [];
    selectedDataIdList = dataTagList
      .filter((obj) => obj.isChecked)
      .map((obj) => obj[dataIdKey]);

    let params = {
      merchantId: merchantId,
      type: type,
      typeIdList: selectedDataIdList,
      taggingList: tagsToAdd,
    };
    let res = null;
    res = await API.graphql(graphqlOperation(adminAddTagsToModule, params));

    if (res.data.adminAddTagsToModule.status === "true") {
      setSnackbar({
        snackbarMessage: res.data.adminAddTagsToModule.message,
        snackbarOpen: true,
        snackbarSeverity: "success",
      });
      window.location.reload();
    }
    setOpenAddTagModal(false);
    setTaggingIsLoading(false);
  } catch (error) {
    setSnackbar({
      snackbarMessage: "Something went wrong. Please try again.",
      snackbarOpen: true,
      snackbarSeverity: "error",
    });
    setTaggingIsLoading(false);
  }
};

export const removeTaggingFromSelectedData = async (
  merchantId,
  dataList,
  setSnackbar = () => { },
  setOpenAddTagModal = () => { },
  setTaggingIsLoading = () => { },
  tagsToRemove,
  dataIdKey,
  type
) => {
  let selectedDataIdList = [];
  selectedDataIdList = dataList
    .filter((obj) => obj.isChecked)
    .map((obj) => obj[dataIdKey]);
  setTaggingIsLoading(true);
  try {
    let params = {
      merchantId: merchantId,
      type: type,
      typeIdList: selectedDataIdList,
      taggingList: tagsToRemove,
    };
    let res = null;
    res = await API.graphql(
      graphqlOperation(adminRemoveTagsFromModule, params)
    );
    if (res.data.adminRemoveTagsFromModule.status === "true") {
      setSnackbar({
        snackbarMessage: res.data.adminRemoveTagsFromModule.message,
        snackbarOpen: true,
        snackbarSeverity: "success",
      });
      setOpenAddTagModal(false);
      window.location.reload();
    }
    setTaggingIsLoading(false);
  } catch (error) {
    setSnackbar({
      snackbarMessage: "Something went wrong. Please try again.",
      snackbarOpen: true,
      snackbarSeverity: "error",
    });
    setTaggingIsLoading(false);
  }
};

export const handleDeleteTag = async (
  merchantId,
  tagToDelete,
  type,
  setSnackbar = () => { },
  existingTagList,
  setExistingTagList = () => { },
  setConfirmDelete = () => { }
) => {
  let params = {
    merchantId: merchantId,
    taggingList: [tagToDelete],
    type: type,
  };
  try {
    const res = await API.graphql(graphqlOperation(adminDeleteTags, params));
    if (res.data.adminDeleteTags.status === "true") {
      setSnackbar({
        snackbarMessage: "Success",
        snackbarOpen: true,
        snackbarSeverity: "success",
      });

      let tmpExistingTagList = produce(existingTagList, (draft) => {
        let tagToDeleteIndex = draft.findIndex((tag) => tag === tagToDelete);
        draft.splice(tagToDeleteIndex, 1);
      });

      setConfirmDelete("");
      setExistingTagList(tmpExistingTagList);
    } else {
      setSnackbar({
        snackbarMessage: res.data.adminDeleteTags.message,
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
    }
  } catch (error) {
    console.log(error);
    setSnackbar({
      snackbarMessage: "Something went wrong. Please try again.",
      snackbarOpen: true,
      snackbarSeverity: "error",
    });
  }
};

export const exampleProductCSVDownloadLink = `https://ecom-bulk-upload-bucket-dev.s3.amazonaws.com/
product/ProductFullList_2022-02-09_13%3A35%3A57.zip?AWSAccessKeyId=ASIAYPIXR4PAPD76LSBE&Signature=W
LK2ketre84DAe2alUB25Ytk9tY%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEMb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2
FwEaDmFwLXNvdXRoZWFzdC0xIkcwRQIgT8etzCWxhWCbgG%2Fq5UKju2D0WiC52%2FrRxIgD2j%2BgJBUCIQCw8k5i0XR9VdASJ
Zhrf0urmZMMLDcT2TIjQTbS74lVGCqsAgj%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAEaDDU4MjU1NDM0NjQzMiIMULJde1
Gv%2BDrJ7%2BUvKoACYgJfeYZ1aJ31v7kAHhovPk9yNSOcjqedkkZTLcCt87CiwOIGI4LmcZykP%2Bqm3wdNdMaiLXF5Izm4X%2
Bro3qbAU7sA8C4mVOA0k%2Fbf4%2FE3Kbln%2FNWuVLhmHhTazIi5aDjUIeXuc%2Bv%2FoCO6i02BJcSlz3sihOrb8bMraB8WBy
V7X7JwdBiE1L3QomxN8oWuRG2N08qMPxGsEdkIseQv%2B5mIoxbleF5s8me4YaLwoHwuAbDU1gb%2B9Ars2aX30dOUK7XsOdU3d
KZMl%2Bp4ndH5W3ihppfwP5k2bWG9k0LhTG6NNDUG42rzbAYOO26uI0F0VZzoHveyEl1YKR9KCz6%2FjwpB1jDMpI2QBjqaAb19
sbe3e4DXZW75yVg0udDv25IBMF3h6mS8gWW0228263Lgwn%2BNoPM0yCtKSv26zj7fVNZKEz3qurGs%2B6l0AUPr0t7UexbEWAZ
ShRQC6HbLw5ulnZK2Tpv2hNLTjZgdC4KRwQbIcAQbZVbKx6EXe9otIYlqQh9PGPBj%2B6u87%2FzdvEw3zybdgpMnQG8eSh0QGB
7ydwgq9CwVs%2B0%3D&Expires=1644471358`;

export const gatewayUrl = 'https://jctkgyk62i.execute-api.ap-southeast-1.amazonaws.com/dev';

export const handleUrlLink = (url) => {
  let newUrl = null;
  if (!url.includes("www")) {
    if (url.startsWith("https://")) {
      newUrl = [url.slice(0, 8), "www.", url.slice(8)].join("");
    } else {
      newUrl = "www." + url;
    }
  } else {
    return url;
  }
  return newUrl;
};

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const deliveryNamesMapping = (value) => {
  if (_.includes(value, "Skynet")) {
    return "Skynet Express";
  } else if (_.includes(value, "J&amp;T Express")) {
    return "J&T Express";
  } else if (_.includes(value, "Pgeon")) {
    return "Pgeon";
  } else if (_.includes(value, "ABX")) {
    return "ABX Express";
  } else if (_.includes(value, "Zepto")) {
    return "Zepto Express";
  } else if (_.includes(value, "DHL")) {
    return "DHL eCommerce";
  } else if (_.includes(value, "Aramex")) {
    return "Aramex";
  } else {
    return value;
  }
};

export const handleSeoUrl = (productTitle) => {
  let seoUrl = productTitle.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\//\s]/gi, "-");
  return seoUrl;
};

export const formatEmail = (value, allowAlias) => {
  let formattedEmail = "";
  if (allowAlias) {
    formattedEmail = value.replace(/[^a-zA-Z0-9-@._ ]/gm, "");
  } else {
    formattedEmail = value.replace(/[^a-zA-Z0-9-._ ]/gm, "");
  }

  return formattedEmail;
};

/**
 * Conserve aspect ratio of the original region. Useful when shrinking/enlarging
 * images to fit into a certain area.
 *
 * @param {Number} srcWidth width of source image
 * @param {Number} srcHeight height of source image
 * @param {Number} maxWidth maximum available width
 * @param {Number} maxHeight maximum available height
 * @return {Object} { width, height }
 */
export const calculateAspectRatioFit = (
  srcWidth,
  srcHeight,
  maxWidth,
  maxHeight
) => {
  var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

  return { width: srcWidth * ratio, height: srcHeight * ratio };
};

export const openInNewTab = (link) => {
  const url = link.includes("http") ? link : "https://" + link;
  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
  return a;
};

export const inputPreventDefault = (evt, isWholeNumber = false) => {
  if (isWholeNumber) {
    if (["e", "E", "+", "-", "."].includes(evt.key)) evt.preventDefault();
  } else {
    if (["e", "E", "+", "-"].includes(evt.key)) evt.preventDefault();
  }
};