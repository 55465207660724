import { ArrowBack } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  CircularProgress,
  Typography,
  Box,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslate } from "react-polyglot";
import { useHistory } from "react-router-dom";
import Header from "../../components/header/Header";
import { MerchantContext } from "../../context/MerchantContext";
import { useQuery } from "../../hooks/useQuery";
import styles from "./PackageOffer.module.scss";
// AWS apis
import axios from "axios";
import { CustomizedSnackbars } from "../../components/shareable/Shareable";
import { gatewayUrl } from "../../utils/shareable-utils";
import { Auth } from "aws-amplify";

function ExpiredPrompt(props) {
  const translate = useTranslate();
  let history = useHistory();
  let query = useQuery();
  const merchantInfoContext = useContext(MerchantContext);
  const TERMS_OF_SERVICES_URL =
    "https://www.ewarung.shop/_files/ugd/0d813e_3a9ea7c805db4e6f953e516cab52f347.pdf";
  const PRIVACY_POLICIES_URL =
    "https://www.ewarung.shop/_files/ugd/0d813e_eb3690bbd71645acbc5e0ded0a2d475d.pdf";
  const [paymentURLIsLoading, setPaymentUrlIsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionPackageList, setSubscriptionPackageList] = useState([]);
  const [addonPackageList, setAddonPackageList] = useState([]);
  const [hasAddon, setHasAddon] = useState(false);
  const [totalAmt, setTotalAmt] = useState(0.0);
  const [totalDiscount, setTotalDiscount] = useState(0.0);
  const [selectedId, setSelectedId] = useState("");
  const [termsChecked, setTermsChecked] = useState(false);
  const [promoCode, setPromoCode] = useState(undefined);
  const [appliedPromoCode, setAppliedPromoCode] = useState(null);

  const whatsAppCRMStyles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    },
    box: {
      width: 300,
      padding: 20,
      border: "1px solid #ccc",
      borderRadius: 4,
    },
  };

  const [snackbar, setSnackbar] = useState({
    snackbarMessage: "",
    snackbarOpen: false,
    snackbarSeverity: "info",
  });

  var inputPromoCodeRef = useRef(null);
  const onPromoCodeSubmit = (e) => {
    e.preventDefault();
    if (promoCode != null) {
      getSubscriptionPackage();
    }
  };

  const handleSignout = async () => {
    try {
      await Auth.signOut();
      localStorage.clear();
      window.location.href = "/login";
    } catch (e) {
      console.log(e);
    } 
  };

  const getSubscriptionPackage = async () => {
    setIsLoading(true);
    let params = `phoneNumber=${merchantInfoContext.mobileNo}`;
    if (selectedId) {
      params += `&packageId=${selectedId}`;
    }
    if (promoCode) {
      params += `&promoCode=${promoCode}`;
    }

    axios
      .get(`${gatewayUrl}/merchant/getmerchantservicecatalog?${params}`)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          setSubscriptionPackageList(data.subscriptionPackageList);
          setSelectedId(data?.subscriptionPackageList[0]?.packageId);
          setAddonPackageList(data.addOnPackageList);
          setHasAddon(data.hasAddon);
          setTotalAmt(data.totalAmount);
        }

        if (promoCode) {
          if (data.totalDiscount != null && data.totalDiscount != 0) {
            setPromoCode("");
            setTotalDiscount(data.totalDiscount);
            setAppliedPromoCode(promoCode);
            setSnackbar({
              snackbarMessage: translate("onboarding-screen.success-promocode"),
              snackbarOpen: true,
              snackbarSeverity: "success",
            });
          } else {
            setAppliedPromoCode(null);
            setPromoCode("");
            setSnackbar({
              snackbarMessage: translate("onboarding-screen.failed-promocode"),
              snackbarOpen: true,
              snackbarSeverity: "error",
            });
          }
        }
      })
      .catch((err) => {
        console.log("err: ", err);
        let errorResponse = err.response;
        const { status, data } = errorResponse;
        if (status === 400) {
          setSnackbar({
            snackbarMessage: data,
            snackbarOpen: true,
            snackbarSeverity: "error",
          });
        } else {
          setSnackbar({
            snackbarMessage: translate("error-handler.server-error"),
            snackbarOpen: true,
            snackbarSeverity: "error",
          });
        }
      });
    setIsLoading(false);
  };
  const purchaseSubscription = async () => {
    if (merchantInfoContext.packageSubscription) {
      setPaymentUrlIsLoading(true);
      let params = {
        packageId: selectedId.split(","),
        phoneNumber: merchantInfoContext.mobileNo,
        isRenew: true,
      };

      axios
        .post(`${gatewayUrl}/merchant/merchantpayforonboard`, params)
        .then((res) => {
          let paymentUrl = res.data;
          window.location.href = paymentUrl;
          setPaymentUrlIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setSnackbar({
            snackbarMessage: translate("error-handler.server-error"),
            snackbarOpen: true,
            snackbarSeverity: "error",
          });
          setPaymentUrlIsLoading(false);
        });
    } else {
      setSnackbar({
        snackbarMessage: translate("error-handler.server-error"),
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
    }
  };

  const renewSubscription = async () => {
    setPaymentUrlIsLoading(true);
    let params = {
      packageIds: selectedId.split(","),
      phoneNumber: merchantInfoContext.mobileNo,
    };

    axios
      .post(`${gatewayUrl}/merchant/merchantrenewsubscription`, params)
      .then((res) => {
        let paymentUrl = res.data;
        window.location.href = paymentUrl;
        setPaymentUrlIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setSnackbar({
          snackbarMessage: translate("error-handler.server-error"),
          snackbarOpen: true,
          snackbarSeverity: "error",
        });
        setPaymentUrlIsLoading(false);
      });
  };

  const handleCheck = (packageId) => {
    let tempSelectId = selectedId;
    if (tempSelectId.includes(packageId)) {
      tempSelectId = tempSelectId.replace(packageId, "");
      let length = tempSelectId.length;
      if (tempSelectId.startsWith(",")) {
        tempSelectId = tempSelectId.slice(1, length);
      }
      if (tempSelectId.endsWith(",")) {
        tempSelectId = tempSelectId.slice(0, length - 1);
      }
    } else {
      if (tempSelectId === "") {
        tempSelectId = tempSelectId + packageId;
      } else {
        tempSelectId = tempSelectId + "," + packageId;
      }
    }
    setSelectedId(tempSelectId);
  };

  useEffect(() => {
    if (merchantInfoContext.storeType !== "whatsAppCrm") {
      getSubscriptionPackage();
    }
  }, [selectedId]);

  return (
    <div>
      <Header></Header>
      <CustomizedSnackbars
        message={snackbar.snackbarMessage}
        snackbarOpen={snackbar.snackbarOpen}
        snackbarClose={() =>
          setSnackbar({
            ...snackbar,
            snackbarOpen: false,
            snackbarSeverity: snackbar.snackbarSeverity,
          })
        }
        severity={snackbar.snackbarSeverity}
      />
      {merchantInfoContext.storeType === "whatsAppCrm" ? (
        <div style={whatsAppCRMStyles.container}>
          <Box style={whatsAppCRMStyles.box}>
            <Typography variant="h6" textAlign={"center"}>
              Subscription expired, kindly inform your administrator to renew
              the subscription to access our services
            </Typography>
            <Box paddingTop={4}>
            <Button
              variant="contained"
              className="orderview-confirm-button"
              color="primary"
              onClick={() => handleSignout()}
            >
             Sign Out
            </Button>
            </Box>
            
          </Box>
        </div>
      ) : (
        <div>
          {query.get("from") === "subscription-history" && (
            <div className={styles.backbuttonrow}>
              <Button
                className={styles.backbutton}
                sx={{ borderRadius: "20px" }}
                onClick={history.goBack}
              >
                <ArrowBack className={styles.backicon} />
                <h3>{translate("onboarding-screen.back")}</h3>
              </Button>
            </div>
          )}
          <div className={styles.packagescreen}>
            <div className={styles.containerwrapper}>
              <h1 className={styles.packageoffertitle}>
                {query.get("from") === "subscription-history"
                  ? query.get("type") === "free-trial"
                    ? translate("onboarding-screen.subscribe-title")
                    : translate("onboarding-screen.renew-title")
                  : translate("onboarding-screen.your-store-locked")}
              </h1>
              <Typography
                style={{ textAlign: "center" }}
                color="textSecondary"
                gutterBottom
              >
                <small className={styles.packageofferdesc}>
                  {query.get("from") === "subscription-history"
                    ? query.get("type") === "free-trial"
                      ? translate("onboarding-screen.subscribe-details")
                      : translate("onboarding-screen.renew-details")
                    : translate("onboarding-screen.to-continue")}
                </small>
              </Typography>

              <br></br>

              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress size={20} color={"inherit"} />
                </div>
              ) : (
                <>
                  <div className="orderhome-flex-box">
                    <div>
                      <h1 className="orderhome-orderinfo-large">
                        {translate("onboarding-screen.plan")}
                      </h1>
                    </div>
                    <div className="push-right">
                      <h1
                        className="orderhome-orderinfo-large"
                        style={{ textTransform: "none" }}
                      >
                        {translate("onboarding-screen.amount")}
                      </h1>
                    </div>
                  </div>

                  {subscriptionPackageList &&
                    subscriptionPackageList.map((subsPackage, index) => (
                      <div className={styles.packageswrapper} key={index}>
                        <div className={styles.packagesrow}>
                          <div className={styles.packagesleading}>
                            <Checkbox
                              style={{ padding: 0 }}
                              onChange={() =>
                                handleCheck(subsPackage.packageId)
                              }
                              checked={selectedId.includes(
                                subsPackage.packageId
                              )}
                              disabled={true}
                            />
                          </div>

                          <div className={styles.packagescontent}>
                            <h1 className="orderhome-orderinfo-large">
                              {translate(
                                `onboarding-screen.${subsPackage.packageTitle.title}`
                              )}
                            </h1>

                            {subsPackage.description.map((descr, idx) => (
                              <h1
                                className="orderhome-orderinfo-small-2"
                                key={idx}
                              >
                                {translate(`onboarding-screen.${descr}`)}
                              </h1>
                            ))}

                            <h1 className="orderhome-orderinfo-small-2">
                              {subsPackage.packageTitle.subtitle}
                            </h1>
                          </div>

                          <div className={styles.packagestrailing}>
                            {subsPackage.discountedPrice ? (
                              <>
                                <h1 className="orderhome-orderinfo-large">
                                  {merchantInfoContext.currency}{" "}
                                  {subsPackage.discountedPrice}
                                </h1>
                                <h1
                                  className="orderhome-orderinfo-small-2"
                                  style={{
                                    textTransform: "none",
                                    textDecoration: "line-through",
                                  }}
                                >
                                  {merchantInfoContext.currency}{" "}
                                  {subsPackage.price}
                                </h1>
                              </>
                            ) : (
                              <h1
                                className="orderhome-orderinfo-large"
                                style={{ textTransform: "none" }}
                              >
                                {merchantInfoContext.currency}{" "}
                                {subsPackage.price}
                              </h1>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}

                  {!hasAddon &&
                    addonPackageList &&
                    addonPackageList.map((addonPackage, index) => (
                      <div className={styles.packageswrapper} key={index}>
                        <div className={styles.packagesrow}>
                          <div className={styles.packagesleading}>
                            <Checkbox
                              style={{ padding: 0 }}
                              onChange={() =>
                                handleCheck(addonPackage.packageId)
                              }
                              checked={selectedId.includes(
                                addonPackage.packageId
                              )}
                            />
                          </div>

                          <div className={styles.packagescontent}>
                            <h1 className="orderhome-orderinfo-large">
                              {translate(
                                `onboarding-screen.${addonPackage.packageTitle.title}`
                              )}
                            </h1>

                            <ul>
                              {addonPackage.description.map((descr, idx) => (
                                <li
                                  className="orderhome-orderinfo-small-2"
                                  key={idx}
                                >
                                  {translate(`onboarding-screen.${descr}`)}
                                </li>
                              ))}
                            </ul>

                            <h1 className="orderhome-orderinfo-small-2">
                              {addonPackage.packageTitle.subtitle}
                            </h1>
                          </div>

                          <div className={styles.packagestrailing}>
                            {addonPackage.discountedPrice ? (
                              <>
                                <h1 className="orderhome-orderinfo-large">
                                  {merchantInfoContext.currency}{" "}
                                  {addonPackage.discountedPrice}
                                </h1>
                                <h1
                                  className="orderhome-orderinfo-small-2"
                                  style={{
                                    textTransform: "none",
                                    textDecoration: "line-through",
                                  }}
                                >
                                  {merchantInfoContext.currency}{" "}
                                  {addonPackage.price}
                                </h1>
                              </>
                            ) : (
                              <h1
                                className="orderhome-orderinfo-large"
                                style={{ textTransform: "none" }}
                              >
                                {merchantInfoContext.currency}{" "}
                                {addonPackage.price}
                              </h1>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}

                  <br></br>

                  <div className={styles.termsrow} style={{ display: "flex" }}>
                    <Checkbox
                      checked={termsChecked}
                      onChange={() => setTermsChecked(!termsChecked)}
                      style={{ padding: 0, margin: 0 }}
                    />
                    <h1
                      className="orderhome-orderinfo-small-2"
                      style={{ marginLeft: "20px" }}
                    >
                      {translate("onboarding-screen.i-agree-to-the")}
                      <a
                        onClick={() => window.open(TERMS_OF_SERVICES_URL)}
                        style={{
                          textDecoration: "underline",
                          color: "#008cdc",
                          cursor: "pointer",
                        }}
                      >
                        {translate("onboarding-screen.terms-of-services")}
                      </a>{" "}
                      &{" "}
                      <a
                        onClick={() => window.open(PRIVACY_POLICIES_URL)}
                        style={{
                          textDecoration: "underline",
                          color: "#008cdc",
                          cursor: "pointer",
                        }}
                      >
                        {translate("onboarding-screen.privacy-policy")}.
                      </a>
                      {translate("onboarding-screen.i-allow-ewarung")}
                    </h1>
                  </div>

                  <br></br>

                  <div className="orderhome-flex-box">
                    <div>
                      <h1 className="orderhome-orderinfo-large">
                        {translate("onboarding-screen.total-pay-now")}
                      </h1>
                    </div>
                    <div className="push-right">
                      <h1
                        className="orderhome-orderinfo-large"
                        style={{ textTransform: "none" }}
                      >
                        {merchantInfoContext.currency} {totalAmt}
                      </h1>
                    </div>
                  </div>

                  <br></br>

                  <div
                    className={styles.packageoffersinglebuttonbox}
                    style={{ marginTop: "20px" }}
                  >
                    <Button
                      variant="contained"
                      className="orderview-confirm-button"
                      color="primary"
                      disabled={!termsChecked || selectedId === ""}
                      onClick={() => {
                        if (query.get("from") === "subscription-history") {
                          renewSubscription();
                        } else {
                          purchaseSubscription();
                        }
                      }}
                    >
                      {!paymentURLIsLoading ? (
                        translate("login-handler.confirm")
                      ) : (
                        <CircularProgress size={20} color={"inherit"} />
                      )}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ExpiredPrompt;
