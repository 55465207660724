import { Link, useHistory } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "./Faqs.scss";

const Faqs = () => {
  const history = useHistory();

  return (
    <>
      <Header />
      <div className="faqs-wrapper">
        <div className="faq-back">
          <Link to={"/kds/settings"}>
            <IoCloseSharp className="backbutton-styling" />
          </Link>
        </div>
        <iframe
          id="hs-support-frame"
          src="https://helpcenter.demeter.ewarung.shop"
          width="100%"
          height="100%"
          title="Help Center"
          style={{ overflow: "auto", border: 0 }}
        ></iframe>
        {/*<object data="https://helpcenter.demeter.ewarung.shop" width="100%" height="100%">
        <embed src="https://helpcenter.demeter.ewarung.shop" width="100%" height="100%" />
          Error: Embedded data could not be displayed.
      </object>
      */}
      </div>
      <Footer />
    </>
  );
};

export default Faqs;
