import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import { MerchantContext } from "../../../context/MerchantContext";
import { useTranslate } from "react-polyglot";
import { OutlinedButton } from "../../../components/buttons/Buttons";
import {
  CustomizedSnackbars,
  CustomSwitch,
} from "../../../components/shareable/Shareable";
import "./PaymentMethodPage.scss";
import { IconButton, CircularProgress } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { IoArrowBackSharp, IoChevronForwardOutline } from "react-icons/io5";
import { CustomInputBase } from "../../../components/custom-input-base/CustomInputBase";
import { UploadDropZone } from "../../../components/upload-dropzone/UploadDropZone";
import { SHARED_ERROR_MESSAGE, MEDIA_MODULES } from "../../../constants";
import { v4 as uuid } from "uuid";
import { useQuery } from "../../../hooks/useQuery";
import { CustomLoadingButton } from "../../../components/buttons/Buttons";
import { validator } from "../../../utils";
import ReactPlayer from "react-player/youtube";
import { Box, Stack } from "@mui/system";
import { Typography, TextField, Divider } from "@material-ui/core";
import { Switch } from "@mui/material";
import { styled } from "@mui/material/styles";

// AWS apis
import { API, graphqlOperation, Storage } from "aws-amplify";
import {
  adminListManualPaymentOptions,
  adminGetMerchantPaymentConfig,
} from "../../../graphql/queries";
import {
  adminCreateManualPaymentOption,
  adminUpdateManualPaymentOption,
  adminUpdateMerchantPaymentConfig,
} from "../../../graphql/mutations";

function PaymentMethodPage(props) {
  const history = useHistory();

  const [snackbar, setSnackbar] = useState({
    snackbarMessage: "",
    snackbarOpen: false,
    snackbarSeverity: "info",
  });
  const merchantInfoContext = useContext(MerchantContext);
  const translate = useTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const [isApiProcessing, setIsApiProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [isSetting, setIsSetting] = useState(false);
  const [canSaveEmpty, setCanSaveEmpty] = useState(false);
  let query = useQuery();

  const [paymentGatewayData, setPaymentGatewayData] = useState({
    merchantId: merchantInfoContext.merchantId,
    serviceId: "",
    servicePassword: "",
    companyName: "",
    isDisabled: false,
  });

  // upload qr code drop zone usage
  const [QRImage, setQRImage] = useState([]);
  const onDropImg = (acceptedFiles) => {
    if (acceptedFiles.length > 1) {
      setSnackbar({
        snackbarMessage:
          "Please upload an image file (e.g. JPG, JPEG, PNG file types)",
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
      return;
    }
    if (!acceptedFiles[0].type.includes("image")) {
      setSnackbar({
        snackbarMessage: "Please upload one image at a time",
        snackbarOpen: true,
        snackbarSeverity: "warning",
      });
      return;
    }

    let imageFile = acceptedFiles[0];
    let reader = new FileReader();
    reader.onload = (e) => {
      let img = document.createElement("img");
      img.onload = (event) => {
        let MAX_WIDTH = 600;

        let width = img.width;
        let height = img.height;

        if (width > MAX_WIDTH) {
          let aspectRatio = width / height;
          width = MAX_WIDTH;
          height = width / aspectRatio;

          // Dynamically create a canvas element
          let canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          let ctx = canvas.getContext("2d");

          // Actual resizing
          ctx.drawImage(img, 0, 0, width, height);

          // Set resized image to image variable
          canvas.toBlob((blob) => {
            let tmpAcceptedFilesArray = [];
            tmpAcceptedFilesArray.push(
              Object.assign(blob, {
                preview: URL.createObjectURL(blob),
              })
            );
            setQRImage(tmpAcceptedFilesArray);
          }, imageFile.type);
        } else {
          let tmpAcceptedFilesArray = [];
          tmpAcceptedFilesArray.push(
            Object.assign(imageFile, {
              preview: URL.createObjectURL(imageFile),
            })
          );
          setQRImage(tmpAcceptedFilesArray);
        }
      };

      img.src = e.target.result;
    };
    reader.readAsDataURL(imageFile);
  };

  const [TNGPaymentLink, setTNGPaymentLink] = useState();
  const [paymentInstructions, setPaymentInstructions] = useState();
  const handleInputBaseChange = (e) => {
    let value = e.target.value === " " ? "" : e.target.value;
    if (e.target.name === "tng-payment-instruction") {
      setPaymentInstructions(value);
    } else if (e.target.name === "tng-payment-link") {
      setTNGPaymentLink(value);
    }
  };
  const [bankTransferData, setBankTransferData] = useState({
    accountNumber: "",
    bankAccountName: "",
    manualPaymentMethodName: "",
  });

  const handleBankTransferDataChange = (e) => {
    if (e.target.value === " ") {
      setBankTransferData({ ...bankTransferData, [e.target.name]: "" });
    } else {
      setBankTransferData({
        ...bankTransferData,
        [e.target.name]: e.target.value,
      });
    }
  };

  useEffect(() => {
    if (paymentMethod === "tng" && paymentMethod === "duitnow") {
      if (!paymentInstructions) {
        setCanSaveEmpty(false);
      } else {
        setCanSaveEmpty(true);
      }
    } else {
      setCanSaveEmpty(true);
    }
  }, [paymentMethod, paymentInstructions]);

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#24bf27",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#bf2822" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const handlePaymentGatewayChange = (e) => {
    if (e.target.value === " ") {
      setPaymentGatewayData({ ...paymentGatewayData, [e.target.name]: "" });
    } else {
      setPaymentGatewayData({
        ...paymentGatewayData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSwitchChange = (event) => {
    setPaymentGatewayData({
      ...paymentGatewayData,
      isDisabled: event.target.checked,
    });
    updatePaymentMethod();
  };

  const handleSavePaymentMethod = async () => {
    if (
      (paymentMethod === "tng" &&
        !QRImage?.length &&
        (validator.isEmptyString(TNGPaymentLink) ||
          !validator.isUrl(TNGPaymentLink))) ||
      !canSaveEmpty ||
      (paymentMethod === "duitnow" && !QRImage?.length) ||
      (paymentMethod === "bank" &&
        (!bankTransferData.accountNumber ||
          !bankTransferData.bankAccountName ||
          !bankTransferData.manualPaymentMethodName))
    ) {
      setSnackbar({
        snackbarMessage: getPaymentError(),
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
      return;
    }

    setIsApiProcessing(true);
    let QrImageParams = "";
    const QrImageUrl = `${merchantInfoContext.merchantId}/kds/manual-payment/qr`;

    if (QRImage.length > 0) {
      if (typeof QRImage[0] === "object") {
        QrImageParams = `${QrImageUrl}/${uuid()}.jpg`;
      } else {
        QrImageParams = QRImage[0];
      }
    }

    if (typeof QRImage[0] === "object" && QRImage && QRImage.length > 0) {
      await Storage.put(`${QrImageParams}`, QRImage[0]);
    }

    try {
      let params = {};
      let res = null;
      let status = "false";
      let message = "";

      if (paymentMethod === "tng" || paymentMethod === "duitnow") {
        params = {
          isBankTransfer: false,
          isDisabled: false,
          manualPaymentMethodName: paymentMethod === "tng" ? "TnG" : "DuitNow",
          paymentInstructions: paymentInstructions,
          qrCode: QrImageParams,
        };

        if (paymentMethod === "tng") {
          params["TNGPaymentLink"] = TNGPaymentLink;
        }

        if (!!manualPaymentOptionsId) {
          params["manualPaymentOptionsId"] = manualPaymentOptionsId;
        }

        res = await API.graphql(
          graphqlOperation(adminUpdateManualPaymentOption, params)
        );

        status = res.data.adminUpdateManualPaymentOption.status;
        message = res.data.adminUpdateManualPaymentOption.message;
        if (isSetting) {
          //do nothing for now
        } else {
          history.push("/kds/settings/store-type");
        }
      } else if (paymentMethod === "bank") {
        params = {
          manualPayment: {
            accountNumber: bankTransferData.accountNumber,
            bankAccountName: bankTransferData.bankAccountName,
            isBankTransfer: true,
            isDisabled: false,
            manualPaymentMethodName: bankTransferData.manualPaymentMethodName,
            paymentInstructions: paymentInstructions,
            qrCode: QrImageParams ? QrImageParams : "",
          },
          paymentGateway: {
            companyName: "",
            isDisabled: true,
            serviceId: "",
            servicePassword: "",
          },
        };

        res = await API.graphql(
          graphqlOperation(adminUpdateMerchantPaymentConfig, params)
        );
        status = res.data.adminUpdateMerchantPaymentConfig.status;
        message = res.data.adminUpdateMerchantPaymentConfig.message;
      } else {
        params = {
          manualPayment: {
            accountNumber: bankTransferData.accountNumber,
            bankAccountName: bankTransferData.bankAccountName,
            isBankTransfer: true,
            isDisabled: false,
            manualPaymentMethodName: bankTransferData.manualPaymentMethodName,
            paymentInstructions: bankTransferData.paymentInstructions,
            qrCode: bankTransferData.qrCode,
          },
          paymentGateway: {
            companyName: paymentGatewayData.companyName,
            isDisabled: paymentGatewayData.isDisabled,
            serviceId: paymentGatewayData.serviceId,
            servicePassword: paymentGatewayData.servicePassword,
          },
        };

        res = await API.graphql(
          graphqlOperation(adminUpdateMerchantPaymentConfig, params)
        );
        status = res.data.adminUpdateMerchantPaymentConfig.status;
        message = res.data.adminUpdateMerchantPaymentConfig.message;
      }

      setIsApiProcessing(false);
      setSnackbar({
        snackbarMessage:
          status === "true"
            ? translate("payment-method-page.success-message")
            : message,
        snackbarOpen: true,
        snackbarSeverity: status === "true" ? "success" : "error",
      });
    } catch (error) {
      setIsApiProcessing(false);
      setSnackbar({
        snackbarMessage: SHARED_ERROR_MESSAGE.exceptionError,
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
    }
  };

  const updatePaymentMethod = async () => {
    if (
      (paymentMethod === "tng" &&
        !QRImage?.length &&
        (validator.isEmptyString(TNGPaymentLink) ||
          !validator.isUrl(TNGPaymentLink))) ||
      !canSaveEmpty ||
      (paymentMethod === "duitnow" && !QRImage?.length) ||
      (paymentMethod === "bank" &&
        (!bankTransferData.accountNumber ||
          !bankTransferData.bankAccountName ||
          !bankTransferData.manualPaymentMethodName))
    ) {
      setSnackbar({
        snackbarMessage: getPaymentError(),
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
      return;
    }

    setIsApiProcessing(true);
    let QrImageParams = "";
    const QrImageUrl = `${merchantInfoContext.merchantId}/kds/manual-payment/qr`;

    if (QRImage.length > 0) {
      if (typeof QRImage[0] === "object") {
        QrImageParams = `${QrImageUrl}/${uuid()}.jpg`;
      } else {
        QrImageParams = QRImage[0];
      }
    }

    if (typeof QRImage[0] === "object" && QRImage && QRImage.length > 0) {
      await Storage.put(`${QrImageParams}`, QRImage[0]);
    }

    try {
      let params = {};
      let res = null;
      let status = "false";
      let message = "";

      params = {
        manualPayment: {
          accountNumber: bankTransferData.accountNumber,
          bankAccountName: bankTransferData.bankAccountName,
          isBankTransfer: true,
          isDisabled: false,
          manualPaymentMethodName: bankTransferData.manualPaymentMethodName,
          paymentInstructions: bankTransferData.paymentInstructions,
          qrCode: bankTransferData.qrCode,
        },
        paymentGateway: {
          companyName: paymentGatewayData.companyName,
          isDisabled: paymentGatewayData.isDisabled,
          serviceId: paymentGatewayData.serviceId,
          servicePassword: paymentGatewayData.servicePassword,
        },
      };

      res = await API.graphql(
        graphqlOperation(adminUpdateMerchantPaymentConfig, params)
      );
      status = res.data.adminUpdateMerchantPaymentConfig.status;
      message = res.data.adminUpdateMerchantPaymentConfig.message;

      setIsApiProcessing(false);
      setSnackbar({
        snackbarMessage:
          status === "true"
            ? translate("payment-method-page.success-message")
            : message,
        snackbarOpen: true,
        snackbarSeverity: status === "true" ? "success" : "error",
      });
    } catch (error) {
      setIsApiProcessing(false);
      setSnackbar({
        snackbarMessage: SHARED_ERROR_MESSAGE.exceptionError,
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
    }
  };

  const handleUpdateEGHLPayment = async () => {
    if (
      (paymentMethod === "tng" &&
        !QRImage?.length &&
        (validator.isEmptyString(TNGPaymentLink) ||
          !validator.isUrl(TNGPaymentLink))) ||
      !canSaveEmpty ||
      (paymentMethod === "duitnow" && !QRImage?.length) ||
      (paymentMethod === "bank" &&
        (!bankTransferData.accountNumber ||
          !bankTransferData.bankAccountName ||
          !bankTransferData.manualPaymentMethodName))
    ) {
      setSnackbar({
        snackbarMessage: getPaymentError(),
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
      return;
    }

    setIsApiProcessing(true);
    let QrImageParams = "";
    const QrImageUrl = `${merchantInfoContext.merchantId}/kds/manual-payment/qr`;

    if (QRImage.length > 0) {
      if (typeof QRImage[0] === "object") {
        QrImageParams = `${QrImageUrl}/${uuid()}.jpg`;
      } else {
        QrImageParams = QRImage[0];
      }
    }

    if (typeof QRImage[0] === "object" && QRImage && QRImage.length > 0) {
      await Storage.put(`${QrImageParams}`, QRImage[0]);
    }

    try {
      let params = {};
      let res = null;
      let status = "false";
      let message = "";

      params = {
        manualPayment: {
          accountNumber: bankTransferData.accountNumber,
          bankAccountName: bankTransferData.bankAccountName,
          isBankTransfer: true,
          isDisabled: false,
          manualPaymentMethodName: bankTransferData.manualPaymentMethodName,
          paymentInstructions: bankTransferData.paymentInstructions,
          qrCode: bankTransferData.qrCode,
        },
        paymentGateway: {
          companyName: paymentGatewayData.companyName,
          isDisabled: false,
          serviceId: paymentGatewayData.serviceId,
          servicePassword: paymentGatewayData.servicePassword,
        },
      };

      res = await API.graphql(
        graphqlOperation(adminUpdateMerchantPaymentConfig, params)
      );
      status = res.data.adminUpdateMerchantPaymentConfig.status;
      message = res.data.adminUpdateMerchantPaymentConfig.message;

      setIsApiProcessing(false);
      setSnackbar({
        snackbarMessage:
          status === "true"
            ? translate("payment-method-page.success-message")
            : message,
        snackbarOpen: true,
        snackbarSeverity: status === "true" ? "success" : "error",
      });
    } catch (error) {
      setIsApiProcessing(false);
      setSnackbar({
        snackbarMessage: SHARED_ERROR_MESSAGE.exceptionError,
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
    }
  };

    const getPaymentError = () => {
    if (
      paymentMethod === "tng" &&
      !QRImage?.length &&
      validator.isEmptyString(TNGPaymentLink)
    ) {
      return translate("payment-method-page.qr-link-required-error");
    } else if (paymentMethod === "tng" && !validator.isUrl(TNGPaymentLink)) {
      return translate("payment-method-page.tng-payment-link-invalid-error");
    } else if (paymentMethod === "duitnow" && !QRImage?.length) {
      return translate("payment-method-page.qr-required-error");
    } else if (!paymentInstructions) {
      return translate("payment-method-page.instruction-error");
    } else if (paymentMethod === "bank" && !bankTransferData.accountNumber) {
      return translate("payment-method-page.bank-account-number-error");
    } else if (paymentMethod === "bank" && !bankTransferData.bankAccountName) {
      return translate("payment-method-page.bank-account-name-error");
    } else if (
      paymentMethod === "bank" &&
      !bankTransferData.manualPaymentMethodName
    ) {
      return translate("payment-method-page.bank-name-error");
    } else {
      return translate("payment-method-page.default-error");
    }
  };

  // manualPaymentOptionsId to pass in when using update API (payment method already exists in db)
  const [manualPaymentOptionsId, setManualPaymentOptionsId] = useState();

  const fetchPaymentGateway = async () => {
    setIsLoading(true);
    try {
      let res = await API.graphql(
        graphqlOperation(adminGetMerchantPaymentConfig, {})
      );
      setPaymentGatewayData({
        ...paymentGatewayData,
        serviceId:
          res.data.adminGetMerchantPaymentConfig.paymentGateway.serviceId,
        servicePassword:
          res.data.adminGetMerchantPaymentConfig.paymentGateway.servicePassword,
        companyName:
          res.data.adminGetMerchantPaymentConfig.paymentGateway.companyName,
        isDisabled:
          !res.data.adminGetMerchantPaymentConfig.paymentGateway.isDisabled,
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setSnackbar({
        snackbarMessage: SHARED_ERROR_MESSAGE.exceptionError,
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
    }
  };

  const handleSelectPaymentMethod = async (paymentMethod) => {
    setIsLoading(true);
    try {
      let res = null;

      if (paymentMethod === "tng") {
        res = await API.graphql(
          graphqlOperation(adminListManualPaymentOptions)
        );
        let index =
          res.data.adminListManualPaymentOptions.manualPaymentOptionList.findIndex(
            (o) => o.manualPaymentMethodName === "TnG"
          );
        if (index !== -1) {
          setQRImage(
            res.data.adminListManualPaymentOptions.manualPaymentOptionList[
              index
            ].qrCode
              ? [
                  res.data.adminListManualPaymentOptions
                    .manualPaymentOptionList[index].qrCode,
                ]
              : []
          );
          setPaymentInstructions(
            res.data.adminListManualPaymentOptions.manualPaymentOptionList[
              index
            ].paymentInstructions
          );
          setManualPaymentOptionsId(
            res.data.adminListManualPaymentOptions.manualPaymentOptionList[
              index
            ].manualPaymentOptionsId
          );
          setTNGPaymentLink(
            res.data.adminListManualPaymentOptions.manualPaymentOptionList[
              index
            ].TNGPaymentLink
          );
        }

        setPaymentMethod("tng");
      } else if (paymentMethod === "duitnow") {
        res = await API.graphql(
          graphqlOperation(adminListManualPaymentOptions)
        );
        let index =
          res.data.adminListManualPaymentOptions.manualPaymentOptionList.findIndex(
            (o) => o.manualPaymentMethodName === "DuitNow"
          );
        if (index !== -1) {
          setQRImage(
            res.data.adminListManualPaymentOptions.manualPaymentOptionList[
              index
            ].qrCode
              ? [
                  res.data.adminListManualPaymentOptions
                    .manualPaymentOptionList[index].qrCode,
                ]
              : []
          );
          setPaymentInstructions(
            res.data.adminListManualPaymentOptions.manualPaymentOptionList[
              index
            ].paymentInstructions
          );
          setManualPaymentOptionsId(
            res.data.adminListManualPaymentOptions.manualPaymentOptionList[
              index
            ].manualPaymentOptionsId
          );
        }

        setPaymentMethod("duitnow");
      } else if (paymentMethod === "bank") {
        res = await API.graphql(
          graphqlOperation(adminGetMerchantPaymentConfig)
        );
        if (
          !!res.data.adminGetMerchantPaymentConfig.manualPayment
            .manualPaymentOptionsId
        ) {
          setBankTransferData({
            accountNumber:
              res.data.adminGetMerchantPaymentConfig.manualPayment
                .accountNumber,
            bankAccountName:
              res.data.adminGetMerchantPaymentConfig.manualPayment
                .bankAccountName,
            manualPaymentMethodName:
              res.data.adminGetMerchantPaymentConfig.manualPayment
                .manualPaymentMethodName,
          });
          setQRImage(
            !!res.data.adminGetMerchantPaymentConfig.manualPayment.qrCode
              ? [res.data.adminGetMerchantPaymentConfig.manualPayment.qrCode]
              : []
          );
          setPaymentInstructions(
            res.data.adminGetMerchantPaymentConfig.manualPayment
              .paymentInstructions
          );
        }

        setPaymentMethod("bank");
      } 
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setSnackbar({
        snackbarMessage: SHARED_ERROR_MESSAGE.exceptionError,
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
    }
  };

  const clearPaymentMethod = () => {
    setPaymentMethod("");
    setQRImage([]);
    setPaymentInstructions();
    setManualPaymentOptionsId();
    setTNGPaymentLink();
    setBankTransferData({
      accountNumber: "",
      bankAccountName: "",
      manualPaymentMethodName: "",
    });
  };

  useEffect(() => {
    if (history.location.state && history.location.state.previous) {
      setIsSetting(true);
    } else {
      setPaymentMethod("tng");
      handleSelectPaymentMethod("tng");
    }
  }, [history.location.state]);

  useEffect(() => {
    if (merchantInfoContext?.referralPartner === "TouchNGo") {
      handleSelectPaymentMethod("tng");
      setPaymentMethod("tng");
    }
    fetchPaymentGateway();
  }, [merchantInfoContext]);

  return (
    <>
      <Header />
      <div className="body body-bg body-padding-with-footer">
        {isLoading ? (
          <div className="loading-symbol-wrapper">
            <CircularProgress />
          </div>
        ) : (
          <div className="payment-method-page-wrapper">
            <CustomizedSnackbars
              message={snackbar.snackbarMessage}
              snackbarOpen={snackbar.snackbarOpen}
              snackbarClose={() =>
                setSnackbar({
                  ...snackbar,
                  snackbarOpen: false,
                  snackbarSeverity: snackbar.snackbarSeverity,
                })
              }
              severity={snackbar.snackbarSeverity}
            />
            <div className="header-section">
              <IoArrowBackSharp
                className="backbutton-styling"
                onClick={
                  isSetting
                    ? !paymentMethod ||
                      merchantInfoContext?.referralPartner === "TouchNGo"
                      ? history.goBack
                      : () => clearPaymentMethod()
                    : () =>
                        history.push(
                          "/kds/settings/store-information?merchantId=" +
                            query.get("merchantId")
                        )
                }
              />
              <label
                className="orderhome-orderinfo-extralarge"
                style={{ textTransform: "none" }}
              >
                {!paymentMethod
                  ? translate("payment-method-page.payment-method")
                  : paymentMethod === "tng"
                  ? translate("payment-method-page.upload-tng")
                  : paymentMethod === "duitnow"
                  ? translate("payment-method-page.upload-duitnow")
                  : paymentMethod === "bank"
                  ? translate("payment-method-page.upload-bank")
                  : ""}
              </label>
            </div>
            <div className="body-section">
              {!paymentMethod ? (
                <>
                  <div>
                    <Box
                      sx={{
                        display: "flex",
                        p: 0,
                        mb: 1,
                        bgcolor: "background.paper",
                        borderRadius: 1,
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Typography>
                          {!paymentGatewayData.isDisabled
                            ? translate("payment-method-page.inactive-payment")
                            : translate("payment-method-page.active-payment")}
                        </Typography>
                        <Typography>
                          {!paymentGatewayData.isDisabled
                            ? translate("payment-method-page.receive-via-tng")
                            : translate("payment-method-page.receive-via-eghl")}
                        </Typography>
                      </div>
                      <div>
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={paymentGatewayData.isDisabled}
                          onChange={handleSwitchChange}
                        />
                      </div>
                    </Box>
                  </div>
                  <Divider />
                  {!paymentGatewayData.isDisabled ? (
                    <>
                      <Stack sx={{ marginTop: "8px" }}>
                        <div className="payment-method-button-wrapper">
                          <Typography
                            style={{
                              fontSize: "20px",
                              fontWeight: "bold",
                              padding: "8px",
                              marginBottom: "5px",
                            }}
                          >
                            {translate("payment-method-page.payment-method-2")}
                          </Typography>
                          <div className="payment-method-button-section">
                            <div
                              onClick={() => handleSelectPaymentMethod("tng")}
                              style={{ width: "100%" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  p: 1,
                                  bgcolor: "background.paper",
                                  borderRadius: 1,
                                  justifyContent: "space-between",
                                }}
                              >
                                <div sx={{ flexGrow: 1 }}>
                                  <Typography style={{ fontSize: "16px" }}>
                                    {translate("payment-method-page.tng")}
                                  </Typography>
                                </div>
                                <div>
                                  <IoChevronForwardOutline></IoChevronForwardOutline>
                                </div>
                              </Box>
                            </div>
                          </div>
                          <div
                            className="payment-method-button-section"
                            style={{ borderTop: "1px solid #d3d3d3" }}
                          >
                            <div
                              onClick={() =>
                                handleSelectPaymentMethod("duitnow")
                              }
                              style={{ width: "100%", marginTop: "10px" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  p: 1,
                                  bgcolor: "background.paper",
                                  borderRadius: 1,
                                  justifyContent: "space-between",
                                }}
                              >
                                <div sx={{ flexGrow: 1 }}>
                                  <Typography style={{ fontSize: "16px" }}>
                                    {translate("payment-method-page.duitnow")}
                                  </Typography>
                                </div>
                                <div>
                                  <IoChevronForwardOutline></IoChevronForwardOutline>
                                </div>
                              </Box>
                            </div>
                          </div>
                        </div>
                      </Stack>
                    </>
                  ) : (
                    <>
                      <Stack sx={{ marginTop: "8px" }}>
                        <div style={{ width: "100%", textAlign: "left" }}>
                          <label>
                            {translate("payment-method-page.service-id")}
                          </label>
                          <TextField
                            hiddenLabel
                            id="filled-hidden-label-small"
                            variant="outlined"
                            fullWidth
                            placeholder={translate(
                              "payment-method-page.input-service-id"
                            )}
                            name="serviceId"
                            onChange={(e) => handlePaymentGatewayChange(e)}
                            value={paymentGatewayData.serviceId}
                            size="small"
                          />
                        </div>
                      </Stack>

                      <Stack sx={{ marginTop: "8px" }}>
                        <div style={{ width: "100%", textAlign: "left" }}>
                          <label>
                            {translate("payment-method-page.service-password")}
                          </label>
                          <TextField
                            hiddenLabel
                            id="filled-hidden-label-small"
                            variant="outlined"
                            fullWidth
                            placeholder={translate(
                              "payment-method-page.input-service-password"
                            )}
                            name="servicePassword"
                            onChange={(e) => handlePaymentGatewayChange(e)}
                            value={paymentGatewayData.servicePassword}
                            size="small"
                            type="password"
                          />
                        </div>
                      </Stack>

                      <Stack sx={{ marginTop: "8px" }}>
                        <div style={{ width: "100%", textAlign: "left" }}>
                          <label>
                            {translate("payment-method-page.company-name")}
                          </label>
                          <TextField
                            hiddenLabel
                            id="filled-hidden-label-small"
                            variant="outlined"
                            fullWidth
                            placeholder={translate(
                              "payment-method-page.input-company-name"
                            )}
                            name="companyName"
                            onChange={(e) => handlePaymentGatewayChange(e)}
                            value={paymentGatewayData.companyName}
                            size="small"
                          />
                        </div>
                      </Stack>

                      <div className="footer-section">
                        <CustomLoadingButton
                          className="save-button"
                          onClick={handleUpdateEGHLPayment}
                          loading={isApiProcessing}
                          loadingPosition="start"
                          variant={isApiProcessing ? "outlined" : "contained"}
                        >
                          {isSetting
                            ? translate("settings-home-page.save")
                            : translate("settings-home-page.next")}
                        </CustomLoadingButton>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  {paymentMethod === "bank" && (
                    <>
                      <div className="payment-method-input-section">
                        <label className="payment-method-input-label">
                          {translate("payment-method-page.bank")}
                        </label>
                        <CustomInputBase
                          name="manualPaymentMethodName"
                          borderAttached={true}
                          value={bankTransferData.manualPaymentMethodName}
                          onChange={handleBankTransferDataChange}
                        />
                      </div>
                      <div className="payment-method-input-section">
                        <label className="payment-method-input-label">
                          {translate("payment-method-page.account-number")}
                        </label>
                        <CustomInputBase
                          name="accountNumber"
                          borderAttached={true}
                          value={bankTransferData.accountNumber}
                          onChange={handleBankTransferDataChange}
                        />
                      </div>
                      <div className="payment-method-input-section">
                        <label className="payment-method-input-label">
                          {translate("payment-method-page.account-name")}
                        </label>
                        <CustomInputBase
                          name="bankAccountName"
                          borderAttached={true}
                          value={bankTransferData.bankAccountName}
                          onChange={handleBankTransferDataChange}
                        />
                      </div>
                    </>
                  )}

                  {paymentMethod === "tng" && (
                    <div>
                      <h5 className="payment-method-input-label-title">
                        {translate(
                          "payment-method-page.how-to-setup-tng-title"
                        )}
                      </h5>
                      <h6 className="payment-method-input-label-text">
                        {translate(
                          "payment-method-page.how-to-setup-tng-line1"
                        )}
                      </h6>
                      <h6 className="payment-method-input-label-text">
                        {translate(
                          "payment-method-page.how-to-setup-tng-line2"
                        )}
                      </h6>
                      <h6 className="payment-method-input-label-text">
                        {translate(
                          "payment-method-page.how-to-setup-tng-line3"
                        )}
                      </h6>
                      <h6 className="payment-method-input-label-text">
                        {translate(
                          "payment-method-page.how-to-setup-tng-line4"
                        )}
                      </h6>
                      <div style={{ marginTop: "10px" }}>
                        <ReactPlayer
                          width="100%"
                          controls="true"
                          url="https://youtu.be/QBCeCgYErZo"
                        />
                      </div>
                      <div className="payment-method-input-section">
                        <label className="payment-method-input-label">
                          {translate("payment-method-page.tng-payment-link")}
                        </label>
                        <CustomInputBase
                          name="tng-payment-link"
                          borderAttached={true}
                          value={TNGPaymentLink}
                          onChange={handleInputBaseChange}
                        />
                      </div>
                    </div>
                  )}
                  <div
                    className={`qr-code-upload-section${
                      paymentMethod === "bank" ? " margin-top" : ""
                    }`}
                  >
                    <label className="qr-code-label">
                      {paymentMethod === "tng"
                        ? "Touch 'n Go eWallet"
                        : paymentMethod === "duitnow"
                        ? translate("payment-method-page.duitnow")
                        : paymentMethod === "bank"
                        ? translate("payment-method-page.bank-transfer")
                        : ""}{" "}
                      {translate("payment-method-page.qr-code-label")}
                    </label>
                    <UploadDropZone
                      fileFormat="image"
                      files={QRImage}
                      setSingleFile={onDropImg}
                      setFiles={setQRImage}
                      uploadLimit={1}
                      onDrop={QRImage.length < 1 ? onDropImg : () => {}}
                      disabled={isApiProcessing}
                      setSnackbar={setSnackbar}
                      folderName={""}
                      module={
                        MEDIA_MODULES.paymentMethod.length > 0
                          ? MEDIA_MODULES.paymentMethod[0].name
                          : ""
                      }
                    />
                  </div>
                  <div className="payment-method-input-section">
                    <label className="payment-method-input-label">
                      {translate("payment-method-page.payment-instruction")}
                    </label>
                    <CustomInputBase
                      name="tng-payment-instruction"
                      borderAttached={true}
                      multiline={true}
                      rows={4}
                      placeholder={
                        paymentMethod === "tng"
                          ? "Pay using Touch 'n Go eWallet app and you will receive email notification when we confirm your order."
                          : paymentMethod === "duitnow" &&
                            "Pay using DuitNow QR code and you will receive email notification when we confirm your order."
                      }
                      value={paymentInstructions}
                      onChange={handleInputBaseChange}
                    />
                  </div>
                </>
              )}
            </div>
            {!!paymentMethod && (
              <div className="footer-section">
                <CustomLoadingButton
                  className="save-button"
                  onClick={handleSavePaymentMethod}
                  loading={isApiProcessing}
                  loadingPosition="start"
                  variant={isApiProcessing ? "outlined" : "contained"}
                >
                  {isSetting
                    ? translate("settings-home-page.save")
                    : translate("settings-home-page.next")}
                </CustomLoadingButton>
              </div>
            )}
          </div>
        )}
      </div>
      {isSetting && <Footer page={"setting"}></Footer>}
    </>
  );
}

export default PaymentMethodPage;
