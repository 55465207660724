import { useState, useEffect, useCallback, useContext } from 'react';
import queryString from 'query-string';
import Header from '../../components/header/Header';
import {
  CircularProgress,
  InputAdornment,
  Checkbox,
} from '@mui/material';
import {
  DeleteOutlined,
  EditOutlined,
  AddBox,
  ContentCopy,
} from "@mui/icons-material";
import { IoArrowBackSharp } from 'react-icons/io5';
import { Link, useHistory, useLocation, } from 'react-router-dom';
import produce from "immer";
import { useTranslate } from "react-polyglot";
import { MerchantContext } from "../../context/MerchantContext";
import { v4 as uuid } from "uuid";
import { CustomizedSnackbars } from '../../components/shareable/Shareable';
import { SHARED_ERROR_MESSAGE } from "../../constants";
import "./ProductDetail.scss";
import { CustomInputBase } from "../../components/custom-input-base/CustomInputBase";
import { SimplerUploadDropZone } from "../../components/upload-dropzone/SimplerUploadDropZone";
import { CustomIconButton, CustomButton, CustomLoadingButton } from "../../components/buttons/Buttons";
import { ProductModifierWizard } from "./ProductModifierWizard";
import { validateProductDetailSchema } from "../../utils/validation-schema/validateProductDetailSchema";
import { CategoriesWithAutoComplete } from "./CategoriesWithAutoComplete";
import { ConfirmDeleteDialog } from "../../components/confirm-delete-dialog/ConfirmDeleteDialog";
import { taxCalculator, inputCurrency, onSaveValidation, redirectToPath } from "../../utils/shareable-utils";

// AWS apis
import { API, graphqlOperation, Storage } from "aws-amplify";
import {
  adminGetProduct,
  searchProductCollections,
  adminListStoreProductsBySalesChannel,
} from "../../graphql/queries";
import {
  adminUpdateProduct,
  adminDeleteProductCollection,
} from "../../graphql/mutations";

function ProductDetail(props) {
  const translate = useTranslate();
  const merchantInfoContext = useContext(MerchantContext);
  const location = useLocation();
  const history = useHistory();

  const [productId, setproductId] = useState(queryString.parse(location.search).productId);
  const [productData, setProductData] = useState({
    title: "",
    deliveryPrice: 0,
    deliveryPriceWithTax: 0,
    isProductTaxable: false,
  });
  const [unchangedProductData, setUnchangedProductData] = useState({});
  const [unchangedProductUOMData, setUnchangedProductUOMData] = useState({});
  const [modifierGroupList, setModifierGroupList] = useState([]);
  const [cover, setCover] = useState([]);
  const displayedGroupNames = {}; // To track which group names have been displayed
  const duplicatedErrorAddOnStorage = {}
  // Check for occurences of repeating titles ans set error for second, third and every next occurence
  modifierGroupList?.forEach((mod, i) => {
    const { modifierGroupName } = mod;

    if (displayedGroupNames[modifierGroupName.trim()]) {
      duplicatedErrorAddOnStorage[i] = "error found";
    } else {
      duplicatedErrorAddOnStorage[i] = "error not found";
      displayedGroupNames[modifierGroupName.trim()] = true;
    }
  });

  const onDropImg = (acceptedFiles) => {
    if (acceptedFiles.length > 1) {
      setSnackbar({
        snackbarMessage: "Please upload an image file (e.g. JPG, JPEG, PNG file types)",
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
      return;
    }
    if (!acceptedFiles[0].type.includes("image")) {
      setSnackbar({
        snackbarMessage: "Please upload one image at a time",
        snackbarOpen: true,
        snackbarSeverity: "warning",
      });
      return;
    }

    let imageFile = acceptedFiles[0];
    let reader = new FileReader();
    reader.onload = (e) => {
      let img = document.createElement("img");
      img.onload = (event) => {
        let MAX_WIDTH = 600;

        let width = img.width;
        let height = img.height;

        if (width > MAX_WIDTH) {
          let aspectRatio = width / height;
          width = MAX_WIDTH;
          height = width / aspectRatio;

          // Dynamically create a canvas element
          let canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          let ctx = canvas.getContext("2d");

          // Actual resizing
          ctx.drawImage(img, 0, 0, width, height);

          // Set resized image to image variable
          canvas.toBlob((blob) => {
            let tmpAcceptedFilesArray = [];
            tmpAcceptedFilesArray.push(
              Object.assign(blob, {
                preview: URL.createObjectURL(blob),
              })
            );
            setCover(tmpAcceptedFilesArray);
          }, imageFile.type);
        } else {
          let tmpAcceptedFilesArray = [];
          tmpAcceptedFilesArray.push(
            Object.assign(imageFile, {
              preview: URL.createObjectURL(imageFile),
            })
          );
          setCover(tmpAcceptedFilesArray);
        }
      }

      img.src = e.target.result;
    };
    reader.readAsDataURL(imageFile);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isApiProcessing, setIsApiProcessing] = useState(false);

  const [snackbar, setSnackbar] = useState({
    snackbarMessage: "",
    snackbarOpen: false,
    snackbarSeverity: "info",
  });

  // handle get existing product detail
  const getProductDetail = useCallback(async () => {
    try {
      let params = {
        productId: productId,
      };
      const res = await API.graphql(graphqlOperation(adminGetProduct, params));
      if (res.data.adminGetProduct.status === "true") {
        let data = res.data.adminGetProduct.product;

        setProductData({
          ...productData,
          title: data.title,
          deliveryPrice: parseFloat(data.deliveryPrice.toFixed(2)),
          deliveryPriceWithTax: parseFloat(data.deliveryPriceWithTax.toFixed(2)),
          isProductTaxable: !!data.deliveryPriceWithTax
        });
        setCover([data.cover]);
        setCategories(data.collectionNames);
        setUnchangedProductData(data);
      }
    } catch (error) {
      setSnackbar({
        snackbarMessage: SHARED_ERROR_MESSAGE.exceptionError,
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
    }
  }, [merchantInfoContext.merchantId, productId]);

  const handleCalculateTax = (deliveryPrice) => {
    let taxValue = parseFloat((Number(deliveryPrice) * (Number(merchantInfoContext.tax) / 100)).toFixed(2))
    let deliveryPriceWithTax = parseFloat(taxCalculator(deliveryPrice, merchantInfoContext.tax))
    let deliveryPricePreTax = parseFloat(taxCalculator(deliveryPrice, merchantInfoContext.tax, "divide"));

    return {
      taxValue: taxValue,
      deliveryPriceWithTax: deliveryPriceWithTax,
      deliveryPricePreTax: deliveryPricePreTax
    }
  }

  const handleChange = (e) => {
    if (e.target.name === "deliveryPrice") {
      setProductData({
        ...productData,
        deliveryPrice: e.target.value,
        taxValue: 0.0,
        deliveryPriceWithTax: 0.0
      })
    } else if (e.target.name === "deliveryPriceWithTax") {
      let calcRes = handleCalculateTax(e.target.value)

      setProductData({
        ...productData,
        deliveryPrice: calcRes.deliveryPricePreTax,
        taxValue: calcRes.taxValue,
        deliveryPriceWithTax: e.target.value,
      })
    } else if (e.target.name === "isProductTaxable") {
      if (e.target.checked === true) {
        let calcRes = handleCalculateTax(productData.deliveryPrice)
        setProductData({
          ...productData,
          isProductTaxable: e.target.checked,
          taxValue: calcRes.taxValue,
          deliveryPriceWithTax: calcRes.deliveryPriceWithTax
        })
      } else {
        setProductData({
          ...productData,
          isProductTaxable: e.target.checked,
          taxValue: 0.0,
          deliveryPriceWithTax: 0.0
        })
      }
    } else if (e.target.value !== " ") {
      setProductData({
        ...productData,
        [e.target.name]: e.target.value
      })
    } else {
      setProductData({
        ...productData,
        [e.target.name]: ""
      })
    }
  }

  // product modifier wizard component usage
  const [showProductModifier, setShowProductModifier] = useState(false);
  const [modifierGroupIdToEdit, setModifierGroupIdToEdit] = useState("");

  const handleEditModifierGroup = (modifierGroupId) => {
    setModifierGroupIdToEdit(modifierGroupId);
    setShowProductModifier(true);
  };

  // add collection component usage

  // the selected collections of the product
  const [categories, setCategories] = useState([]);
  const [txtCategories, setTxtCategories] = useState("");
  const handleOnChange = (e) => {
    setTxtCategories(e.target.value);
  };
  // list of available collections
  const [productCollectionList, setProductCollectionList] = useState([]);
  const [refProductCollectionList, setRefProductCollectionList] = useState([]);
  const [fetchCategoriesIsLoading, setFetchCategoriesIsLoading] = useState(false);

  // product detail page collections state
  const maxRowPerPage = 10000;

  // fetchProductCollection apis
  const fetchProductCollections = async () => {
    setFetchCategoriesIsLoading(true);
    try {
      let params = {
        limit: maxRowPerPage,
        filter: {
          merchantId: {},
        },
      };
      let res = null;
      res = await API.graphql(graphqlOperation(searchProductCollections, params));

      if (res.data.searchProductCollections.items.length > 0) {
        let productCollectionItemsList = produce([], (draft) => {
          res.data.searchProductCollections.items.map((obj, index) =>
            draft.push(obj.name)
          );
        });

        setProductCollectionList(productCollectionItemsList);

        let refProductCollectionItemsList = produce([], (draft) => {
          res.data.searchProductCollections.items.map((obj, index) =>
            draft.push({
              name: obj.name,
              productCollectionId: obj.productCollectionId,
            })
          );
        });

        setRefProductCollectionList(refProductCollectionItemsList);
      }
      setFetchCategoriesIsLoading(false);
    } catch (error) {
      setFetchCategoriesIsLoading(false);
      setSnackbar({
        snackbarMessage: SHARED_ERROR_MESSAGE.exceptionError,
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
    }
  };

  // handle get product modifier groups
  const getProductModifiers = useCallback(async () => {
    try {
      let params = {
        productId: productId,
        productBundleId: "",
      };
      const res = await API.graphql(graphqlOperation(adminListStoreProductsBySalesChannel, params));
      let data = res.data.adminListStoreProductsBySalesChannel;
      let modifierGroupData = [];
      if (!!data.uoms[0].productUOMs[0].modifierGroups && data.uoms[0].productUOMs[0].modifierGroups.length > 0) {
        data.uoms[0].productUOMs[0].modifierGroups.forEach((modifierGroup) => {
          modifierGroupData.push({
            modifier:
              !!modifierGroup.modifier && modifierGroup.modifier.length > 0
                ? modifierGroup.modifier.map((o) => ({
                  availableStatus: o.availableStatus,
                  modifierId: o.modifierId,
                  modifierName: o.modifierName,
                  price: o.price,
                }))
                : [],
            modifierGroupId: modifierGroup.modifierGroupId,
            modifierGroupName: modifierGroup.modifierGroupName,
            modifierGroupType: modifierGroup.modifierGroupType,
            requestRemark: modifierGroup.requestRemark,
            selectionRangeMax: modifierGroup.selectionRangeMax,
            selectionRangeMin: modifierGroup.selectionRangeMin,
            isItemsControlAvailable: modifierGroup?.isItemsControlAvailable,
          });
        });
      }

      setModifierGroupList(modifierGroupData);
      setUnchangedProductUOMData(data);
    } catch (error) {
      setSnackbar({
        snackbarMessage: SHARED_ERROR_MESSAGE.exceptionError,
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
    }
  }, [merchantInfoContext.merchantId, productId]);

  const checkforDuplicateModifierGroupName = () => {
    for (let index = 0; index < modifierGroupList.length; index++) {
      if (duplicatedErrorAddOnStorage[index] === "error found") {
        setSnackbar({
          snackbarMessage: translate("add-product.duplicate-modifier-group-names"),
          snackbarOpen: true,
          snackbarSeverity: "error",
        });
        return true;
      }
    }
    return false;
  };

  const handleUpdateProduct = async () => {
    const check = checkforDuplicateModifierGroupName();
    if (!check) {
      setIsApiProcessing(true);

      var coverParams = "";
      let imageParams = [];

      const coverUrl = `${merchantInfoContext.merchantId}/product/cover`;
      const imageUrl = `${merchantInfoContext.merchantId}/product/image`;

      if (cover.length > 0) {
        if (typeof cover[0] === "object") {
          coverParams = `${coverUrl}/${uuid()}.png`;
          imageParams.push(`${imageUrl}/${uuid()}.png`);
        } else {
          coverParams = cover[0];
          imageParams.push(cover[0]);
        }
      }

      let cleanedProductUOMsList = [];
      let updatedUOMsArray;

      let fullPricing = [];
      updatedUOMsArray = produce(cleanedProductUOMsList, (draft) => {
        let pricingObj = {
          salesChannelName: unchangedProductUOMData.uoms[0].salesChannelName,
          deliveryPrice: productData.deliveryPrice,
          deliveryPriceWithTax: productData.deliveryPriceWithTax,
          deliveryCostPerItem: 0,
          deliveryCompareAtPrice: 0,
          pickupPrice: 0,
          pickupPriceWithTax: 0,
          pickupCompareAtPrice: 0,
          pickupCostPerItem: 0,
        };

        pricingObj["storeId"] = unchangedProductUOMData.uoms[0].productUOMs[0].storeId;
        pricingObj["storeProductId"] = unchangedProductUOMData.uoms[0].productUOMs[0].storeProductId;
        pricingObj["marketPlaceCategories"] = unchangedProductUOMData.uoms[0].productUOMs[0].marketPlaceCategories;
        pricingObj["marketPlaceBrand"] = unchangedProductUOMData.uoms[0].productUOMs[0].marketPlaceBrand;
        pricingObj["marketPlaceAttributes"] = unchangedProductUOMData.uoms[0].productUOMs[0].marketPlaceAttributes;

        fullPricing.push(pricingObj);
        draft.push({
          productUOMId: unchangedProductUOMData.uoms[0].productUOMs[0].productUOMId,
          sku: unchangedProductUOMData.uoms[0].productUOMs[0].sku,
          barcode: unchangedProductUOMData.uoms[0].productUOMs[0].barcode,
          trackQuantity: unchangedProductUOMData.uoms[0].productUOMs[0].trackQuantity,
          collectionNames: categories,
          taggingNames: unchangedProductUOMData.uoms[0].productUOMs[0].taggingNames,
          variantName1: null,
          variantValue1: null,
          variantName2: null,
          variantValue2: null,
          variantName3: null,
          variantValue3: null,
          isVirtualGoods: unchangedProductUOMData.uoms[0].productUOMs[0].isVirtualGoods,
          shippingDimensionHeight: unchangedProductUOMData.uoms[0].productUOMs[0].shippingDimensionHeight,
          shippingDimensionLength: unchangedProductUOMData.uoms[0].productUOMs[0].shippingDimensionLength,
          shippingDimensionWidth: unchangedProductUOMData.uoms[0].productUOMs[0].shippingDimensionWidth,
          shippingWeight: unchangedProductUOMData.uoms[0].productUOMs[0].shippingWeight,
          shippingWeightUnit: unchangedProductUOMData.uoms[0].productUOMs[0].shippingWeightUnit,
          productUOMPricing: fullPricing,
          isProductTaxable: productData.isProductTaxable,
          productCover: coverParams,
          image: imageParams[0],
          modifierGroups: modifierGroupList,
        });
      });

      let params = {
        effectiveStartDateTime: unchangedProductData.effectiveStartDateTime,
        effectiveEndDateTime: unchangedProductData.effectiveEndDateTime,
        promotionStartDateTime: unchangedProductData.promotionStartDateTime,
        promotionEndDateTime: unchangedProductData.promotionEndDateTime,
        // hasMultiVariant - to use for validation checking
        hasVariant: unchangedProductData.hasVariant,
        title: productData.title,
        description: unchangedProductData.description,
        cover: coverParams,
        image: imageParams,
        video: unchangedProductData.video,
        code: unchangedProductData.code,
        barcode: unchangedProductUOMData.uoms[0].productUOMs[0].barcode,
        sku: unchangedProductUOMData.uoms[0].productUOMs[0].sku,
        pickupCompareAtPrice: 0,
        pickupPrice: 0,
        pickupPriceWithTax: 0,
        pickupCostPerItem: 0,
        deliveryCompareAtPrice: 0,
        deliveryPrice: productData.deliveryPrice,
        deliveryCostPerItem: 0,
        deliveryPriceWithTax: productData.deliveryPriceWithTax,
        isDisabled: unchangedProductData.isDisabled,
        seoTitle: unchangedProductData.seoTitle,
        seoDescription: unchangedProductData.seoDescription,
        seoUrl: unchangedProductData.seoUrl,
        productUOMs: updatedUOMsArray,
        sellOnFacebookStore: unchangedProductData.sellOnFacebookStore,
        sellOnPandaMart: unchangedProductData.sellOnPandaMart,
        sellOnFoodPanda: unchangedProductData.sellOnFoodPanda,
        sellOnGrabFood: unchangedProductData.sellOnGrabFood,
        sellOnGrabMart: unchangedProductData.sellOnGrabMart,
        sellOnInstagram: unchangedProductData.sellOnInstagram,
        sellOnLazada: unchangedProductData.sellOnLazada,
        sellOnOfflineStore: unchangedProductData.sellOnOfflineStore,
        sellOnOnlineStore: unchangedProductData.sellOnOnlineStore,
        sellOnShopee: unchangedProductData.sellOnShopee,
        collectionNames: categories,
        taggingNames: unchangedProductData.taggingNames,
        variantName1: unchangedProductData.variantName1,
        variantValues1: unchangedProductData.variantValues1,
        variantName2: unchangedProductData.variantName2,
        variantValues2: unchangedProductData.variantValues2,
        variantName3: unchangedProductData.variantName3,
        variantValues3: unchangedProductData.variantValues3,
        isVirtualGoods: unchangedProductData.isVirtualGoods,
        virtualGoodsExpiredAt: unchangedProductData.virtualGoodsExpiredAt,
        virtualGoodsExpiryDays: unchangedProductData.virtualGoodsExpiryDays,
        productId: productId,
        platform: "warung",
      };

      const validationChecking = await onSaveValidation(params, validateProductDetailSchema);
      if (Boolean(validationChecking)) {
        if (validationChecking === "Product price must be more than 0") {
          setSnackbar({
            snackbarMessage: translate("add-product.price-required-error"),
            snackbarOpen: true,
            snackbarSeverity: "error",
          });
        } else if (validationChecking === "Product price must be a number") {
          setSnackbar({
            snackbarMessage: translate("add-product.price-format-error"),
            snackbarOpen: true,
            snackbarSeverity: "error",
          });
        } else {
          setSnackbar({
            snackbarMessage: validationChecking,
            snackbarOpen: true,
            snackbarSeverity: "error",
          });
        }
        setIsApiProcessing(false);
      } else {
        if (cover && cover.length > 0 && typeof cover[0] === "object") {
          await Storage.put(`${params.cover}`, cover[0], {
            contentType: "image/png", // contentType is optional , but crucial for png
            metadata: { title: productData.title },
          });
          await Storage.put(`${params.image}`, cover[0], {
            contentType: "image/png", // contentType is optional , but crucial for png
            metadata: { title: productData.title },
          });
        }

        try {
          const res = await API.graphql(graphqlOperation(adminUpdateProduct, params));

          setSnackbar({
            snackbarMessage: res.data.adminUpdateProduct.message,
            snackbarOpen: true,
            snackbarSeverity: res.data.adminUpdateProduct.status === "true" ? "success" : "error",
          });

          setIsApiProcessing(false);
        } catch (error) {
          setSnackbar({
            snackbarMessage: SHARED_ERROR_MESSAGE.exceptionError,
            snackbarOpen: true,
            snackbarSeverity: "error",
          });
          setIsApiProcessing(false);
        }
      }
    }
  };

  // confirm delete modifier group dialog usage
  const [openConfirmDeleteModifierGroupDialog, setOpenConfirmDeleteModifierGroupDialog] = useState(false);
  const [modifierGroupIdToDelete, setModifierGroupIdToDelete] = useState("");
  const handleCloseConfirmDeleteModifierGroupDialog = () => {
    setModifierGroupIdToDelete("");
    setOpenConfirmDeleteModifierGroupDialog(false);
  };
  const handleRemoveModifierGroup = (modifierGroupId) => {
    let updatedModifierGroupList = [...modifierGroupList];
    let index = updatedModifierGroupList.findIndex((o) => o.modifierGroupId === modifierGroupId);
    updatedModifierGroupList.splice(index, 1);
    setModifierGroupList(updatedModifierGroupList);
    setOpenConfirmDeleteModifierGroupDialog(false);
  };

  // confirm delete category dialog usage
  const [openConfirmDeleteCategoryDialog, setOpenConfirmDeleteCategoryDialog] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState("");
  const handleCloseConfirmDeleteCategoryDialog = () => {
    setCategoryToDelete("");
    setOpenConfirmDeleteCategoryDialog(false);
  };

  const deleteProductCollection = async (productCollectionId) => {
    try {
      let params = {
        merchantId: merchantInfoContext.merchantId,
        productCollectionIdList: [productCollectionId],
      };

      const res = await API.graphql(graphqlOperation(adminDeleteProductCollection, params));

      setSnackbar({
        snackbarMessage: res.data.adminDeleteProductCollection.message,
        snackbarOpen: true,
        snackbarSeverity: res.data.adminDeleteProductCollection.status === "true" ? "success" : "error",
      });

      return res.data.adminDeleteProductCollection.status === "true" ? true : false;
    } catch (error) {
      setSnackbar({
        snackbarMessage: SHARED_ERROR_MESSAGE.exceptionError,
        snackbarOpen: true,
        snackbarSeverity: "error",
      });
    }
  };

  const handleRemoveCategory = (category) => {
    let productCollectionId = refProductCollectionList.find((o) => o.name === category).productCollectionId;
    // call api to delete product collection
    deleteProductCollection(productCollectionId).then((value) => {
      if (value) {
        setTimeout(() => {
          fetchProductCollections();
          // remove the category to be deleted if it is selected
          let index = categories.findIndex((o) => o === category);
          if (index !== -1) {
            let tmpCategories = [...categories];
            tmpCategories.splice(index, 1);
            setCategories(tmpCategories);
          }
        }, 1500);
      }
      setOpenConfirmDeleteCategoryDialog(false);
    });
  };

  const renderProductDetail = () => {

    return (
      <div className="product-detail-page-wrapper">
        <div className='header-section'>
          <IoArrowBackSharp className='backbutton-styling' onClick={() => history.push("/kds/viewmenu")} />
          <div className="title-row">
            <h1 className="product-detail-page-title">{translate("product-detail-page.product-details")}</h1>
            <CustomButton
              startIcon={<ContentCopy />}
              disableRipple
              onClick={() =>
                redirectToPath(
                  history,
                  "/kds/addproduct",
                  JSON.stringify({
                    title: productData.title,
                    deliveryPrice: productData.deliveryPrice,
                    deliveryPriceWithTax: productData.deliveryPriceWithTax,
                    categories: categories,
                    modifierGroupList: modifierGroupList,
                  })
                )
              }
            >
              {translate("product-detail-page.clone-product")}
            </CustomButton>
          </div>
        </div>
        <div className="product-detail-section">
          <div className="product-image">
            <div className="image-container">
              <SimplerUploadDropZone
                onDrop={onDropImg}
                uploadLimit={1}
                files={cover}
                setFiles={setCover}
                disabled={isApiProcessing}
              />
            </div>
            {cover && cover.length > 0 && (
              <CustomButton
                color='error'
                variant='text'
                startIcon={<DeleteOutlined />}
                disableRipple
                onClick={() => setCover([])}
              >
                {translate("product-detail-page.delete-image")}
              </CustomButton>
            )}
          </div>
          <div className="product-info">
            <div className="text-section">
              <CustomInputBase
                borderAttached={true}
                placeholder='Nasi Lemak'
                name="title"
                onChange={(e) => handleChange(e)}
                value={productData.title}
                disabled={isApiProcessing}
              />
              <CustomInputBase
                borderAttached={true}
                type="number"
                placeholder='0.00'
                step="0.01"
                inputProps={{ pattern: "^\d+(?:\.\d{1,2})?$" }}
                name={productData.isProductTaxable ? "deliveryPriceWithTax" : "deliveryPrice"}
                onChange={(e) => handleChange(e)}
                value={productData.isProductTaxable ? inputCurrency(productData.deliveryPriceWithTax, true) : inputCurrency(productData.deliveryPrice, true)}
                startAdornment={
                  <InputAdornment
                    position="start"
                  >
                    {merchantInfoContext.currency}
                  </InputAdornment>
                }
                disabled={isApiProcessing}
              />
            </div>
            <div className="checkbox-section">
              <Checkbox
                checked={productData.isProductTaxable}
                name="isProductTaxable"
                onChange={handleChange}
              />
              <label>{translate("product-detail-page.is-taxable")}</label>
            </div>
          </div>
        </div>
        <div className="categories-section">
          <div className="categories-header-section">
            <h1 className="product-detail-page-title secondary">{translate("product-detail-page.categories-title")}</h1>
          </div>
          <div className="categories-box">
            <CategoriesWithAutoComplete
              categories={categories}
              setCategories={setCategories}
              txtCategories={txtCategories}
              setTxtCategories={setTxtCategories}
              description={translate("product-detail-page.add-categories-instruction")}
              handleOnChange={handleOnChange}
              setOpenDeleteModal={setOpenConfirmDeleteCategoryDialog}
              setCategoryToDelete={setCategoryToDelete}
              setExistingCategoryList={setProductCollectionList}
              existingCategoryList={productCollectionList}
              fetchCategoriesIsLoading={fetchCategoriesIsLoading}
            />
          </div>
        </div>
        <div className="add-on-section">
          <div className="add-on-header-section">
            <h1 className="product-detail-page-title secondary">{translate("product-detail-page.add-ons")}</h1>
            <CustomButton
              onClick={() => setShowProductModifier(true)}
              startIcon={<AddBox />}
              variant="contained"
              color="primary"
            >
              {translate("product-detail-page.add-modifier")}
            </CustomButton>
          </div>
          {modifierGroupList?.length > 0 ?
            modifierGroupList.map((modifierGroup, i) => (
              <div className="add-on-box" key={i}>
                <div className="add-on-detail">
                  <h1 className='add-on-detail-text'>{modifierGroup.modifierGroupName}</h1>
                  <div className="action-button-section">
                    <CustomIconButton
                      color='primary'
                      onClick={() => handleEditModifierGroup(modifierGroup.modifierGroupId)}
                    >
                      <EditOutlined />
                    </CustomIconButton>
                    <CustomIconButton
                      color='error'
                      onClick={() => {
                        setModifierGroupIdToDelete(modifierGroup.modifierGroupId);
                        setOpenConfirmDeleteModifierGroupDialog(true);
                      }}
                    >
                      <DeleteOutlined />
                    </CustomIconButton>
                  </div>
                </div>
                <h1 className='add-on-box-text'>{modifierGroup.modifier.length} {translate("product-detail-page.add-on-options")}</h1>
                <span
                  style={{
                    color: 'red',
                  }}>
                  {duplicatedErrorAddOnStorage[i] === "error found" && <p>{translate("add-product.this-is-a-duplicate-add-on-title")}</p>}
                </span>
              </div>
            ))
            :
            <div className="no-add-ons-indicator">
              <label>{translate("product-detail-page.no-add-ons")}</label>
            </div>
          }
          <ConfirmDeleteDialog
            openDialog={openConfirmDeleteModifierGroupDialog}
            subject={translate("product-detail-page.add-on-singular")}
            handleCloseDialog={handleCloseConfirmDeleteModifierGroupDialog}
            deleteFunction={() => handleRemoveModifierGroup(modifierGroupIdToDelete)}
          />
          <ConfirmDeleteDialog
            openDialog={openConfirmDeleteCategoryDialog}
            subject={translate("product-detail-page.category-singular")}
            handleCloseDialog={handleCloseConfirmDeleteCategoryDialog}
            deleteFunction={() => handleRemoveCategory(categoryToDelete)}
          />
        </div>
      </div>
    );
  };

  const fetchData = () => {
    setIsLoading(true);
    fetchProductCollections().then(() => {
      Promise.all([getProductDetail(), getProductModifiers()]).then(() => {
        setIsLoading(false);
      });
    });
  };

  useEffect(() => {
    fetchData();
  }, [productId]);

  return (
    <>
      <CustomizedSnackbars
        message={snackbar.snackbarMessage}
        snackbarOpen={snackbar.snackbarOpen}
        snackbarClose={() =>
          setSnackbar({
            ...snackbar,
            snackbarOpen: false,
            snackbarSeverity: snackbar.snackbarSeverity,
          })
        }
        severity={snackbar.snackbarSeverity}
      />
      <Header page={"menu"} />
      {!showProductModifier ? (
        <>

          <div className='new-body-padding-with-footer'>
            {isLoading ? (
              <div className="loading-symbol-wrapper">
                <CircularProgress />
              </div>
            ) : renderProductDetail()}
          </div>
          <div className='orderview-button-bottom'>
            <div className='ordercomplete-button-holder'>
              <div className='orderview-button-grid'>
                <CustomButton
                  className='orderview-reject-button'
                  disabled={isApiProcessing}
                  variant="outlined"
                  onClick={() => history.push("/kds/viewmenu")}
                >
                  {!isApiProcessing ? (
                    translate("product-detail-page.back")
                  ) : (
                    <CircularProgress size={20} color={"inherit"} />
                  )}
                </CustomButton>
                <CustomLoadingButton
                  className='orderview-confirm-button'
                  onClick={() => handleUpdateProduct()}
                  loading={isApiProcessing}
                  variant={isApiProcessing ? "outlined" : "contained"}
                >
                  {translate("product-detail-page.save")}
                </CustomLoadingButton>
              </div>
            </div>
          </div>
        </>
      )
        : (
          <ProductModifierWizard
            modifierGroupList={modifierGroupList}
            setModifierGroupList={setModifierGroupList}
            setShowProductModifier={setShowProductModifier}
            modifierGroupIdToEdit={modifierGroupIdToEdit}
            setModifierGroupIdToEdit={setModifierGroupIdToEdit}
          />
        )}
    </>
  );
}

export default ProductDetail;