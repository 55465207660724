import { API, Auth, graphqlOperation } from "aws-amplify";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useTranslate } from "react-polyglot";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { MerchantContext } from "./context/MerchantContext";
import {
  adminGetUserMerchantList,
  checkMerchantSetupStatus,
  kdsGetMerchantServiceCatalog,
} from "./graphql/queries";
import LoginScreen from "./pages/authentication-module/login-module/LoginScreen";
import PageNotFound from "./pages/error-module/PageNotFound.js";
import OrderHistoryPage from "./pages/history-module/OrderHistoryPage";
import ExpiredPrompt from "./pages/onboarding-module/ExpiredPrompt.js";
import FailedPayment from "./pages/onboarding-module/FailedPayment.js";
import LinkQR from "./pages/onboarding-module/LinkQR.js";
import PackageOffer from "./pages/onboarding-module/PackageOffer.js";
import Professional from "./pages/onboarding-module/ProfessionalAcknowledgement.js";
import OnboardingRegister from "./pages/onboarding-module/RegisterOnboard.js";
import SelectMerchant from "./pages/onboarding-module/SelectMerchant.js";
import SuccessPayment from "./pages/onboarding-module/SuccessfullPayment.js";
import CompleteOrder from "./pages/order-module/CompleteOrder";
import OrderHomepage from "./pages/order-module/OrderHomepage";
import ViewOrder from "./pages/order-module/ViewOrder";
import AddProduct from "./pages/product-module/AddProduct";
import ProductDetail from "./pages/product-module/ProductDetail";
import ViewMenu from "./pages/product-module/ViewMenu";
import CreditHistoryPage from "./pages/setting-module/detail/CreditHistoryPage";
import CurrentStoreStatus from "./pages/setting-module/detail/CurrentStoreStatus";
import FinishSetup from "./pages/setting-module/detail/FinishSetup.js";
import PaymentMethodPage from "./pages/setting-module/detail/PaymentMethodPage";
import SelectLanguage from "./pages/setting-module/detail/SelectLanguage";
import StoreInformationPage from "./pages/setting-module/detail/StoreInformationPage";
import StoreOperatingHours from "./pages/setting-module/detail/StoreOperatingHours";
import StoreTypePage from "./pages/setting-module/detail/StoreTypePage";
import SubscriptionHistoryPage from "./pages/setting-module/detail/SubscriptionHistoryPage.js";
import TopUpPage from "./pages/setting-module/detail/TopUpPage";
import TopUpFailed from "./pages/setting-module/detail/topupresult/TopUpFailed";
import TopUpSuccess from "./pages/setting-module/detail/topupresult/TopUpSuccess";
import UpdateBusinessDetails from "./pages/setting-module/detail/UpdateBusinessDetails";
import Faqs from "./pages/setting-module/Faqs";
import SettingHomePage from "./pages/setting-module/SettingHomePage";
import { appState } from "./states/appState";
import Authentication from "./utils/auth";

/* custom hook */
import { useQuery } from "./hooks";
import { useLocalStorage } from "./hooks/useLocalStorage";

export const Routes = [
  {
    path: "/kds/orders",
    exact: true,
    main: () => <OrderHomepage />,
  },
  {
    path: "/kds/vieworder",
    exact: true,
    main: () => <ViewOrder />,
  },
  {
    path: "/kds/ordercompleted",
    exact: true,
    main: () => <CompleteOrder />,
  },
  {
    path: "/kds/viewmenu",
    exact: true,
    main: () => <ViewMenu />,
  },
  {
    path: "/kds/viewproductdetail",
    exact: true,
    main: () => <ProductDetail />,
  },
  {
    path: "/kds/addproduct",
    exact: true,
    main: () => <AddProduct />,
  },
  {
    path: "/kds/settings",
    exact: true,
    main: () => <SettingHomePage />,
  },
  {
    path: "/kds/settings/selectlanguage",
    exact: true,
    main: () => <SelectLanguage />,
  },
  {
    path: "/kds/settings/credit-history",
    exact: true,
    main: () => <CreditHistoryPage />,
  },
  {
    path: "/kds/settings/topup",
    exact: true,
    main: () => <TopUpPage />,
  },
  {
    path: "/kds/settings/credit-history/success",
    exact: true,
    main: () => <TopUpSuccess pageType={"topup"} />,
  },
  {
    path: "/kds/settings/credit-history/fail",
    exact: true,
    main: () => <TopUpFailed pageType={"topup"} />,
  },
  {
    path: "/kds/settings/subscription/success",
    exact: true,
    main: () => <TopUpSuccess pageType={"subscription"} />,
  },
  {
    path: "/kds/settings/subscription/fail",
    exact: true,
    main: () => <TopUpFailed pageType={"subscription"} />,
  },
  {
    path: "/kds/settings/storeopening",
    exact: true,
    main: () => <StoreOperatingHours />,
  },
  {
    path: "/kds/settings/currentstorestatus",
    exact: true,
    main: () => <CurrentStoreStatus />,
  },
  {
    path: "/kds/settings/paymentmethod",
    exact: true,
    main: () => <PaymentMethodPage />,
  },
  {
    path: "/kds/history",
    exact: true,
    main: () => <OrderHistoryPage />,
  },
  {
    path: "/kds/settings/store-type",
    exact: true,
    main: () => <StoreTypePage />,
  },
  {
    path: "/kds/register",
    exact: true,
    main: () => <OnboardingRegister />,
  },
  {
    path: "/kds/package-offer",
    exact: true,
    main: () => <PackageOffer />,
  },
  {
    path: "/kds/expired-prompt",
    exact: true,
    main: () => <ExpiredPrompt />,
  },
  {
    path: "/kds/continue-subscription",
    exact: true,
    main: () => <ExpiredPrompt />,
  },
  {
    path: "/kds/select-merchant",
    exact: true,
    main: () => <SelectMerchant />,
  },
  {
    path: "/kds/settings/update-business-detail",
    exact: true,
    main: () => <UpdateBusinessDetails />,
  },
  {
    path: "/kds/link-qr",
    exact: true,
    main: () => <LinkQR />,
  },
  {
    path: "/kds/settings/store-information",
    exact: true,
    main: () => <StoreInformationPage />,
  },
  {
    path: "/kds/professional-acknowledge",
    exact: true,
    main: () => <Professional />,
  },
  {
    path: "/kds/success-payment",
    exact: true,
    main: () => <SuccessPayment />,
  },
  {
    path: "/kds/failed-payment",
    exact: true,
    main: () => <FailedPayment />,
  },
  {
    path: "/kds/settings/finishsetup",
    exact: true,
    main: () => <FinishSetup />,
  },
  {
    path: "/kds/settings/subscription-history",
    exact: true,
    main: () => <SubscriptionHistoryPage />,
  },
  {
    path: "/kds/settings/faqs",
    exact: true,
    main: () => <Faqs />,
  },
];

export default function Path(props) {
  return (
    <Router>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/login" />} />
        <Route exact path="/login" component={AuthenticateRoutes} />
        <Route path="/kds/orders" component={ProtectedRoutes} />
        <Route path="/kds/vieworder" component={ProtectedRoutes} />
        <Route path="/kds/ordercompleted" component={ProtectedRoutes} />
        <Route path="/kds/viewmenu" component={ProtectedRoutes} />
        <Route path="/kds/viewproductdetail" component={ProtectedRoutes} />
        <Route path="/kds/addproduct" component={ProtectedRoutes} />
        <Route path="/kds/settings" component={ProtectedRoutes} />
        <Route
          path="/kds/settings/selectlanguage"
          component={ProtectedRoutes}
        />
        <Route
          path="/kds/settings/credit-history"
          component={ProtectedRoutes}
        />
        <Route
          path="/kds/settings/credit-history/success"
          component={ProtectedRoutes}
        />
        <Route
          path="/kds/settings/credit-history/fail"
          component={ProtectedRoutes}
        />
        <Route
          path="/kds/settings/subscription/success"
          component={ProtectedRoutes}
        />
        <Route
          path="/kds/settings/subscription/fail"
          component={ProtectedRoutes}
        />
        <Route path="/kds/settings/topup" component={ProtectedRoutes} />
        <Route path="/kds/settings/storeopening" component={ProtectedRoutes} />
        <Route path="/kds/settings/paymentmethod" component={ProtectedRoutes} />
        <Route path="/kds/history" component={ProtectedRoutes} />
        <Route path="/kds/settings/store-type" component={ProtectedRoutes} />
        <Route path="/kds/link-qr" component={ProtectedRoutes} />
        <Route
          path="/kds/settings/update-business-detail"
          component={ProtectedRoutes}
        />
        <Route
          path="/kds/settings/store-information"
          component={ProtectedRoutes}
        />
        <Route path="/kds/settings/faqs" component={ProtectedRoutes} />
        <Route path="/kds/register" component={ProtectedRoutes} />
        <Route path="/kds/package-offer" component={ProtectedRoutes} />
        <Route
          path="/kds/professional-acknowledge"
          component={ProtectedRoutes}
        />
        <Route path="/kds/success-payment" component={ProtectedRoutes} />
        <Route path="/kds/failed-payment" component={ProtectedRoutes} />
        <Route path="/kds/select-merchant" component={ProtectedRoutes} />
        <Route path="/kds/settings/finishsetup" component={ProtectedRoutes} />
        <Route
          path="/kds/settings/subscription-history"
          component={ProtectedRoutes}
        />
        <Route path="/kds/expired-prompt" component={ProtectedRoutes} />
        <Route path="/kds/continue-subscription" component={ProtectedRoutes} />
        <Route path="*" component={PageNotFound} />
        <Route render={() => <Redirect to="/login" />} />
        {/* <Route path="/*" element={<Navigate to="/kds/orders"/>}/> */}
      </Switch>
    </Router>
  );
}

function ProtectedRoutes(props) {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [merchantId, setMerchantId] = useState(null);
  const [merchantDomain, setMerchantDomain] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [tax, setTax] = useState(null);
  const [merchantName, setMerchantName] = useState(null);
  const [membershipTierActivated, setMembershipTierActivated] = useState(null);
  const [storeSetupStatus, setStoreSetupStatus] = useState(null);
  const [collectKYCInfo, setCollectKYCInfo] = useState(null);
  const [packageAddon, setPackageAddOn] = useState(null);
  const [packageSubscription, setPackageSubscription] = useState(null);
  const [isFreeTrial, setIsFreeTrial] = useState(false);
  const [subscriptionExpiryDate, setSubscriptionExpiryDate] = useState(false);
  const [role, setRole] = useState(null);
  const [storeId, setStoreId] = useState(null);
  const [referralPartner, setReferralPartner] = useState(null);
  const [mobileNo, setMobileNo] = useState(null);
  const [expiryDuration, setExpiryDuration] = useState(null);
  const [storeType, setStoreType] = useState(null);
  const fromMobileApp =
    localStorage.getItem("fromMobileApp") &&
    localStorage.getItem("fromMobileApp") !== "IS_MOBILE_APP";
  const merchantInfoProvider = useMemo(
    () => ({
      merchantId,
      setMerchantId,
      merchantDomain,
      currency,
      tax,
      setTax,
      merchantName,
      membershipTierActivated,
      storeSetupStatus,
      collectKYCInfo,
      packageAddon,
      packageSubscription,
      role,
      storeId,
      setStoreId,
      referralPartner,
      isFreeTrial,
      subscriptionExpiryDate,
      mobileNo,
      expiryDuration,
      storeType,
    }),
    [
      merchantId,
      setMerchantId,
      merchantDomain,
      currency,
      setTax,
      tax,
      merchantName,
      membershipTierActivated,
      storeSetupStatus,
      collectKYCInfo,
      packageAddon,
      packageSubscription,
      role,
      storeId,
      setStoreId,
      referralPartner,
      isFreeTrial,
      subscriptionExpiryDate,
      mobileNo,
      expiryDuration,
      storeType,
    ]
  );

  useEffect(() => {
    async function checkCredentials() {
      await Auth.currentCredentials();
      await Auth.currentSession();
    }
    checkCredentials();
  }, [props]);

  useEffect(() => {
    async function ValidateUserSession() {
      try {
        await Auth.currentSession();
        let user = await Auth.currentUserPoolUser();
        setMobileNo(user.attributes.phone_number);
        let userCognitoGroups =
          user.signInUserSession.accessToken.payload["cognito:groups"];
        if (userCognitoGroups && userCognitoGroups[0] === "AxrailAdmin") {
          setRole("AxrailAdmin");
        }
        let preferredMFA = await Auth.getPreferredMFA(user);

        if (preferredMFA === "NOMFA") {
          let getMerchantInfo = await API.graphql(
            graphqlOperation(adminGetUserMerchantList)
          );

          if (getMerchantInfo.data.adminGetUserMerchantList.items) {
            let merchantInfo =
              getMerchantInfo.data.adminGetUserMerchantList.items;
            setMerchantId(merchantInfo[0]["merchantId"]);
            setMerchantDomain(merchantInfo[0]["domain"]);
            setCurrency(merchantInfo[0]["currency"]);
            setTax(merchantInfo[0]["tax"]);
            setMerchantName(merchantInfo[0]["name"]);
            setMembershipTierActivated(
              merchantInfo[0]["membershipTierActivated"]
            );
            setStoreId(merchantInfo[0]["storeId"]);
            setReferralPartner(merchantInfo[0]["referralPartner"]);
            setIsFreeTrial(merchantInfo[0]["isFreeTrial"]);
            setSubscriptionExpiryDate(
              merchantInfo[0]["subscriptionExpiryDate"]
            );
            setStoreType(merchantInfo[0]["storeType"]);
            let language = merchantInfo[0]["preferredLanguage"];
            if (language !== appState.state.locale) {
              localStorage.setItem("locale", language);
            }
          }
          var x = new moment(subscriptionExpiryDate);
          var y = new moment(Date.now());
          setExpiryDuration(x.diff(y, "days"));
          let resPackage = await API.graphql(
            graphqlOperation(kdsGetMerchantServiceCatalog)
          );
          if (resPackage.data.kdsGetMerchantServiceCatalog) {
            setPackageAddOn(
              resPackage.data.kdsGetMerchantServiceCatalog.addOnPackageList
            );
            setPackageSubscription(
              resPackage.data.kdsGetMerchantServiceCatalog
                .subscriptionPackageList
            );
          }
          let resStatus = await API.graphql(
            graphqlOperation(checkMerchantSetupStatus)
          );
          if (resStatus.data.checkMerchantSetupStatus) {
            setStoreSetupStatus(
              resStatus.data.checkMerchantSetupStatus.storeSetupStatus
            );
            setCollectKYCInfo(
              resStatus.data.checkMerchantSetupStatus.collectKYCInfo
            );
          }
          setIsAuthenticated(true);
        } else {
          await Auth.signOut();
          setIsAuthenticated(false);
          localStorage.clear();
          window.location = "/";
        }
        // setAlertMsgControl("show");
      } catch (err) {
        setIsAuthenticated(false);
      }
    }

    ValidateUserSession();
    setIsAuthenticated(isAuthenticated);
  }, []);

  return isAuthenticated != null ? (
    isAuthenticated === false ? (
      <Redirect to="/" />
    ) : (
      <>
        <MerchantContext.Provider value={merchantInfoProvider}>
          <Switch>
            {Routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                children={(props) => <route.main {...props} />}
              />
            ))}
            {storeType !== "multiOffline" &&
              storeType !== "whatsAppCrm" &&
              (fromMobileApp ? (
                isFreeTrial ? (
                  <Redirect to="/kds/expired-prompt" />
                ) : (
                  <Redirect to="/kds/settings" />
                )
              ) : isFreeTrial ? (
                <Redirect to="/kds/expired-prompt" />
              ) : (
                <Redirect to="/kds/orders" />
              ))}
          </Switch>
        </MerchantContext.Provider>
      </>
    )
  ) : null;
}

function AuthenticateRoutes() {
  const translate = useTranslate();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [alertMsgControl, setAlertMsgControl] = useLocalStorage(
    "alertMsgControl",
    "show"
  );
  const [merchantId, setMerchantId] = useState(null);
  const [merchantDomain, setMerchantDomain] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [tax, setTax] = useState(null);
  const [merchantName, setMerchantName] = useState(null);
  const [membershipTierActivated, setMembershipTierActivated] = useState(null);
  const [storeSetupStatus, setStoreSetupStatus] = useState(null);
  const [collectKYCInfo, setCollectKYCInfo] = useState(null);
  const [packageAddon, setPackageAddOn] = useState(null);
  const [role, setRole] = useState(null);
  const [storeId, setStoreId] = useState(null);
  const [referralPartner, setReferralPartner] = useState(null);
  const [isFreeTrial, setIsFreeTrial] = useState(false);
  const [mobileNo, setMobileNo] = useState(null);
  const [subscriptionExpiryDate, setSubscriptionExpiryDate] = useState(false);
  const [expiryDuration, setExpiryDuration] = useState(null);
  const [storeType, setStoreType] = useState(null);
  const merchantInfoProvider = useMemo(
    () => ({
      merchantId,
      setMerchantId,
      merchantDomain,
      currency,
      tax,
      setTax,
      merchantName,
      membershipTierActivated,
      storeSetupStatus,
      collectKYCInfo,
      packageAddon,
      role,
      storeId,
      setStoreId,
      referralPartner,
      isFreeTrial,
      subscriptionExpiryDate,
      mobileNo,
      expiryDuration,
    }),
    [
      merchantId,
      setMerchantId,
      merchantDomain,
      currency,
      setTax,
      tax,
      merchantName,
      membershipTierActivated,
      storeSetupStatus,
      collectKYCInfo,
      packageAddon,
      role,
      storeId,
      setStoreId,
      referralPartner,
      isFreeTrial,
      subscriptionExpiryDate,
      mobileNo,
    ]
  );

  let query = useQuery();

  useEffect(() => {
    async function ValidateUserSession() {
      try {
        await Auth.currentSession();
        let user = await Auth.currentUserPoolUser();
        setMobileNo(user.attributes.phone_number);
        let userCognitoGroups =
          user.signInUserSession.accessToken.payload["cognito:groups"];
        if (userCognitoGroups && userCognitoGroups[0] === "AxrailAdmin") {
          setRole("AxrailAdmin");
        }
        let preferredMFA = await Auth.getPreferredMFA(user);
        if (preferredMFA === "NOMFA") {
          let getMerchantInfo = await API.graphql(
            graphqlOperation(adminGetUserMerchantList)
          );
          if (getMerchantInfo.data.adminGetUserMerchantList.items) {
            let merchantInfo =
              getMerchantInfo.data.adminGetUserMerchantList.items;
            setMerchantId(merchantInfo[0]["merchantId"]);
            setMerchantDomain(merchantInfo[0]["domain"]);
            setCurrency(merchantInfo[0]["currency"]);
            setTax(merchantInfo[0]["tax"]);
            setMerchantName(merchantInfo[0]["name"]);
            setMembershipTierActivated(
              merchantInfo[0]["membershipTierActivated"]
            );

            setStoreId(merchantInfo[0]["storeId"]);
            setReferralPartner(merchantInfo[0]["referralPartner"]);
            setIsFreeTrial(merchantInfo[0]["isFreeTrial"]);
            setSubscriptionExpiryDate(
              merchantInfo[0]["subscriptionExpiryDate"]
            );
            setStoreType(merchantInfo[0]["storeType"]);
            let language = merchantInfo[0]["preferredLanguage"];
            if (language !== appState.state.locale) {
              localStorage.setItem("locale", language);
              window.location.reload();
            }
          }
          let resStatus = await API.graphql(
            graphqlOperation(checkMerchantSetupStatus)
          );
          if (resStatus.data.checkMerchantSetupStatus) {
            setStoreSetupStatus(
              resStatus.data.checkMerchantSetupStatus.storeSetupStatus
            );
            setCollectKYCInfo(
              resStatus.data.checkMerchantSetupStatus.collectKYCInfo
            );
          }
          if (!collectKYCInfo === "completed") {
            let resPackage = await API.graphql(
              graphqlOperation(kdsGetMerchantServiceCatalog)
            );
            if (resPackage.data.kdsGetMerchantServiceCatalog) {
              setPackageAddOn(
                resPackage.data.kdsGetMerchantServiceCatalog.addOnPackageList
              );
            }
          }
          setIsAuthenticated(true);
        } else {
          await Auth.signOut();
          setIsAuthenticated(false);
          localStorage.clear();
          window.location.href = "/";
        }
      } catch (err) {
        console.log("err", err);
        setIsAuthenticated(Authentication.getAuth());
      }
    }
    ValidateUserSession();
  }, []);

  const checkStoreSetupStatus = () => {
    const fromMobileApp =
      localStorage.getItem("fromMobileApp") &&
      localStorage.getItem("fromMobileApp") !== "IS_MOBILE_APP";
    switch (true) {
      case storeSetupStatus === "pendingMerchantSetup":
        return (
          <MerchantContext.Provider value={merchantInfoProvider}>
            <Redirect
              to={
                "/kds/settings/selectlanguage?merchantId=" +
                query.get("merchantId")
              }
            />
          </MerchantContext.Provider>
        );
      case storeSetupStatus === "pendingMerchantAcknowledge":
        return (
          <MerchantContext.Provider value={merchantInfoProvider}>
            <Redirect to="/kds/professional-acknowledge" />
          </MerchantContext.Provider>
        );
      case storeSetupStatus === "pendingAxrailSetup":
        return (
          <MerchantContext.Provider value={merchantInfoProvider}>
            <Redirect to="/kds/settings" />
          </MerchantContext.Provider>
        );
      default:
        return (
          <MerchantContext.Provider value={merchantInfoProvider}>
            {fromMobileApp ? (
              <Redirect to="/kds/settings" />
            ) : (
              <Redirect to="/kds/orders" />
            )}
          </MerchantContext.Provider>
        );
    }
  };

  let allowAccess = () => {
    const fromMobileApp =
      localStorage.getItem("fromMobileApp") &&
      localStorage.getItem("fromMobileApp") !== "IS_MOBILE_APP";
    return (
      (storeType !== "multiOffline" &&
        storeType !== "whatsAppCrm" &&
        storeType !== "b2b" &&
        storeType !== "singleOnline") ||
      ((storeType === "multiOffline" || storeType === "whatsAppCrm") &&
        fromMobileApp)
    );
  };

  return isAuthenticated != null ? (
    isAuthenticated === true && allowAccess() ? (
      collectKYCInfo === "pending" &&
      storeType !== "multiOffline" &&
      storeType !== "whatsAppCrm" ? (
        <MerchantContext.Provider value={merchantInfoProvider}>
          <Redirect to="/kds/register" />
        </MerchantContext.Provider>
      ) : (
        checkStoreSetupStatus()
      )
    ) : (
      <Switch>
        <Route
          exact
          path="/login"
          render={(props) => {
            return (
              <LoginScreen
                {...props}
                setAlertMsgControl={setAlertMsgControl}
                initialMessage={
                  storeType === "multiOffline" ||
                  storeType === "whatsAppCrm" ||
                  storeType === "b2b" ||
                  storeType === "singleOnline"
                    ? translate("invalid-user")
                    : ""
                }
              />
            );
          }}
        />
      </Switch>
    )
  ) : null;
}
