import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  FormControlLabel,
} from "@mui/material";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { removeKdsToken, kDSDeactivateAccount } from "../../graphql/mutations";
import React, { useContext, useEffect, useState } from "react";
import { GrFormNext } from "react-icons/gr";
import { useTranslate } from "react-polyglot";
import { useHistory } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { MerchantContext } from "../../context/MerchantContext";
import { validateExpiryDate } from "../../validation/validateExpiryDate";
import useCheckMultistoreUser from "./compnents/useCheckMultistoreUser";
import "./SettingHomePage.scss";

function SettingHomePage(props) {
  const history = useHistory();
  const translate = useTranslate();
  const [openLogOutModal, setOpenLogOutModal] = useState(false);
  const [openDeactivateAccountModal, setOpenDeactivateAccountModal] =
    useState(false);
  const [openConfirmDeactivateModal, setOpenConfirmDeactivateModal] =
    useState(false);
  const deviceToken = localStorage.getItem("deviceToken");
  const fromMobileApp =
    localStorage.getItem("fromMobileApp") &&
    localStorage.getItem("fromMobileApp") !== "IS_MOBILE_APP";

  const handleSignout = async () => {
    try {
      await API.graphql(
        graphqlOperation(removeKdsToken, {
          deviceToken: deviceToken,
        })
      );
      await Auth.signOut();
      localStorage.clear();
      window.location.href = "/login";
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeactivateAccount = async () => {
    try {
      await API.graphql(graphqlOperation(kDSDeactivateAccount));
      await Auth.signOut();
      localStorage.clear();
      window.location.href = "/login";
    } catch (e) {
      console.log(e);
    }
  };
  const { isMultistore } = useCheckMultistoreUser();

  const handleOpenLogOutModal = () => {
    setOpenLogOutModal(true);
  };

  const handleCloseLogOutModal = () => {
    setOpenLogOutModal(false);
  };

  const handleOpenDeactivateAccountModal = () => {
    setOpenDeactivateAccountModal(true);
  };

  const handleCloseDeactivateAccountModal = () => {
    setOpenDeactivateAccountModal(false);
  };

  const handleOpenConfirmDeactivateModal = () => {
    setOpenConfirmDeactivateModal(true);
    setOpenDeactivateAccountModal(false);
  };

  const handleCloseConfirmDeactivateModal = () => {
    setOpenConfirmDeactivateModal(false);
  };
  const merchantInfoContext = useContext(MerchantContext);
  let isWhatsappCRMAdmin = merchantInfoContext.storeType === "whatsAppCrm";
  let isWarungStore = merchantInfoContext.storeType === "warung";
  let isSepang = merchantInfoContext.merchantDomain.includes("sepang")

  useEffect(
    () => validateExpiryDate(merchantInfoContext.subscriptionExpiryDate),
    []
  );

  const renderLogOutModal = () => {
    return (
      <div>
        <Dialog open={openLogOutModal} onClose={handleCloseLogOutModal}>
          <DialogTitle
            className="orderhome-orderinfo-large"
            id="alert-dialog-title"
          >
            {translate("settings-home-page.areyousuretologout")}
          </DialogTitle>
          <DialogActions>
            <Button
              className="orderhome-orderinfo-no"
              onClick={handleCloseLogOutModal}
            >
              {translate("settings-home-page.cancel")}
            </Button>
            <Button
              className="orderhome-orderinfo-yes"
              onClick={() => handleSignout()}
              autoFocus
            >
              {translate("settings-home-page.confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const renderDeactivateAccountModal = () => {
    return (
      <div>
        <Dialog
          open={openDeactivateAccountModal}
          onClose={handleCloseDeactivateAccountModal}
          style={{ height: fromMobileApp ? "80vh" : "100vh", overflow: "auto" }}
        >
          <DialogTitle
            className="orderhome-orderinfo-large"
            id="alert-dialog-title"
          >
            {translate("settings-home-page.deactivating-your-account")}
          </DialogTitle>
          <DialogContent>
            <Typography>
              {translate("settings-home-page.permanently-delete")}
            </Typography>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="just-need"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="just-need"
                  control={<Radio />}
                  label={translate("settings-home-page.just-need")}
                />
                <FormControlLabel
                  value="too-busy"
                  control={<Radio />}
                  label={translate("settings-home-page.too-busy")}
                />
                <FormControlLabel
                  value="privacy"
                  control={<Radio />}
                  label={translate("settings-home-page.privacy")}
                />
                <FormControlLabel
                  value="trouble"
                  control={<Radio />}
                  label={translate("settings-home-page.trouble")}
                />
                <FormControlLabel
                  value="something-else"
                  control={<Radio />}
                  label={translate("settings-home-page.something-else")}
                />
                <FormControlLabel
                  value="concerned"
                  control={<Radio />}
                  label={translate("settings-home-page.concerned")}
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              className="orderhome-orderinfo-no"
              onClick={handleCloseDeactivateAccountModal}
            >
              {translate("settings-home-page.cancel")}
            </Button>
            <Button
              className="orderhome-orderinfo-yes"
              onClick={() => handleOpenConfirmDeactivateModal(true)}
              autoFocus
            >
              {translate("settings-home-page.continue")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const renderConfirmDeactivationModal = () => {
    return (
      <div>
        <Dialog
          open={openConfirmDeactivateModal}
          onClose={handleCloseDeactivateAccountModal}
        >
          <DialogTitle
            className="orderhome-orderinfo-large"
            id="alert-dialog-title"
          >
            {translate("settings-home-page.confirm-deactivating-your-account")}
          </DialogTitle>
          <DialogContent>
            <Typography>
              {translate("settings-home-page.confirm-permanent-delete")}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              className="orderhome-orderinfo-no"
              onClick={handleCloseConfirmDeactivateModal}
            >
              {translate("settings-home-page.cancel")}
            </Button>
            <Button
              className="orderhome-orderinfo-yes"
              onClick={() => handleDeactivateAccount()}
              autoFocus
            >
              {translate("settings-home-page.deactivate")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  return (
    <div className="setting-home-page-wrapper">
      {renderLogOutModal()}
      {renderDeactivateAccountModal()}
      {renderConfirmDeactivationModal()}
      <Header />
      <div className="body body-bg new-body-padding-with-footer">
        <h1 className="orderhome-orderinfo-extralarge">
          {translate("settings-home-page.settings")}
        </h1>
        <div className="setting-menu">
          {/*
                            merchantInfoContext.role==="AxrailAdmin" ?
                                null
                            : 
                                <div className="setting-menu-option" onClick={() => history.push("/kds/settings/currentstorestatus")}>
                                    <div>
                                        <p className="setting-main-text">{translate("settings-home-page.current-store-status")}</p>
                                        <p className="setting-desc-text">{translate("settings-home-page.current-store-status-desc")}</p>
                                    </div>
                                    <GrFormNext className='nextbutton-styling'></GrFormNext>
                                </div>
                        */}
          {!isMultistore && !isWhatsappCRMAdmin && !isSepang && (
            <div
              className="setting-menu-option"
              onClick={() =>
                history.push({
                  pathname: "/kds/settings/paymentmethod",
                  state: { previous: history.location.pathname },
                })
              }
            >
              <div>
                <p className="setting-main-text">
                  {translate("settings-home-page.payment-setup")}
                </p>
                <p className="setting-desc-text">
                  {translate("settings-home-page.payment-setup-desc")}
                </p>
              </div>
              <GrFormNext className="nextbutton-styling"></GrFormNext>
            </div>
          )}
          {merchantInfoContext.role !== "AxrailAdmin" &&
            !isMultistore &&
            !isWhatsappCRMAdmin &&
            !isSepang && (
              <div
                className="setting-menu-option"
                onClick={() => history.push("/kds/settings/credit-history")}
              >
                <div>
                  <p className="setting-main-text">
                    {translate("settings-home-page.delivery-credits")}
                  </p>
                  <p className="setting-desc-text">
                    {translate("settings-home-page.delivery-credits-desc")}
                  </p>
                </div>
                <GrFormNext className="nextbutton-styling"></GrFormNext>
              </div>
            )}
          {!isMultistore && !isWhatsappCRMAdmin && (
            <div
              className="setting-menu-option"
              onClick={() =>
                history.push({
                  pathname: "/kds/settings/update-business-detail",
                  state: { previous: history.location.pathname },
                })
              }
            >
              <div>
                <p className="setting-main-text">
                  {translate("settings-home-page.business-setting")}
                </p>
                <p className="setting-desc-text">
                  {translate("settings-home-page.business-setting-desc")}
                </p>
              </div>
              <GrFormNext className="nextbutton-styling"></GrFormNext>
            </div>
          )}
          {!isMultistore && !isWhatsappCRMAdmin && (
            <div
              className="setting-menu-option"
              onClick={() =>
                history.push({
                  pathname: "/kds/settings/store-information",
                  state: { previous: history.location.pathname },
                })
              }
            >
              <div>
                <p className="setting-main-text">
                  {translate("settings-home-page.store-information-setting")}
                </p>
                <p className="setting-desc-text">
                  {translate(
                    "settings-home-page.store-information-setting-desc"
                  )}
                </p>
              </div>
              <GrFormNext className="nextbutton-styling"></GrFormNext>
            </div>
          )}
          {!isMultistore && !isWhatsappCRMAdmin && (
            <div
              className="setting-menu-option"
              onClick={() =>
                history.push({
                  pathname: "/kds/settings/storeopening",
                  state: { previous: history.location.pathname },
                })
              }
            >
              <div>
                <p className="setting-main-text">
                  {translate("settings-home-page.store-setting")}
                </p>
                <p className="setting-desc-text">
                  {translate("settings-home-page.store-setting-desc")}
                </p>
              </div>
              <GrFormNext className="nextbutton-styling"></GrFormNext>
            </div>
          )}

          {!isMultistore && !isWhatsappCRMAdmin && !isSepang && (
            <div
              className="setting-menu-option"
              onClick={() =>
                history.push({
                  pathname: "/kds/settings/faqs",
                  state: { previous: history.location.pathname },
                })
              }
            >
              <div>
                <p className="setting-main-text">
                  {translate("settings-home-page.faq")}
                </p>
                <p className="setting-desc-text">
                  {translate("settings-home-page.faq-desc")}
                </p>
              </div>
              <GrFormNext className="nextbutton-styling"></GrFormNext>
            </div>
          )}
          {!isMultistore && !isWhatsappCRMAdmin && !isSepang && (
            <div
              className="setting-menu-option"
              onClick={() =>
                history.push({
                  pathname: "/kds/settings/store-type",
                  state: { previous: history.location.pathname },
                })
              }
            >
              <div>
                <p className="setting-main-text">
                  {translate("settings-home-page.store-type-setting")}
                </p>
                <p className="setting-desc-text">
                  {translate("settings-home-page.store-type-setting-desc")}
                </p>
              </div>
              <GrFormNext className="nextbutton-styling"></GrFormNext>
            </div>
          )}

          {!isWhatsappCRMAdmin && (
            <div
              className="setting-menu-option"
              onClick={() =>
                history.push({
                  pathname: "/kds/settings/selectlanguage",
                  state: { previous: history.location.pathname },
                })
              }
            >
              <div>
                <p className="setting-main-text">
                  {translate("settings-home-page.languages")}
                </p>
                <p className="setting-desc-text">
                  {translate("settings-home-page.languages-desc")}
                </p>
              </div>
              <GrFormNext className="nextbutton-styling"></GrFormNext>
            </div>
          )}
          {merchantInfoContext.role !== "AxrailAdmin" &&
            !isMultistore &&
            !isWhatsappCRMAdmin &&
            !isSepang && (
              <div
                className="setting-menu-option"
                onClick={() =>
                  history.push({
                    pathname: "/kds/settings/subscription-history",
                    state: { previous: history.location.pathname },
                  })
                }
              >
                <div>
                  <p className="setting-main-text">
                    {translate("settings-home-page.subs-history")}
                  </p>
                  <p className="setting-desc-text">
                    {translate("settings-home-page.subs-history-desc")}
                  </p>
                </div>
                <GrFormNext className="nextbutton-styling"></GrFormNext>
              </div>
            )}

          {merchantInfoContext.role !== "AxrailAdmin" && !isMultistore && !isWarungStore && (
            <div
              className="setting-menu-option"
              onClick={() => handleOpenDeactivateAccountModal()}
            >
              <div>
                <p className="setting-main-text">
                  {translate("settings-home-page.deactivate-account")}
                </p>
                <p className="setting-desc-text">
                  {translate("settings-home-page.deactivate-account-desc")}
                </p>
              </div>
              <GrFormNext className="nextbutton-styling"></GrFormNext>
            </div>
          )}
          {merchantInfoContext.role === "AxrailAdmin" ? (
            <div
              className="setting-menu-option"
              onClick={() => history.push("/kds/settings/finishsetup")}
            >
              <div>
                <p className="setting-main-text">
                  {translate("settings-home-page.finishsetup")}
                </p>
                <p className="setting-desc-text"></p>
              </div>
              <GrFormNext className="nextbutton-styling"></GrFormNext>
            </div>
          ) : null}
          <div
            className="setting-menu-option"
            onClick={() => handleOpenLogOutModal()}
          >
            <div>
              <p className="setting-main-text">
                {translate("settings-home-page.logout")}
              </p>
              <p className="setting-desc-text"></p>
            </div>
            <GrFormNext className="nextbutton-styling"></GrFormNext>
          </div>
        </div>
      </div>
      <Footer page={"setting"} />
    </div>
  );
}

export default SettingHomePage;
