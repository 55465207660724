import * as Yup from "yup";
import { isValidPhoneNumber, isValidStringWithNoSpace } from "../utils/shareable-utils";

export const validateStoreInfo = Yup.object().shape({
  name: Yup.string().test("test-name-validity", "Store name cannot be blank spaces.", (value) => !!value && value.trim() !== "").required("Store name is required.").nullable(),
  smsMobileNo: Yup.string()
    .required("SMS notification phone number is required.")
    .test("test-phone-number-validity", "SMS notification phone number is invalid.", (value) => !!value && isValidPhoneNumber(value))
    .nullable(),
  address: Yup.string().required("Store address is required.").nullable(),
  addressDetail: Yup.string().nullable(),
});
