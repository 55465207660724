import React, { useState, useContext, useEffect } from 'react';
import Header from '../../components/header/Header';
import { useHistory } from 'react-router-dom';
import {
    Checkbox,
    Grid,
    TextField,
    Button,
    IconButton,
    InputAdornment
} from '@mui/material';
import { Cancel, DeleteOutlined } from '@mui/icons-material';
import UploadIcon from '../../assets/icons/uploadIcon.png'
import "./AddProduct.scss"
import { validator } from '../../utils'
import { useDropzone } from "react-dropzone";
import { useTranslate } from "react-polyglot";
import { v4 as uuid } from "uuid";
import produce from "immer";

//Components
import { CustomizedSnackbars } from '../../components/shareable/Shareable';
import { TopLayout, BottomLayout } from '../common/layout/Layout'
import { ProductModifierWizard } from './ProductModifierWizard'
import {
    taxCalculator,
    inputCurrency,
    handleSeoUrl,
    inputPreventDefault,
} from "../../utils/shareable-utils";
import { CategoriesWithAutoComplete } from "./CategoriesWithAutoComplete";
import { SHARED_ERROR_MESSAGE } from "../../constants";
import { ConfirmDeleteDialog } from "../../components/confirm-delete-dialog/ConfirmDeleteDialog";
import { CustomButton, CustomIconButton } from "../../components/buttons/Buttons";
import defaultProductImage from "../../images/default-product-image.svg";

//Context
import { MerchantContext } from "../../context/MerchantContext";

// AWS apis
import { API, graphqlOperation, Storage } from "aws-amplify";
import { adminCreateProduct, adminDeleteProductCollection } from '../../graphql/mutations';
import { searchStores, searchProductCollections, getSubscriptionHistoryList, checkProductName } from '../../graphql/queries';

function AddProduct(props) {
    const history = useHistory();
    const translate = useTranslate();
    const merchantInfoContext = useContext(MerchantContext);
    const merchantId = merchantInfoContext.merchantId
    const [merchantStores, setMerchantStores] = useState([])
    const [storeIdList, setStoreIdList] = useState([])

    const [formProgress, setFormProgress] = useState(1)
    const [productData, setProductData] = useState(({
        title: "",
        deliveryPrice: 0.0,
        deliveryPriceWithTax: 0.0,
        taxValue: 0.0,
        type: "",
        image: null,
        cover: null,
        minproductQty: 0,
        maxproductQty: 0,
        isTaxable: false
    }))
    const [uploadImage, setUploadedImage] = useState([]);
    const [preview, setPreview] = useState("");
    const [filesParams, setFilesParams] = useState([]);
    const supportedFileType = {
        'image/*': ['.jpeg', '.jpg', '.png'],
    };
    const [errorMessage, setErrorMessage] = useState({
        title: "",
        deliveryPrice: "",
        image: "",
        collectionNames: "",
        duplicateAddOns: ""
    })
    const [isCloneProduct, setIsCloneProduct] = useState(false);
    const [isProductCheckLoading, setProductCheckIsLoading] = useState(false);

    const [snackbar, setSnackbar] = useState({
        snackbarMessage: "",
        snackbarOpen: false,
        snackbarSeverity: "info",
    });

    const roundN = (num, n) => {
        return parseFloat(Math.round(num * Math.pow(10, n)) / Math.pow(10, n)).toFixed(n);
    }

    //Modifier
    const [modifierGroupList, setModifierGroupList] = useState([]);
    const [showProductModifier, setShowProductModifier] = useState(false);
    const [modifierGroupIdToEdit, setModifierGroupIdToEdit] = useState("");
    const [redirectToLastPage, setRedirectToLastPage] = useState(false)
    const displayedGroupNames = {}; // To track which group names have been displayed
    const duplicatedErrorAddOnStorage = {}
    // Check for occurences of repeating titles ans set error for second, third and every next occurence
    modifierGroupList?.forEach((mod, i) => {
        const { modifierGroupName } = mod;

        if (displayedGroupNames[modifierGroupName.trim()]) {
            duplicatedErrorAddOnStorage[i] = "error found";
        } else {
            duplicatedErrorAddOnStorage[i] = "error not found";
            displayedGroupNames[modifierGroupName.trim()] = true;
        }
    });

    const validateData = (type, value) => {
        if (type === "title") {
            if (validator.isEmptyString(value)) {
                setErrorMessage({
                    ...errorMessage,
                    title: translate("add-product.title-required-error")
                })
            } else {
                setErrorMessage({
                    ...errorMessage,
                    title: ''
                })
            }
        } else if (type === "deliveryPrice" || type === "deliveryPriceWithTax") {
            let price = inputCurrency(value);
            if (!validator.isPrice(price)) {
                setErrorMessage({
                    ...errorMessage,
                    deliveryPrice: translate("add-product.price-format-error")
                })
            } else {
                setErrorMessage({
                    ...errorMessage,
                    deliveryPrice: ''
                })
            }
        }
    }

    const handleCalculateTax = (deliveryPrice) => {
        let taxValue = parseFloat((Number(deliveryPrice) * (Number(merchantInfoContext.tax) / 100)).toFixed(2))
        let deliveryPriceWithTax = parseFloat(taxCalculator(deliveryPrice, merchantInfoContext.tax))
        let deliveryPricePreTax = parseFloat(taxCalculator(deliveryPrice, merchantInfoContext.tax, "divide"));

        return {
            taxValue: taxValue,
            deliveryPriceWithTax: deliveryPriceWithTax,
            deliveryPricePreTax: deliveryPricePreTax
        }
    }

    const handleChange = (e) => {
        validateData(e.target.name, e.target.value)
        if (e.target.name === "deliveryPrice") {
            setProductData({
                ...productData,
                deliveryPrice: e.target.value,
                taxValue: 0.0,
                deliveryPriceWithTax: 0.0
            })
        } else if (e.target.name === "deliveryPriceWithTax") {
            let calcRes = handleCalculateTax(e.target.value)

            setProductData({
                ...productData,
                deliveryPrice: calcRes.deliveryPricePreTax,
                taxValue: calcRes.taxValue,
                deliveryPriceWithTax: e.target.value,
            })
        } else if (e.target.name === "isTaxable") {
            if (e.target.checked === true) {
                let calcRes = handleCalculateTax(productData.deliveryPrice)
                setProductData({
                    ...productData,
                    isTaxable: e.target.checked,
                    taxValue: calcRes.taxValue,
                    deliveryPriceWithTax: calcRes.deliveryPriceWithTax
                })
            } else {
                setProductData({
                    ...productData,
                    isTaxable: e.target.checked,
                    taxValue: 0.0,
                    deliveryPriceWithTax: 0.0
                })
            }
        } else if (e.target.value !== " ") {
            setProductData({
                ...productData,
                [e.target.name]: e.target.value
            })
        } else {
            setProductData({
                ...productData,
                [e.target.name]: ""
            })
        }
    }

    const handleImagePreview = async (acceptedFiles) => {
        const checkFileType = acceptedFiles.map(
            (item) => item.type.substring(0, 6) !== supportedFileType
        );
        if (checkFileType.includes(true)) {
            const imageUrl = `${merchantId}/product/image`;
            const coverUrl = `${merchantId}/product/cover`;
            if (acceptedFiles.length > 0) {
                let imageFile = acceptedFiles[0];
                let reader = new FileReader();
                reader.onload = (e) => {
                    let img = document.createElement("img");
                    img.onload = (event) => {
                        let MAX_WIDTH = 600;

                        let width = img.width;
                        let height = img.height;

                        if (width > MAX_WIDTH) {
                            let aspectRatio = width / height;
                            width = MAX_WIDTH;
                            height = width / aspectRatio;

                            // Dynamically create a canvas element
                            let canvas = document.createElement("canvas");
                            canvas.width = width;
                            canvas.height = height;
                            let ctx = canvas.getContext("2d");

                            // Actual resizing
                            ctx.drawImage(img, 0, 0, width, height);

                            // Set resized image to image variable
                            canvas.toBlob((blob) => {
                                let tmpAcceptedFilesArray = [];
                                tmpAcceptedFilesArray.push(
                                    Object.assign(blob, {
                                        preview: URL.createObjectURL(blob),
                                    })
                                );
                                setUploadedImage(tmpAcceptedFilesArray);
                                setPreview(URL.createObjectURL(tmpAcceptedFilesArray[0]));
                            }, imageFile.type);
                        } else {
                            setUploadedImage([imageFile])
                            setPreview(URL.createObjectURL(imageFile))
                        }
                    }
                    img.src = e.target.result;
                };
                reader.readAsDataURL(imageFile);

                setProductData({
                    ...productData,
                    image: `${imageUrl}/${uuid()}.png`,
                    cover: `${coverUrl}/${uuid()}.png`
                })
                setErrorMessage({
                    ...errorMessage,
                    image: ""
                })
                let imageParams = []
                imageParams.push(`${coverUrl}/${uuid()}.png`)
                setFilesParams(imageParams)
            }
        } else {
            setSnackbar({
                snackbarMessage: translate("order-listing-screen.invalidfiletype"),
                snackbarOpen: true,
                snackbarSeverity: "error",
            });
        }
    };

    //get merchant store
    const fetchStore = async () => {
        let params = {
            filter: {
                and: [
                    {
                        merchantId: {},
                    },
                    {
                        salesChannelName: { eq: "Online Store" },
                    }
                ],
            },
            sort: {
                field: "name",
                direction: "asc",
            },
            limit: 10000,
        };
        let res = null;
        try {
            res = await API.graphql(graphqlOperation(searchStores, params));
            if (res && res.data.searchStores.items && res.data.searchStores.items.length > 0) {
                let data = res.data.searchStores.items
                let tempIdList = data.map((store) => {
                    return store.storeId
                })
                setMerchantStores(data)
                setStoreIdList(tempIdList)
            }
        } catch (error) {
            console.log(error)
        }

    }

    //Saving in database
    const [isSaving, setIsSaving] = useState(false);
    const checkforDuplicateModifierGroupName = () => {
        for (let index = 0; index < modifierGroupList.length; index++) {
            if (duplicatedErrorAddOnStorage[index] === "error found") {
                setSnackbar({
                    snackbarMessage: translate("add-product.duplicate-modifier-group-names"),
                    snackbarOpen: true,
                    snackbarSeverity: "error",
                });
                return true;
            }
        }
        return false;
    };
    const handleCreateProduct = async (redirectToProductDetail = false) => {
        const check = checkforDuplicateModifierGroupName();
        if (!check) {
            setIsSaving(true);
            try {
                let url = productData.title.toLowerCase()
                url = handleSeoUrl(url);
                let productUOMId = uuid();
                let params = {
                    title: productData.title,
                    deliveryPrice: parseFloat(productData.deliveryPrice),
                    deliveryCompareAtPrice: 0,
                    deliveryCostPerItem: 0,
                    deliveryPriceWithTax: parseFloat(productData.deliveryPriceWithTax),
                    image: productData.image ? [productData.image] : productData.image,
                    cover: productData.cover,
                    seoUrl: "product/" + url,
                    productUOMs: [{
                        productUOMId: productUOMId,
                        sku: url,
                        image: productData.image,
                        productUOMPricing: [{
                            productUOMId: productUOMId,
                            storeId: storeIdList,
                            deliveryPrice: parseFloat(productData.deliveryPrice),
                            deliveryCompareAtPrice: 0,
                            deliveryCostPerItem: 0,
                            deliveryPriceWithTax: parseFloat(productData.deliveryPriceWithTax),
                        }],
                        modifierGroups: modifierGroupList,
                        shippingDimensionHeight: 1,
                        shippingDimensionLength: 1,
                        shippingDimensionWidth: 1,
                        shippingWeight: 1,
                        shippingWeightUnit: "kg",
                    }],
                    platform: "warung",
                    collectionNames: categories?.length ? categories : [],
                    sellOnOnlineStore: true,
                };

                //Image Upload
                for (let index in uploadImage) {
                    if (typeof uploadImage[index] === "object" && uploadImage && uploadImage.length > 0) {
                        await Storage.put(`${params.image[index]}`, uploadImage[index], {
                            contentType: "image/png", // contentType is optional , but crucial for png
                            metadata: { title: productData.title },
                        })
                        await Storage.put(`${params.cover}`, uploadImage[0], {
                            contentType: "image/png", // contentType is optional , but crucial for png
                            metadata: { title: productData.title },
                        });
                    }
                }

                const createProductResp = await API.graphql(
                    graphqlOperation(adminCreateProduct, params)
                );
                if (createProductResp.data.adminCreateProduct.status === "true") {
                    setSnackbar({
                        snackbarMessage: translate("order-listing-screen.uploadtodb"),
                        snackbarOpen: true,
                        snackbarSeverity: "success",
                    });
                    setTimeout(
                        redirectToProductDetail
                            ? window.location.replace("/kds/viewproductdetail?productId=" + createProductResp.data.adminCreateProduct.productId)
                            : window.location.replace("/kds/viewmenu")
                        , 1500
                    );
                } else {
                    setSnackbar({
                        snackbarMessage: getCreateProductResponse(createProductResp.data.adminCreateProduct.message),
                        snackbarOpen: true,
                        snackbarSeverity: "error",
                    });
                }
                setIsSaving(false);
            } catch (error) {
                console.log(error)
                setSnackbar({
                    snackbarMessage: translate("add-product.create-product-error"),
                    snackbarOpen: true,
                    snackbarSeverity: "error",
                });
                setIsSaving(false);
            }
        }
    }

    const getCreateProductResponse = (message) => {
        switch (message) {
            case "SeoUrl Is Taken":
                return translate("add-product.product-name-taken");

            default:
                return message;
        }
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        accept: supportedFileType,
        noClick: true,
        noKeyboard: true,
        onDrop: handleImagePreview,
    });

    const handleEditModifierGroup = () => {
        let latestData = modifierGroupList.length - 1
        let modifierGroupId =
            !!modifierGroupList[latestData]?.modifierGroupId
                ? modifierGroupList[latestData].modifierGroupId
                : ""
        setModifierGroupIdToEdit(modifierGroupId);
        setRedirectToLastPage(true)
        setFormProgress(5);
    };

    // add collection component usage
    // the selected collections of the product
    const [categories, setCategories] = useState([]);
    const [txtCategories, setTxtCategories] = useState("");
    const handleOnChange = (e) => {
        setTxtCategories(e.target.value);
    };
    // list of available collections
    const [productCollectionList, setProductCollectionList] = useState([]);
    const [refProductCollectionList, setRefProductCollectionList] = useState([]);
    const [fetchCategoriesIsLoading, setFetchCategoriesIsLoading] = useState(false);

    // product detail page collections state
    const maxRowPerPage = 10000;

    // fetchProductCollection apis
    const fetchProductCollections = async () => {
        setFetchCategoriesIsLoading(true);
        try {
            let params = {
                limit: maxRowPerPage,
                filter: {
                    merchantId: {},
                },
            };
            let res = null;
            res = await API.graphql(graphqlOperation(searchProductCollections, params));

            if (res.data.searchProductCollections.items.length > 0) {
                let productCollectionItemsList = produce([], (draft) => {
                    res.data.searchProductCollections.items.map((obj, index) =>
                        draft.push(obj.name)
                    );
                });

                setProductCollectionList(productCollectionItemsList);

                let refProductCollectionItemsList = produce([], (draft) => {
                    res.data.searchProductCollections.items.map((obj, index) =>
                        draft.push({
                            name: obj.name,
                            productCollectionId: obj.productCollectionId,
                        })
                    );
                });

                setRefProductCollectionList(refProductCollectionItemsList);
            }
            setFetchCategoriesIsLoading(false);
        } catch (error) {
            setFetchCategoriesIsLoading(false);
            setSnackbar({
                snackbarMessage: SHARED_ERROR_MESSAGE.exceptionError,
                snackbarOpen: true,
                snackbarSeverity: "error",
            });
        }
    };

    // confirm delete category dialog usage
    const [openConfirmDeleteCategoryDialog, setOpenConfirmDeleteCategoryDialog] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState("");
    const handleCloseConfirmDeleteCategoryDialog = () => {
        setCategoryToDelete("");
        setOpenConfirmDeleteCategoryDialog(false);
    };

    const checkIfProductNameExists = async () => {
        let url = productData.title.toLowerCase()
        url = handleSeoUrl(url);
        setProductCheckIsLoading(true)
        try {
            let res = await API.graphql(
                graphqlOperation(checkProductName, {
                    seoUrl: "product/" + url,
                    title: productData.title
                })
            );
            setProductCheckIsLoading(false)
            return res.data.checkProductName.message === "Product name is available"
        } catch (error) {
            setProductCheckIsLoading(false)
            console.log("check product name exist error", error);
        }
    };

    const deleteProductCollection = async (productCollectionId) => {
        try {
            let params = {
                merchantId: merchantInfoContext.merchantId,
                productCollectionIdList: [productCollectionId],
            };

            const res = await API.graphql(graphqlOperation(adminDeleteProductCollection, params));

            setSnackbar({
                snackbarMessage: res.data.adminDeleteProductCollection.message,
                snackbarOpen: true,
                snackbarSeverity: res.data.adminDeleteProductCollection.status === "true" ? "success" : "error",
            });

            return res.data.adminDeleteProductCollection.status === "true" ? true : false;
        } catch (error) {
            setSnackbar({
                snackbarMessage: SHARED_ERROR_MESSAGE.exceptionError,
                snackbarOpen: true,
                snackbarSeverity: "error",
            });
        }
    };

    const handleRemoveCategory = (category) => {
        let productCollectionId = refProductCollectionList.find((o) => o.name === category).productCollectionId;
        // call api to delete product collection
        deleteProductCollection(productCollectionId).then((value) => {
            if (value) {
                setTimeout(() => {
                    fetchProductCollections();
                    // remove the category to be deleted if it is selected
                    let index = categories.findIndex((o) => o === category);
                    if (index !== -1) {
                        let tmpCategories = [...categories];
                        tmpCategories.splice(index, 1);
                        setCategories(tmpCategories);
                    }
                }, 1500);
            }
            setOpenConfirmDeleteCategoryDialog(false);
        });
    };

    // confirm delete modifier group dialog usage
    const [openConfirmDeleteModifierGroupDialog, setOpenConfirmDeleteModifierGroupDialog] = useState(false);
    const [modifierGroupIdToDelete, setModifierGroupIdToDelete] = useState("");
    const handleCloseConfirmDeleteModifierGroupDialog = () => {
        setModifierGroupIdToDelete("");
        setOpenConfirmDeleteModifierGroupDialog(false);
    };
    const handleRemoveModifierGroup = (modifierGroupId) => {
        let updatedModifierGroupList = [...modifierGroupList];
        let index = updatedModifierGroupList.findIndex((o) => o.modifierGroupId === modifierGroupId);
        updatedModifierGroupList.splice(index, 1);
        setModifierGroupList(updatedModifierGroupList);
        setOpenConfirmDeleteModifierGroupDialog(false);
    };

    // function to replicate product data of product to be cloned
    const cloneProduct = (data) => {
        let clone = JSON.parse(data);

        setProductData({
            ...productData,
            title: clone.title,
            deliveryPrice: clone.deliveryPrice,
            deliveryPriceWithTax: clone.deliveryPriceWithTax,
            isTaxable: !!clone.deliveryPriceWithTax,
        });
        setCategories(clone.categories);
        setModifierGroupList(clone.modifierGroupList);
        setIsCloneProduct(true);
    };

    const handleNext = async () => {
        switch (formProgress) {
            case 1:
                if (productData.title === "") {
                    setErrorMessage({
                        ...errorMessage,
                        title: translate("add-product.title-required-error")
                    })
                }
                else if (errorMessage.title === "" && productData.title !== "") {
                    let isProductNameAvailable = await checkIfProductNameExists();

                    if (!isProductCheckLoading && isProductNameAvailable) {
                        if (isCloneProduct) {
                            handleCreateProduct(true);
                        }
                        else {
                            setFormProgress(2);
                        }
                    } else {
                        setErrorMessage({
                            ...errorMessage,
                            title: translate("add-product.product-name-taken")
                        })
                    }
                }
                return

            case 2:
                setFormProgress(3)

                return

            case 3:
                if (productData.deliveryPrice === "" || productData.deliveryPrice === 0.0) {
                    setErrorMessage({
                        ...errorMessage,
                        deliveryPrice: translate("add-product.price-required-error")
                    })
                }
                else if (errorMessage.deliveryPrice === "" && productData.deliveryPrice !== "") {
                    setFormProgress(4)
                }
                return

            case 4:
                if (!categories?.length) {
                    setErrorMessage({
                        ...errorMessage,
                        collectionNames: translate("add-product.category-required-error")
                    })
                } else {
                    setFormProgress(5);
                }
                return

            case 7:
                handleCreateProduct()

                return

            default:
                return

        }
    }

    const renderPage = () => {
        switch (formProgress) {
            case 1:
                return (
                    <>
                        <TopLayout
                            hasBackButton={true}
                            redirectTo={"/kds/viewmenu"}
                            samePageRedirect={!!history.location.state}
                            handleRedirect={history.goBack}
                        >
                            <h1 className='orderhome-orderinfo-extralarge'>
                                {translate("order-listing-screen.whatisproductname")}
                            </h1>
                            <Grid item xs={12} className="product-create-container">
                                <h1 className='orderhome-orderinfo-small'>
                                    {translate("order-listing-screen.enterproductname")}
                                </h1>
                                <div className="product-create-textfield-section">
                                    <TextField
                                        placeholder='Nasi Lemak'
                                        className='product-create-textfield text'
                                        id="outlined-basic"
                                        label=""
                                        variant="outlined"
                                        name="title"
                                        onChange={(e) => handleChange(e)}
                                        value={productData.title}
                                    />
                                    <span
                                        style={{
                                            color: 'red',
                                        }}>
                                        {errorMessage.title}
                                    </span>
                                </div>

                            </Grid>
                        </TopLayout>
                        <BottomLayout
                            title={isCloneProduct ? translate("add-product.create-product") : translate("order-listing-screen.next")}
                            handleRedirect={handleNext}
                            isLoading={isSaving}
                        />
                    </>
                )
            case 2:
                return (
                    <>
                        <TopLayout
                            hasBackButton={true}
                            samePageRedirect={true}
                            handleRedirect={() => setFormProgress(1)}
                        >
                            <h1 className='orderhome-orderinfo-extralarge'>
                                {translate("order-listing-screen.addproductimage")}
                            </h1>
                            <Grid item xs={12} className="product-create-container">
                                <h1 className='orderhome-orderinfo-small'>
                                    {translate("add-product.enterproductsnap")}
                                </h1>
                                {uploadImage.length > 0 ? (
                                    <div className="product-image-display">
                                        <div
                                            className="img-wrapper"
                                            style={{
                                                backgroundImage: `url(${preview})`,
                                            }}
                                        />
                                        <CustomButton
                                            color='error'
                                            variant='text'
                                            startIcon={<DeleteOutlined />}
                                            disableRipple
                                            onClick={() => {
                                                setUploadedImage([]);
                                                setPreview("");
                                                setProductData({
                                                    ...productData,
                                                    image: "",
                                                    cover: ""
                                                });
                                            }}
                                        >
                                            {translate("product-detail-page.delete-image")}
                                        </CustomButton>
                                    </div>
                                )
                                    : (
                                        <div className="product-upload-image-box">
                                            <div className="product-upload-image">
                                                <div className="container">
                                                    <div {...getRootProps({ className: "product-dropzone" })}>
                                                        <input {...getInputProps()} />
                                                        <div className="button-label-wrapper">
                                                            <div>
                                                                <IconButton
                                                                    variant="outlined"
                                                                    onClick={open}>
                                                                    <img height="60px" width="60px" alt="upload" src={UploadIcon} />
                                                                </IconButton>
                                                            </div>
                                                            <p>{translate("add-product.upload-product-image")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                <span
                                    style={{
                                        color: 'red',
                                    }}>
                                    {errorMessage.image}
                                </span>
                            </Grid>
                        </TopLayout>
                        <BottomLayout
                            title={translate("order-listing-screen.next")}
                            handleRedirect={handleNext}
                        />
                    </>
                )
            case 3:
                return (
                    <>
                        <TopLayout
                            hasBackButton={true}
                            samePageRedirect={true}
                            handleRedirect={() => setFormProgress(2)}
                        >
                            <h1 className='orderhome-orderinfo-extralarge'>{translate("order-listing-screen.whatisprice")}</h1>
                            <Grid item xs={12} className="product-create-container">
                                <div className="product-create-checkbox-section">
                                    <Checkbox
                                        name="isTaxable"
                                        className="checkbox"
                                        onChange={(e) => handleChange(e)}
                                        checked={productData.isTaxable}
                                    />
                                    <label className='orderhome-orderinfo-small'>{translate("add-product.product-is-taxable")}</label>
                                </div>

                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <div style={{ width: "70%" }}>
                                        <h1 className='orderhome-orderinfo-small'>
                                            {productData.isTaxable
                                                ? translate("add-product.enter-price-with-tax")
                                                : translate("order-listing-screen.enterprice")
                                            }
                                        </h1>
                                        <div className="product-create-textfield-section">
                                            <TextField
                                                placeholder='0.00'
                                                step="0.01"
                                                inputProps={{ pattern: "^\d+(?:\.\d{1,2})?$" }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">{merchantInfoContext.currency}</InputAdornment>,
                                                    inputProps: { min: 1, max: 100000 }
                                                }}
                                                className='product-create-textfield price'
                                                id="outlined-basic"
                                                label=""
                                                variant="outlined"
                                                type='number'
                                                name={productData.isTaxable ? "deliveryPriceWithTax" : "deliveryPrice"}
                                                onChange={(e) => handleChange(e)}
                                                value={productData.isTaxable ? inputCurrency(productData.deliveryPriceWithTax, true) : inputCurrency(productData.deliveryPrice, true)}
                                                onKeyDown={(evt) => inputPreventDefault(evt)}
                                            />
                                            <span
                                                style={{
                                                    color: 'red',
                                                }}>
                                                {errorMessage.deliveryPrice}
                                            </span>
                                        </div>

                                    </div>
                                </div>
                            </Grid>

                        </TopLayout>
                        <BottomLayout
                            title={translate("order-listing-screen.next")}
                            handleRedirect={handleNext}
                        />
                    </>
                )

            case 4:
                return (
                    <>
                        <TopLayout
                            hasBackButton={true}
                            samePageRedirect={true}
                            handleRedirect={() => setFormProgress(3)}
                        >
                            <h1 className='orderhome-orderinfo-extralarge'>
                                {translate("add-product.category-title")}
                            </h1>
                            <Grid item xs={12} className="product-create-container">
                                <h1 className='orderhome-orderinfo-small'>
                                    {translate("add-product.category-subtitle")}
                                </h1>
                                <div className="categories-box">
                                    <CategoriesWithAutoComplete
                                        categories={categories}
                                        setCategories={setCategories}
                                        txtCategories={txtCategories}
                                        setTxtCategories={setTxtCategories}
                                        description={translate("add-product.add-categories-instruction")}
                                        handleOnChange={handleOnChange}
                                        setOpenDeleteModal={setOpenConfirmDeleteCategoryDialog}
                                        setCategoryToDelete={setCategoryToDelete}
                                        setExistingCategoryList={setProductCollectionList}
                                        existingCategoryList={productCollectionList}
                                        fetchCategoriesIsLoading={fetchCategoriesIsLoading}
                                    />
                                    <span
                                        style={{
                                            color: 'red',
                                        }}>
                                        {errorMessage.collectionNames}
                                    </span>
                                </div>
                            </Grid>
                            <ConfirmDeleteDialog
                                openDialog={openConfirmDeleteCategoryDialog}
                                subject={translate("product-detail-page.category-singular")}
                                handleCloseDialog={handleCloseConfirmDeleteCategoryDialog}
                                deleteFunction={() => handleRemoveCategory(categoryToDelete)}
                            />
                        </TopLayout>
                        <BottomLayout
                            title={translate("order-listing-screen.next")}
                            handleRedirect={handleNext}
                        />
                    </>
                )

            case 5:
                return (
                    <TopLayout
                        hasBackButton={true}
                        samePageRedirect={true}
                        handleRedirect={() => setFormProgress(4)}
                    >
                        <h1 className='orderhome-orderinfo-extralarge'>
                            {translate("order-listing-screen.doyouhaveaddons")}
                        </h1>
                        <Grid item xs={12} className="product-create-container">
                            <h1 className='orderhome-orderinfo-small'>
                                {translate("add-product.select-yes-no")}
                            </h1>
                            <div className="product-create-selection-section">
                                <Button
                                    className="selection-button"
                                    variant="outlined"
                                    fullWidth
                                    onClick={() => setShowProductModifier(true)}
                                >
                                    {translate("order-listing-screen.yes")}
                                </Button>
                                <Button
                                    className="selection-button"
                                    variant="outlined"
                                    fullWidth
                                    onClick={() => setFormProgress(7)}
                                >
                                    {translate("order-listing-screen.no")}
                                </Button>
                            </div>
                        </Grid>
                    </TopLayout>

                )

            case 6:
                return (

                    <TopLayout
                        hasBackButton={true}
                        samePageRedirect={true}
                        handleRedirect={() => {
                            handleEditModifierGroup()
                        }}
                    >
                        <h1 className='orderhome-orderinfo-extralarge'>
                            {translate("order-listing-screen.doyouhavemoreaddon")}
                        </h1>
                        <Grid item xs={12} className="product-create-container">
                            <h1 className='orderhome-orderinfo-small'>
                                {translate("add-product.select-an-option")}
                            </h1>
                            <div className="product-create-selection-section">
                                <Button
                                    className="selection-button"
                                    variant="outlined"
                                    fullWidth
                                    onClick={() => setShowProductModifier(true)}
                                >
                                    {translate("order-listing-screen.yes")}
                                </Button>
                                <Button
                                    className="selection-button"
                                    variant="outlined"
                                    fullWidth
                                    onClick={() => setFormProgress(7)}
                                >
                                    {translate("order-listing-screen.no")}
                                </Button>
                            </div>
                        </Grid>
                    </TopLayout>

                )

            case 7:
                return (
                    <>
                        <TopLayout
                            hasBackButton={true}
                            samePageRedirect={true}
                            handleRedirect={() => setFormProgress(6)}
                        >
                            <h1 className='orderhome-orderinfo-extralarge'>
                                {translate("add-product.confirm-page-title")}
                            </h1>
                            <Grid item xs={12} className="product-create-container">
                                <h1 className='confirm-section-text bolder'>
                                    {translate("add-product.product")}
                                </h1>
                                <div className="product-confirm-section">
                                    <div className="left">
                                        <img className="product-confirm-img" alt="upload" src={preview ? preview : defaultProductImage
                                        } />
                                    </div>
                                    <div className="right">
                                        <h1 className='confirm-section-text mediumBold'>
                                            {productData.title}
                                        </h1>
                                        <h1 style={{ marginTop: "0.5rem" }} className='confirm-section-text'>
                                            {merchantInfoContext.currency}{" "}{productData.isTaxable ? parseFloat(productData.deliveryPriceWithTax).toFixed(2) : parseFloat(productData.deliveryPrice).toFixed(2)}
                                        </h1>
                                    </div>
                                </div>
                                {modifierGroupList.length > 0 && (
                                    <>
                                        <h1 className='confirm-section-text bolder'>
                                            {translate("order-listing-screen.addons")}
                                        </h1>
                                        {modifierGroupList.map((mod, i) => (
                                            <div className="product-confirm-modifier-section" key={i}>
                                                <div className="modifier-title">
                                                    <h1 className='confirm-section-text mediumBold'>
                                                        <span style={{ fontWeight: "bold" }}>{translate("order-listing-screen.addOnTitle")}</span> : {mod.modifierGroupName}
                                                    </h1>

                                                    <CustomIconButton
                                                        color='error'
                                                        onClick={() => {
                                                            setModifierGroupIdToDelete(mod.modifierGroupId);
                                                            setOpenConfirmDeleteModifierGroupDialog(true);
                                                        }}
                                                        className="delete-modifier-button"
                                                    >
                                                        <DeleteOutlined />
                                                    </CustomIconButton>
                                                </div>

                                                <span
                                                    style={{
                                                        color: 'red',
                                                    }}>
                                                    {duplicatedErrorAddOnStorage[i] === "error found" && <p>{translate("add-product.this-is-a-duplicate-add-on-title")}</p>}
                                                </span>

                                                {mod.modifier.map((item, j) => (
                                                    <div className="modifier-item" key={j}>
                                                        <div className="left">
                                                            <h1 className='confirm-section-text mediumBold'>
                                                                {item.modifierName}
                                                            </h1>
                                                        </div>
                                                        <div className="right">
                                                            <h1 className='confirm-section-text'>
                                                                {merchantInfoContext.currency}{" "}{item.price}
                                                            </h1>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </>
                                )}
                            </Grid>

                        </TopLayout>
                        <BottomLayout
                            title={translate("add-product.create-product")}
                            handleRedirect={handleNext}
                            isLoading={isSaving}
                        />
                        <ConfirmDeleteDialog
                            openDialog={openConfirmDeleteModifierGroupDialog}
                            subject={translate("product-detail-page.add-on-singular")}
                            handleCloseDialog={handleCloseConfirmDeleteModifierGroupDialog}
                            deleteFunction={() => handleRemoveModifierGroup(modifierGroupIdToDelete)}
                        />
                    </>
                )
            default:
                return
        }
    }

    useEffect(() => {
        fetchStore()
    }, [])

    useEffect(() => {
        fetchProductCollections();
    }, []);

    useEffect(() => {
        if (!!history.location.state) {
            cloneProduct(history.location.state);
        }
    }, []);

    return (

        <div>
            <Header page={"menu"}></Header>
            {!showProductModifier ? (
                <>
                    <CustomizedSnackbars
                        message={snackbar.snackbarMessage}
                        snackbarOpen={snackbar.snackbarOpen}
                        snackbarClose={() =>
                            setSnackbar({
                                ...snackbar,
                                snackbarOpen: false,
                                snackbarSeverity: snackbar.snackbarSeverity,
                            })
                        }
                        severity={snackbar.snackbarSeverity}
                    />
                    {renderPage()}
                </>
            ) : (
                <ProductModifierWizard
                    modifierGroupList={modifierGroupList}
                    setModifierGroupList={setModifierGroupList}
                    setShowProductModifier={setShowProductModifier}
                    modifierGroupIdToEdit={modifierGroupIdToEdit}
                    setModifierGroupIdToEdit={setModifierGroupIdToEdit}
                    handleProductCreate={() => setFormProgress(6)}
                    redirectToLastPage={redirectToLastPage}
                    isProductCreate={true}
                />
            )}
        </div>

    );
}

export default AddProduct;